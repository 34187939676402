import axios from "axios";

const sendJson = (url, success_callback, error_callback, data={}, headers={}, ) => {
    // POST request using axios with set headers
    console.log('xios')
    console.log(data)
    axios.post(process.env.REACT_APP_API_BASE_SECURE_URL + url, data, { headers })
        .then(response => {
                success_callback(response);
            }
        )
        .catch(error => {
            error_callback(error);
        });
}
export default sendJson;

import React, {Fragment} from "react";
import {Link} from "react-router-dom";


export default function CPCTOCPMPolicyPage(){
    return (
        <Fragment>
            <div className="container">
                <div className="content educate_content">
                    <section className="section section__article">
                        <div className="breadcrumb" dir="ltr">
                            <div className="link__arrow o__ltr">
                                <a href="/policy-all">Policies & Contracts</a>
                            </div>

                            <div className="link__arrow o__ltr">CPA Campaigns Policy & Conditions</div>
                        </div>

                        <div className="paper paper__large">
                            <div className="content content__narrow">
                                <div className="article intercom-force-break">
                                    <div className="article__meta" dir="ltr">
                                        <h1 className="t__h1">CPA Campaigns Policy & Conditions</h1>
                                        <div className="article__desc">

                                        </div>
                                        <div className="avatar">
                                            <div className="avatar__photo o__ltr">
                                                <span className="avatar__image avatar__fallback"> A </span>

                                            </div>
                                            <div className="avatar__info">
                                                <div>
                                                    Written by <span className='c__darker'> Admin</span>
                                                    <br /> Updated over a week ago
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <h3 className={'t__h3'}>CPC/CPM to CPA Models</h3><br />
                                    <article dir="ltr"><p className="intercom-align-left">

                                        CPA Model refers to a business model where as advertiser you pay ONLY for the traffic that you reported as "Converted" traffic.<br />
                                        B-Tech Will allow you to select CPA payment model even though the traffic to your campaigns will be sent from CPC & CPM campaigns (meaning
                                        the payout is for Clicks / Ad Views) if the following conditions terms are met:
                                    </p>
                                        <ul>
                                            <li><p className="no-margin">You have a "Gold" or "Silver" level membership (see <a href={'#membership-levels'} className={'reg_link'}>membership levels</a>). </p></li>
                                            <li><p className="no-margin">You have at least 250 USD balance.</p></li>
                                            <li><p className="no-margin">You perform payouts on a weekly basis for each previous week (no monthly payments allowed for this model) </p></li>
                                            <li><p className="no-margin">You are meeting your CPA goals at least 3 out of each 4 weeks (see <a href={'#cpa-goals'} className={'reg_link'}>CPA goals</a>).</p></li>
                                            <li><p className="no-margin">Your Weekly budget cap does not exceed 15,000 USD.</p></li>
                                            <li><p className="no-margin">Your Day budget cap does not exceed 5,000 USD.</p></li>
                                        </ul>
                                        <br />
                                        <h3 className={'t__h3'}>CPA Model service & approval</h3><br />
                                        <p className={'intercom-align-left'}>
                                            AS WE PARTICIPATE IN THE LOSS RISK DUE TO FAILED CAMPAIGNS THAT DO NOT MEET CPA GOALS, PLEASE BE ADVISED THAT B-TECH CAN AT ANY POINT PAUSE
                                            A CAMPING / STOP A CAMPAIGN OR ENFORCE ANY CAP ON THE INCOMING TRAFFIC PER GEO OR PER ENTIRE CAMPAIGN OR ACCOUNT IF WE WILL DEEM THE OPERATION RISKY OR NOT PROFITABLE, AT ANY TIME INCLUDING
                                            ACTIVE CAMPAIGN WORKING HOURS AT OUR SOLE DISCRETION AND WITHOUT PRIOR NOTICE.<br />
                                            PLEASE ALSO BE AWARE THAT WE CAN DENY A REQUEST TO WORK WITH CPA MODEL BASED ON OUR INTERNAL RISK EVALUATION AND WE ARE NOT OBLIGATED
                                            TO SUPPLY THE REASONS FOR THE DENIAL OR FOR PAUSE OF CPA CAMPAIGNS AND SUCH ACTIONS ARE AT B-TECH'S SOLE DISCRETION.
                                            IN SUCH EVENT YOU WILL BE NOTIFIED AND WILL BE ABLE TO REACTIVATE THE
                                            CAMPAIGN / REMOVE THE CAPS IF YOU SWITCH TO CPC / CPM / PREPAID MODEL FOR THE CAMPAIGN IN QUESTION.
                                        </p>
                                        <p className="intercom-align-left">Please also refer to our&nbsp;
                                            <Link to={'/terms-and-conditions'} className={'reg_link'}>Terms & Conditions</Link>&nbsp;
                                            to see all other conditions for ANY campaign activity (including CPA campaigns).</p>
                                        <br /><br />
                                        <a name={'membership-levels'} href={'#membership-levels'}>
                                            <h3 className={'t__h3'}>Membership Levels</h3><br /></a>
                                            <ul>
                                                <li><p><span style={{fontWeight: "bold"}}>Bronze - Entry Level</span> <br />This Level is the basic level that receive any verified advertiser.</p></li>
                                            </ul>
                                            <ul>
                                                <li><p><span style={{fontWeight: "bold"}}>Silver - Activity Level</span> <br />This Level is received after 4 weeks of active operations with a minimum of 1,000 USD total spend.</p></li>
                                            </ul>
                                            <ul>
                                                <li><p><span style={{fontWeight: "bold"}}>Gold - VIP Level</span> <br />This Level is received after 3 month of active operations with a minimum of 20,000 USD total spend.</p></li>
                                            </ul>

                                        <a name={'cpa-goals'} href={'#cpa-goals'}> <h3 className={'t__h3'}>CPA Goals</h3></a><br />
                                        <p>

                                            CPA goals are defined per account and can be negotiated by contacting us at <a href={'mailto:dao@bandoftech.com'} className={'reg_link'}>dao@bandoftech.com</a>
                                            <br /><br />
                                            You will be able to see the initial CPA goals per GEO in your account in the "Country Management" section, provided all the above mentioned rules are met.<br />
                                            The CPA goals per GEO are effective with any campaign separately that work with said GEO.
                                            <br />
                                            To Clarify: Each campaign must meet the CPA goal separately from all other campaigns. (CPA goal is no accumulated between different campaigns).
                                           </p>


                                        <p className="intercom-align-left">B-Tech also reserves the right to
                                            reject any content for any reason at our sole discretion.</p><p
                                            className="intercom-align-left">

                                            <i>Please note that campaigns are
                                            continually reviewed by our staff and an active campaign can be suspended at
                                            any time. If, after approval, a campaign was modified in a way that does not
                                            follow our Guidelines, your account could be suspended or terminated.</i>
                                        </p></article>
                                </div>
                            </div>
                            <div className="intercom-reaction-picker" dir="ltr">
                                <div className="intercom-reaction-prompt">&nbsp;</div>

                                <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0"
                                        aria-label="Disappointed Reaction">
        <span data-emoji="disappointed" title="Disappointed">
        </span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0"
                                        aria-label="Neutral face Reaction">
        <span data-emoji="neutral_face" title="Neutral face">
        </span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0"
                                        aria-label="Smiley Reaction">
        <span data-emoji="smiley" title="Smiley">
        </span>
                                </button>
                            </div>

                        </div>

                    </section>
                </div>
            </div>
        </Fragment>
    );
}
import React, {Fragment} from "react";
import {Link} from "react-router-dom";


export default function ProhibitedContentPage(){
    return (
        <Fragment>
            <div className="container">
                <div className="content educate_content">
                    <section className="section section__article">
                        <div className="breadcrumb" dir="ltr">
                            <div className="link__arrow o__ltr">
                                <a href="/policy-all">Policies & Contracts</a>
                            </div>

                            <div className="link__arrow o__ltr">Prohibited Content, Products and Services</div>
                        </div>

                        <div className="paper paper__large">
                            <div className="content content__narrow">
                                <div className="article intercom-force-break">
                                    <div className="article__meta" dir="ltr">
                                        <h1 className="t__h1">Prohibited Content, Products and Services</h1>
                                        <div className="article__desc">

                                        </div>
                                        <div className="avatar">
                                            <div className="avatar__photo o__ltr">
                                                <span className="avatar__image avatar__fallback"> A </span>

                                            </div>
                                            <div className="avatar__info">
                                                <div>
                                                    Written by <span className='c__darker'> Admin</span>
                                                    <br /> Updated over a week ago
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <article dir="ltr"><p className="intercom-align-left">B-Tech and it's brands prohibits the
                                        promotion of products or services in the following categories:</p>
                                        <ul>
                                            <li><p className="no-margin">Pornography, adult or mature content;</p></li>
                                            <li><p className="no-margin">Malware, phishing or other harmful codes /
                                                software;</p></li>
                                            <li><p className="no-margin">Fake virus, "scan alerts" or "technical
                                                support";</p></li>
                                            <li><p className="no-margin">Illegal, prescription, or recreational
                                                drugs;</p></li>
                                            <li><p className="no-margin">Alcohol or tobacco;</p></li>
                                            <li><p className="no-margin">Offensive, abusive or hate-mongering
                                                content;</p></li>
                                            <li><p className="no-margin">Auto-downloading of software;</p></li>
                                            <li><p className="no-margin">Collecting push notification subscribers;</p>
                                            </li>
                                            <li><p className="no-margin">Marketing materials that are protected by
                                                copyright and trademark laws;</p></li>
                                            <li><p className="no-margin">Fraud, unofficial, untrue, false, misleading,
                                                invented, re-produced information (i.e. facts, news, offers, solutions,
                                                guidelines)</p></li>
                                        </ul>
                                        <p className="intercom-align-left">Please also refer to our&nbsp;
                                             <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>&nbsp;
                                             in order to check out the reasons of campaign rejection.</p>
                                        <p className="intercom-align-left">B-Tech also reserves the right to
                                            reject any content for any reason at our sole discretion.</p><p
                                            className="intercom-align-left"><i>Please note that campaigns are
                                            continually reviewed by our staff and an active campaign can be suspended at
                                            any time. If, after approval, a campaign was modified in a way that does not
                                            follow our Guidelines, your account could be suspended or terminated.</i>
                                        </p></article>
                                </div>
                            </div>
                            <div className="intercom-reaction-picker" dir="ltr">
                                <div className="intercom-reaction-prompt">&nbsp;</div>

                                <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0"
                                        aria-label="Disappointed Reaction">
        <span data-emoji="disappointed" title="Disappointed">
        </span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0"
                                        aria-label="Neutral face Reaction">
        <span data-emoji="neutral_face" title="Neutral face">
        </span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0"
                                        aria-label="Smiley Reaction">
        <span data-emoji="smiley" title="Smiley">
        </span>
                                </button>
                            </div>

                        </div>

                    </section>
                </div>
            </div>
        </Fragment>
    );
}
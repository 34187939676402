import React, { Fragment } from 'react';
import './reg.css'

import PagesRegisterContent from './componants';
export default function PagesRegister() {
  return (
    <Fragment>
      <PagesRegisterContent />
    </Fragment>
  );
}

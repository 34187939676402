import React, {Fragment, useEffect, useRef, useState} from 'react';
import dashboard_example_image from "../../theme/assets/images/dashboard_example.png"
import data_points_image from "../../theme/assets/images/illustrations/data_points.svg"
import { Link } from 'react-router-dom';
import './home.css';
import './inlines.css';
import {Button, Grid, InputAdornment, TextField} from "@material-ui/core";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import ReCaptchaV2 from "react-google-recaptcha";
import sendJson from "../../services/post";

const LandingPage = () => {
    const newSubmitForm = useRef(null);
    const capthcaWrapper = useRef(null);
    const thankyouNote = useRef(null);
    const [captchaState, setCaptchaState] = useState(null);
    const [formNewsProps, setFormNewsProps] = useState({'email_address': undefined, 'captcha_token': undefined});

    const [submitEmailDisabled, setSubmitEmailDisabled] = useState(true);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const onFormNewInputChange = (emailInput) => {
        setFormNewsProps((previus) => ({...previus, ...{'email_address': emailInput.target.value}}));
        console.log(formNewsProps);
    }

    const handleSubmitSucess = (response) => {
        console.log(response);
        newSubmitForm.current.className = 'nodisplay';
        capthcaWrapper.current.className = 'nodisplay';
        thankyouNote.current.className = '';
    }

    const handleSubmitError = (response) => {
        console.log(response)
    }

    const subscribeToNews = () => {
        console.log('subscription clicked');

        sendJson(process.env.REACT_APP_NEWS_REGISTRATION,
            handleSubmitSucess,handleSubmitError, formNewsProps, headers);
    }

    const onCaptchaChange = (value) => {
        setCaptchaState(value)
    }

    useEffect(() => {
        if(captchaState){
            setSubmitEmailDisabled(false);
            setFormNewsProps((previus) => ({...previus, ...{'captcha_token': captchaState}}));
        }
    }, [captchaState]);
    // const [state, setState] = React.useState({
    //     right: false
    // });
    // const toggleDrawer = (side, open) => event => {
    //     if (
    //         event.type === 'keydown' &&
    //         (event.key === 'Tab' || event.key === 'Shift')
    //     ) {
    //         return;
    //     }
    //
    //     setState({ ...state, [side]: open });
    // };
    return (
        <Fragment>
            <div className={'main_home_container'}>
                <div className="hero-wrapper bg-composed-wrapper">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <div className="bg-composed-wrapper--image bg-composed-filter-rm opacity-9 quaote_div"
                            ></div>
                        <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                            <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                                <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                    <div
                                        className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                        <div>
                                            <div className="text-black mt-3"><h1
                                                className="display-2 mb-3 font-weight-bold">B-Tech Services</h1><p
                                                className="font-size-lg text-black-50">
                                                •&nbsp;Get traffic from multiple CPA/CPM networks on single campaign.<br />
                                                •&nbsp;Get ONLY relevant traffic with smart and adjustable lead scoring engine.<br />
                                                •&nbsp;Monitor web page health and get alerts right to your phone!<br />
                                                •&nbsp;Accurate Tracking & analytics in real time.<br />
                                                •&nbsp;Remain in control: All your data and campaigns are yours, and you can migrate them any time!<br />
                                            </p>
                                                <div
                                                    className="divider border-2 border-dark mt-4 mb-2 border-light opacity-2 rounded-circle w-25"></div>
                                                <div>
                                                    {/*<a*/}
                                                    {/*className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mt-2 MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"*/}
                                                    {/*tabIndex="0" role="button" aria-disabled="false"*/}
                                                    {/*title="View Carolina React Admin Dashboard with Material-UI PRO Live Preview"*/}
                                                    {/*href="/DashboardCustomers"><span className="MuiButton-label"><span*/}
                                                    {/*className="btn-wrapper--label">Live Demo</span><span*/}
                                                    {/*className="btn-wrapper--icon"><svg aria-hidden="true"*/}
                                                    {/*                                   focusable="false"*/}
                                                    {/*                                   data-prefix="fas"*/}
                                                    {/*                                   data-icon="arrow-right"*/}
                                                    {/*                                   className="svg-inline--fa fa-arrow-right fa-w-14 "*/}
                                                    {/*                                   role="img"*/}
                                                    {/*                                   xmlns="http://www.w3.org/2000/svg"*/}
                                                    {/*                                   viewBox="0 0 448 512"><path*/}
                                                    {/*fill="currentColor"*/}
                                                    {/*d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></span></span><span*/}
                                                    {/*className="MuiTouchRipple-root"></span></a>*/}
                                                    <a
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 ml-2 mt-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                                                    tabIndex="0" role="button" aria-disabled="false" rel="noopener"
                                                    target="_blank"
                                                    title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                                                    href="/registration#FirstStep"><span className="MuiButton-label"><span
                                                    className="btn-wrapper--icon"><svg aria-hidden="true"
                                                                                       focusable="false"
                                                                                       data-prefix="fas"
                                                                                       data-icon="external-link-alt"
                                                                                       className="svg-inline--fa fa-external-link-alt fa-w-16 "
                                                                                       role="img"
                                                                                       xmlns="http://www.w3.org/2000/svg"
                                                                                       viewBox="0 0 512 512"><path
                                                    fill="currentColor"
                                                    d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                                                    className="btn-wrapper--label">Register</span></span><span
                                                    className="MuiTouchRipple-root"></span></a></div>
                                                <small className="d-block pt-3"></small></div>
                                        </div>
                                    </div>
                                    <div
                                        className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                        <img alt="..." className="w-100 mx-auto d-block img-fluid"
                                             src={dashboard_example_image}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-footer py-3 py-lg-5">
                        <div className="MuiContainer-root MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4">
                                    <div
                                        className="MuiPaper-root MuiCard-root card-box-hover-rise card-box-hover card-box-alt card-border-top border-success mb-4 p-3 MuiPaper-elevation1 MuiPaper-rounded">
                                        <h3 className="font-size-lg font-weight-bold mb-4">B-Tech Campaigns</h3><p
                                        className="card-text mb-0">A platform connecting multiple publisher CPA/CPM networks to advertisers that do not want to take the risk and perfer to work with CPA Model.
                                        <br /><br />
                                        This unique model enables you to get traffic from "Click", "Push" and "Native" ads campaigns while paying ONLY for traffic that actually converted*!
                                        <br /><br />
                                        The platform also provides a full ads campaign management and optimisation system, various and flexible payment methods, finance management tools and much more... <br /><Link to={'/products#campaigns'} className={'reg_link'}> (see full feature list)</Link>
                                        </p></div>
                                </div>
                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4">
                                    <div
                                        className="MuiPaper-root MuiCard-root card-box-hover-rise card-box-hover card-box-alt card-border-top border-first mb-4 p-3 MuiPaper-elevation1 MuiPaper-rounded">
                                        <h3 className="font-size-lg font-weight-bold mb-4">B-Tech Tracker</h3><p
                                        className="card-text mb-0">Never be in the dark about your web traffic again.
                                        Create tracking campaigns with a few clicks of a button, generate tracking pixels and links for clicks,
                                        leads and goals. <br /><br />
                                        Create redirect urls, cookie trackers and usage trackers while maintaining transparency and compliance rules with built in data anonymization!
                                        <br /><br />
                                        our detailed live dashboards and historical reports will give you full and clear visibility of your business data.<br /><br />
                                        Business data visibility have never been simpler!
                                        <br /><Link to={'/products#tracker'} className={'reg_link'}> (see full feature list)</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4">
                                    <div
                                        className="MuiPaper-root MuiCard-root card-box-hover-rise card-box-hover card-box-alt card-border-top border-warning mb-4 p-3 MuiPaper-elevation1 MuiPaper-rounded">
                                        <h3 className="font-size-lg font-weight-bold mb-4">B-Tech Monitor</h3><p
                                        className="card-text mb-0">Monitor tools are vital to any commercial web operation!.<br /> Any minute of downtime on your webpages while running advertising campaigns
                                        equals to money loss!
                                        <br /><br />
                                        We provide a tool for webpage health monitoring, but without the hustle of looking at charts and ping speeds.<br /> This tool is designed for business people, not for NOC centers
                                        or DevOps teams meaning you will not need either of them to keep track of your web page health status and even get suggestion on issue remediation in real time.
                                        <br /><Link to={'/products#monitor'} className={'reg_link'}> (see full feature list)</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 bg-secondary">
                    <div className="MuiContainer-root pb-4 MuiContainer-fixed MuiContainer-maxWidthLg">
                        <div className="text-center py-4"><h3 className="display-3 mb-2 font-weight-bold">What's
                            included</h3><p className="font-size-lg mb-5 text-black-50">reports, dashboards, alerts and
                            widgets at your disposal. Everything you need to track your web data</p></div>
                        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-success badge-pill">Yes+</span></div>
                                    <div className="bg-grow-early text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shapes"
                                             className="svg-inline--fa fa-shapes fa-w-16 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M128,256A128,128,0,1,0,256,384,128,128,0,0,0,128,256Zm379-54.86L400.07,18.29a37.26,37.26,0,0,0-64.14,0L229,201.14C214.76,225.52,232.58,256,261.09,256H474.91C503.42,256,521.24,225.52,507,201.14ZM480,288H320a32,32,0,0,0-32,32V480a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V320A32,32,0,0,0,480,288Z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Campaigns</h3><p
                                    className="text-black-50 mt-2 mb-0">Campaign Management dashboards, carefully
                                    crafted for marketing and affiliation work.<br />define tracker links / pixels / and
                                        labels.</p></div>
                            </div>
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-warning badge-pill">Yes+</span></div>
                                    <div className="bg-ripe-malin text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                             data-icon="align-center"
                                             className="svg-inline--fa fa-align-center fa-w-14 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor"
                                                  d="M432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM108.1 96h231.81A12.09 12.09 0 0 0 352 83.9V44.09A12.09 12.09 0 0 0 339.91 32H108.1A12.09 12.09 0 0 0 96 44.09V83.9A12.1 12.1 0 0 0 108.1 96zm231.81 256A12.09 12.09 0 0 0 352 339.9v-39.81A12.09 12.09 0 0 0 339.91 288H108.1A12.09 12.09 0 0 0 96 300.09v39.81a12.1 12.1 0 0 0 12.1 12.1z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Ready made reports</h3><p
                                    className="text-black-50 mt-2 mb-0">A large repository of pre-defined reports for
                                    most common tracking tasks, and a clean and simple interface to create your custom
                                    once.</p></div>
                            </div>
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-first badge-pill">Yes+</span></div>
                                    <div className="bg-malibu-beach text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="table"
                                             className="svg-inline--fa fa-table fa-w-16 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Exports</h3><p
                                    className="text-black-50 mt-2 mb-0">Export your data to Excel / CSV.<br />You can
                                    export all your data at any time without any limitations!</p></div>
                            </div>
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-danger badge-pill">Yes+</span></div>
                                    <div className="bg-arielle-smile text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                             data-icon="map-marker-alt"
                                             className="svg-inline--fa fa-map-marker-alt fa-w-12 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path fill="currentColor"
                                                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Maps</h3><p
                                    className="text-black-50 mt-2 mb-0">See your traffic distribution sliced by any
                                    metric on Geo Maps.<br />Zoom in / out for accurate detalization.</p></div>
                            </div>
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-info badge-pill">Yes+</span></div>
                                    <div className="bg-strong-bliss text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="far"
                                             data-icon="object-group"
                                             className="svg-inline--fa fa-object-group fa-w-16 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M500 128c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12h-72c-6.627 0-12 5.373-12 12v12H96V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v72c0 6.627 5.373 12 12 12h12v256H12c-6.627 0-12 5.373-12 12v72c0 6.627 5.373 12 12 12h72c6.627 0 12-5.373 12-12v-12h320v12c0 6.627 5.373 12 12 12h72c6.627 0 12-5.373 12-12v-72c0-6.627-5.373-12-12-12h-12V128h12zm-52-64h32v32h-32V64zM32 64h32v32H32V64zm32 384H32v-32h32v32zm416 0h-32v-32h32v32zm-40-64h-12c-6.627 0-12 5.373-12 12v12H96v-12c0-6.627-5.373-12-12-12H72V128h12c6.627 0 12-5.373 12-12v-12h320v12c0 6.627 5.373 12 12 12h12v256zm-36-192h-84v-52c0-6.628-5.373-12-12-12H108c-6.627 0-12 5.372-12 12v168c0 6.628 5.373 12 12 12h84v52c0 6.628 5.373 12 12 12h200c6.627 0 12-5.372 12-12V204c0-6.628-5.373-12-12-12zm-268-24h144v112H136V168zm240 176H232v-24h76c6.627 0 12-5.372 12-12v-76h56v112z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Live Dashboards <br /></h3><p
                                    className="text-black-50 mt-2 mb-0">Track your performance in real time with
                                    customizable live dashboards.<br />Configure your dashboards and widgets!</p></div>
                            </div>
                            <div
                                className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                                <div
                                    className="MuiPaper-root MuiCard-root p-4 mb-4 MuiPaper-elevation1 MuiPaper-rounded">
                                    <div className="card-badges"><span
                                        className="shadow-none badge badge-dark badge-pill">Yes+</span></div>
                                    <div className="bg-plum-plate text-center text-white font-size-xl d-50 rounded">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sitemap"
                                             className="svg-inline--fa fa-sitemap fa-w-20 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path fill="currentColor"
                                                  d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"></path>
                                        </svg>
                                    </div>
                                    <h3 className="font-size-lg font-weight-bold mt-4">Alerts</h3><p
                                    className="text-black-50 mt-2 mb-0">stay up to date! <br />Configure alerts to your
                                    mobile/email/slack based on your logic rules.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 bg-white">
                    <div className="text-center py-4"><h3 className="display-3 mb-2 font-weight-bold">Register NOW!</h3>
                        {/*<p className="font-size-lg mb-4 text-black-50">Checkout Our Live Demo to get the*/}
                        {/*    look &amp; feel</p>*/}
                        <div
                            className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                        <div>
                            {/*<a*/}
                            {/*className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"*/}
                            {/*tabIndex="0" role="button" aria-disabled="false"*/}
                            {/*title="View Carolina React Admin Dashboard with Material-UI PRO Live Preview"*/}
                            {/*href="/DashboardCustomers"><span className="MuiButton-label"><span*/}
                            {/*className="btn-wrapper--label">Live Demo</span><span className="btn-wrapper--icon"><svg*/}
                            {/*aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"*/}
                            {/*className="svg-inline--fa fa-arrow-right fa-w-14 " role="img"*/}
                            {/*xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor"*/}
                            {/*                                                               d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></span></span><span*/}
                            {/*className="MuiTouchRipple-root"></span></a>*/}
                            <a
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                            tabIndex="0" role="button" aria-disabled="false" rel="noopener" target="_blank"
                            title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                            href="/registration#FirstStep"><span className="MuiButton-label"><span className="btn-wrapper--icon"><svg
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                            className="svg-inline--fa fa-external-link-alt fa-w-16 " role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor"
                                                                                           d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                            className="btn-wrapper--label">Register</span></span><span
                            className="MuiTouchRipple-root"></span></a></div>
                        <small className="d-block pt-3"></small></div>
                </div>
                <div className="bg-royal pt-5">
                    <div className="MuiContainer-root py-5 MuiContainer-maxWidthMd">
                        <div className="MuiPaper-root MuiCard-root shadow-lg mb-5 MuiPaper-elevation1 MuiPaper-rounded">
                            <div className="p-5">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="pl-0 pl-lg-5 py-0 py-md-2 text-center text-lg-left">
                                        <div className="mb-4">
                                            {/*<span className="badge badge-success badge-pill">FREE</span>*/}
                                            <h1
                                            className="display-3 my-3 font-weight-bold">B-Tech Services!</h1><p
                                            className="font-size-lg text-black-50">B-Tech Services was born out of need.<br />
                                            we decided that we had enough of complex web campaign and web analytics systems that take a
                                            lot of time to set up &amp; configure and cost irrational amount of money
                                            and still will not give us exactly what we need.<br /><br />

                                            {/*we believe that you*/}
                                            {/*    know better what's right for your business! so we focused our mission on*/}
                                            {/*    creation of the most flexible system on one hand, and simplicity of use*/}
                                            {/*    on the other and non less important make it affordable for any business*/}
                                            {/*    large or small or even for personal use.<br /><br />*/}

                                            In essence, we provide an easy way to create and run ads campaign to promote your business, track and measure advertising performance
                                            and monitor the health of your important web assets.<br /><br />

                                            On top of that we provide a model that enable you to work in CPA model thuth have a better controll of your budget on one hand, and still
                                            be able to utilize the power of large volumes of traffic that can be generated through CPM & CPC campaigns.<span className={'red_dot'}>*</span>
                                            <br /><br />
                                            <span className={'red_dot small_dot'}>*</span> <span className={'small_dot'}> subject to</span> <Link to={'cpc-to-cpa'} className={'reg_link'} ><span className={'small_dot'}> CPC to CPA Policy and trial period</span></Link>
                                        </p></div>
                                    </div>
                                    <div className="p-4"><img alt="..." className="img-fluid"
                                                              src={data_points_image}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 bg-white">
                    <div className="text-center py-4"><h3 className="display-3 mb-2 font-weight-bold">Register NOW!</h3>
                        {/*<p className="font-size-lg mb-4 text-black-50">Checkout Our Live Demo to get the*/}
                        {/*    look &amp; feel</p>*/}
                        <div
                            className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                        <div>
                            {/*<a*/}
                            {/*className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"*/}
                            {/*tabIndex="0" role="button" aria-disabled="false"*/}
                            {/*title="View Carolina React Admin Dashboard with Material-UI PRO Live Preview"*/}
                            {/*href="/DashboardCustomers"><span className="MuiButton-label"><span*/}
                            {/*className="btn-wrapper--label">Live Demo</span><span className="btn-wrapper--icon"><svg*/}
                            {/*aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"*/}
                            {/*className="svg-inline--fa fa-arrow-right fa-w-14 " role="img"*/}
                            {/*xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor"*/}
                            {/*                                                               d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></span></span><span*/}
                            {/*className="MuiTouchRipple-root"></span></a>*/}
                            <a
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                                tabIndex="0" role="button" aria-disabled="false" rel="noopener" target="_blank"
                                title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                                href="/registration#FirstStep"><span className="MuiButton-label"><span className="btn-wrapper--icon"><svg
                                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                                className="svg-inline--fa fa-external-link-alt fa-w-16 " role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor"
                                                                                               d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                                className="btn-wrapper--label">Register</span></span><span
                                className="MuiTouchRipple-root"></span></a></div>
                        <small className="d-block pt-3"></small></div>
                </div>

                <div className="bg-first py-5">
                    <div>
                        <div className="MuiContainer-root text-center MuiContainer-maxWidthMd"><h1
                            className="display-3 mb-4 text-white font-weight-bold">Stay up to date</h1></div>
                        <div
                            className="divider border-2 d-sm-none d-md-block rounded-circle border-white bg-white opacity-1 mx-auto mb-4 mt-5 w-50"></div>
                        <div
                            className="divider border-2 d-sm-none d-md-block rounded-circle border-white bg-white opacity-1 mx-auto my-4 w-50"></div>
                        <div className="MuiContainer-root text-center MuiContainer-maxWidthMd">
                            <div
                                className="MuiPaper-root MuiCard-root mt-5 card-box MuiPaper-elevation1 MuiPaper-rounded">
                                <div className="MuiCardContent-root p-3">
                                    <div className="text-black"><h1
                                        className="display-4 mb-3 font-weight-bold">Newsletter updates</h1><p
                                        className="font-size-md mb-4 text-black-50">Subscribe to our newsletter to be
                                        the first to find out when we offer promotions or discounts for our
                                        products.</p>
                                        <div id={"mc_embed_signup_thankyou"} ref={thankyouNote} className={"nodisplay"} > <p className="font-size-xl text-black-50">Thank You For Registering To Our News!<br/></p></div>
                                        <div id="mc_embed_signup" ref={newSubmitForm}>
                                            <form
                                                className="validate"
                                                id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                noValidate>
                                                <Grid container spacing={4}>
                                                    <Grid xs={8} item>
                                                        <div id="mc_embed_signup_scroll">
                                                            <div className="mc-field-group">
                                                                <TextField
                                                                    fullWidth
                                                                    className="mt-0"
                                                                    margin="dense"
                                                                    id="mce-EMAIL"
                                                                    onChange={onFormNewInputChange}
                                                                    name="email"
                                                                    type="email"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <MailOutlineTwoToneIcon />
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="clear" id="mce-responses">
                                                                <div
                                                                    className="response"
                                                                    id="mce-Errorresponse"
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <div
                                                                    className="response"
                                                                    id="mce-success-response"
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </div>
                                                            <div
                                                                aria-hidden="true"
                                                                style={{ position: 'absolute', left: '-5000px' }}>
                                                                <input
                                                                    name="b_3ebc33a3d37f2278158d74721_a122e673aa"
                                                                    tabIndex="-1"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid xs={4} item>
                                                        <Button
                                                            className="w-100"
                                                            color="primary"
                                                            variant="contained"
                                                            id="mc-embedded-subscribe"
                                                            name="subscribe"
                                                            onClick={subscribeToNews}
                                                            disabled={submitEmailDisabled}
                                                        >
                                                            <span className="btn-wrapper--label">Signup</span>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>

                                        </div>
                                        <div className={"capthca_center"} ref={capthcaWrapper}>
                                            <ReCaptchaV2 sitekey="6Lc_jRodAAAAAG1tM3J2gluAoCk9Xq6jrev_1QOL" onChange={onCaptchaChange}/>
                                        </div>
                                        <small className="text-black-50 d-block pt-3">We promise not to spam your inbox.
                                            We also hate spam!</small></div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5"><span className="text-center d-block text-white-50">Copyright © 2021 - <a
                            className="text-white" href="/" title="UiFort">B-Tech</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LandingPage;

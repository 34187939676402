import React, {Fragment, useEffect, useRef, useState} from 'react';
import dashboard_example_image from "../../theme/assets/images/dashboard_example.png";
import domains_image from "../../theme/assets/images/domains.png";
import domains_large from "../../theme/assets/images/domains_large.png";
import campaigns_image from "../../theme/assets/images/campaigns.png";
import campaign_image from "../../theme/assets/images/campaign.png";
import panels_image from "../../theme/assets/images/panels.png"

import {Link, useLocation} from 'react-router-dom';

import './products.css';
import './inlines.css';
import {animateScroll} from "react-scroll";
import ReCaptchaV2 from "react-google-recaptcha";
import {Button, Grid, InputAdornment, TextField} from "@material-ui/core";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import sendJson from "../../services/post";


const ProductsPage = () => {
    const tracker_ref = useRef(null);
    const monitor_ref = useRef(null);
    const location = useLocation();
    const newSubmitForm = useRef(null);
    const capthcaWrapper = useRef(null);
    const thankyouNote = useRef(null);
    const [captchaState, setCaptchaState] = useState(null);
    const [formNewsProps, setFormNewsProps] = useState({'email_address': undefined, 'captcha_token': undefined});

    const [submitEmailDisabled, setSubmitEmailDisabled] = useState(true);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const onFormNewInputChange = (emailInput) => {
        setFormNewsProps((previus) => ({...previus, ...{'email_address': emailInput.target.value}}));
        console.log(formNewsProps);
    }

    const handleSubmitSucess = (response) => {
        console.log(response);
        newSubmitForm.current.className = 'nodisplay';
        capthcaWrapper.current.className = 'nodisplay';
        thankyouNote.current.className = '';
    }

    const handleSubmitError = (response) => {
        console.log(response)
    }

    const subscribeToNews = () => {
        console.log('subscription clicked');

        sendJson(process.env.REACT_APP_NEWS_REGISTRATION,
            handleSubmitSucess,handleSubmitError, formNewsProps, headers);
    }

    const onCaptchaChange = (value) => {
        setCaptchaState(value)
    }

    useEffect(() => {
        if(captchaState){
            setSubmitEmailDisabled(false);
            setFormNewsProps((previus) => ({...previus, ...{'captcha_token': captchaState}}));
        }
    }, [captchaState]);

    useEffect(() => {
        let requested_location = location.hash.slice(1)
        if (requested_location ){
            if(requested_location === 'tracker') { // scroll to anchor
                const offset = tracker_ref.current.getBoundingClientRect().top;
                animateScroll.scrollMore(offset);
            } else if(requested_location === 'monitor'){
                const offset = monitor_ref.current.getBoundingClientRect().top;
                animateScroll.scrollMore(offset);
            }
        }
    },[location.hash]);

    return (
        <Fragment>
            <div className={'main_home_container'}>
                {/*campaigns module*/}
                <div className="py-4 bg-secondary">
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-black mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold">B-Tech Campaigns</h1>
                                            <p className="font-size-lg text-black-50">
                                                A platform connecting multiple publisher CPA/CPM networks to advertisers that do not want to take the risk and perfer to work with CPA Model.
                                                <br /><br />
                                                This unique model enables you to get traffic from "Click", "Push" and "Native" ads campaigns while paying ONLY for traffic that actually converted!<span className={'red_dot medium_dot'}> *</span>
                                                <br /><br />
                                                The platform also provides a full ads campaign management and optimisation system, various and flexible payment methods, finance management tools and much more... <br />
                                            </p>
                                            <p
                                                className="font-size-lg text-black-50">
                                                The platform provides full managment and controll over traffic and compaigns.
                                            </p>
                                            <p><span className={"red_dot medium_dot"}>*</span><span className={'product_italic font-size-sm text-black-50'}>Subject to <Link to={'cpc-to-cpa'} className={'reg_link'} >CPC to CPA Policy and trial period</Link>.</span></p>

                                            </div>
                                    </div>
                                </div>
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="..." className="w-100 mx-auto d-block img-fluid img-right img-after-header"
                                         src={panels_image}/></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="py-4 bg-dark">
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="descirption" className="w-100 d-block img-fluid img-left img-after-header"
                                         src={campaign_image}/></div>
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-white mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold text-white">How it works:</h1>
                                            <p className="font-size-lg text-white">1) You create an advertisment campaign.</p>
                                            <p className="font-size-lg text-white">2) Defined the regular campaign parameters such as: industry, countries, goals, etc.</p>
                                            <p className="font-size-lg text-white">3) Configure the "Lead Scoring Engine" with attributes like: <br /> refferer category, country of origin, email / phone validation, VPN presence, etc.</p>
                                            <p className="font-size-lg text-white">4) Defined your API integration using simple JSON tool, or define redirect path.</p>
                                            <p className="font-size-lg text-white">5) Agree to Terms & conditions and save the campaign.</p>
                                            <p className="font-size-lg text-white">6) Waite for 5-30 minutes while your campaign syncs to multiple CPA/CPM advertisement platforms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="py-4 bg-white">
                    <div className="text-center py-4">
                        {/*<p className="font-size-lg mb-4 text-black-50">Checkout Our Live Demo to get the*/}
                        {/*    look &amp; feel</p>*/}
                        <div
                            className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                        <div>

                            <a
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                            tabIndex="0" role="button" aria-disabled="false" rel="noopener" target="_blank"
                            title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                            href="/registration#FirstStep"><span className="MuiButton-label"><span className="btn-wrapper--icon"><svg
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                            className="svg-inline--fa fa-external-link-alt fa-w-16 " role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor"
                                                                                           d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                            className="btn-wrapper--label">Register</span></span><span
                            className="MuiTouchRipple-root"></span></a></div>
                        </div>
                    <div
                        className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                </div>

                {/*tracking module*/}
                <div className="py-4 bg-secondary" ref={tracker_ref}>
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-black mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold">B-Tech Tracker</h1>
                                            <p className="font-size-lg text-black-50">
                                                Never be in the dark about your web traffic again.
                                                Create tracking campaigns with a few clicks of a button, generate tracking pixels and links for clicks,
                                                leads and goals. <br /><br />
                                                Create redirect urls, cookie trackers and usage trackers while maintaining transparency and compliance rules with built in data anonymization!
                                                our detailed live dashboards and historical reports will give you full and clear visibility of your business data.<br /><br />
                                                Business data visibility have never been simpler!<span className={'red_dot medium_dot'}> *</span>
                                            </p>
                                            <p><span className={"red_dot medium_dot"}>*</span><span className={'product_italic font-size-sm text-black-50'}>We do not record any PII as defined by GDPR.</span></p>

                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="..." className="w-100 mx-auto d-block img-fluid img-right img-after-header"
                                         src={dashboard_example_image}/></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="py-4 bg-dark">
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="descirption" className="w-100 d-block img-fluid img-left img-after-header"
                                         src={campaigns_image}/></div>
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-white mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold text-white">How it works:</h1>
                                            <p className="font-size-lg text-white">1) You register for the reporting service in your admin panel.</p>
                                            <p className="font-size-lg text-white">2) You Define a tracking api call token or Webhook token.</p>
                                            <p className="font-size-lg text-white">3) You Define the "sub" parameters you want to track (up to 5 parameters).</p>
                                            <p className="font-size-lg text-white">4) You implement the generated tracker on your landing pages / server call hook.</p>
                                            <p className="font-size-lg text-white">5) You subscribe to the reporting service.</p>
                                            <p className="font-size-lg text-white">6) A grafana style dashboards are created for your company and campaigns.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="py-4 bg-white">
                    <div className="text-center py-4">
                        {/*<p className="font-size-lg mb-4 text-black-50">Checkout Our Live Demo to get the*/}
                        {/*    look &amp; feel</p>*/}
                        <div
                            className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                        <div>

                            <a
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                                tabIndex="0" role="button" aria-disabled="false" rel="noopener" target="_blank"
                                title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                                href="/registration#FirstStep"><span className="MuiButton-label"><span className="btn-wrapper--icon"><svg
                                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                                className="svg-inline--fa fa-external-link-alt fa-w-16 " role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor"
                                                                                               d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                                className="btn-wrapper--label">Register</span></span><span
                                className="MuiTouchRipple-root"></span></a></div>
                    </div>
                    <div
                        className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                </div>

                {/*monitor module*/}
                <div className="py-4 bg-secondary" ref={monitor_ref}>
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-black mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold">B-Tech Monitor</h1>
                                            <p className="font-size-lg text-black-50">
                                                Monitor tools are vital to any commercial web operation!.<br /> Any minute of downtime on your webpages while running advertising campaigns
                                                equals to money loss!
                                                <br /><br />
                                                We provide a tool for webpage health monitoring, but without the hustle of looking at charts and ping speeds.<br /> This tool is designed for business people, not for NOC centers
                                                or DevOps teams meaning you will not need either of them to keep track of your web page health status and even get suggestion on issue remediation in real time.<span className={'red_dot medium_dot'}> *</span>
                                            </p>

                                            <p><span className={"red_dot medium_dot"}>*</span><span className={'product_italic font-size-sm text-black-50'}> Currently only domains behind CloudFlare CDN are supported.</span><br />
                                            <span className={"red_dot medium_dot"}>**</span><span className={'product_italic font-size-sm text-black-50'}> Currently SMS method of notification is available only in IL,UK,BY and ES.</span></p>

                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="..." className="w-100 mx-auto d-block img-fluid img-right img-after-header"
                                         src={domains_image}/></div>
                            </div>
                        </div>
                    </div>

                </div  >

                <div className="py-4 bg-dark">
                    <div className="bg-composed-wrapper--content pt-5 pb-2 py-lg-5">
                        <div className="MuiContainer-root pb-5 MuiContainer-fixed MuiContainer-maxWidthLg">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-4">
                                <div
                                    className="MuiGrid-root px-0 d-none d-lg-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-5 MuiGrid-grid-xl-6">
                                    <img alt="descirption" className="w-100 d-block img-fluid img-left img-after-header"
                                         src={domains_large}/></div>
                                <div
                                    className="MuiGrid-root d-flex align-items-center MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-lg-7 MuiGrid-grid-xl-6">
                                    <div>
                                        <div className="text-white mt-3"><h1
                                            className="display-2 mb-3 font-weight-bold text-white">How it works:</h1>
                                            <p className="font-size-lg text-white">1) You create a cloudflare account record in your admin panel.</p>
                                            <p className="font-size-lg text-white">2) You receive a notification that the account was approved and an html script.</p>
                                            <p className="font-size-lg text-white">2) You place the html file in the root folder of your domain.</p>
                                            <p className="font-size-lg text-white">3) On your monitor panel domains associated with the account will appear.</p>
                                            <p className="font-size-lg text-white">4) Upon a validation fail you will receive a notification by SMS, Email or Chat.</p>
                                            <p className="font-size-lg text-white">5) Upon a domain "Slow" response time as defined in monitor parameters the domain will be marked as "SLOW" on the panel dashboard.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="py-4 bg-white">
                    <div className="text-center py-4">
                        {/*<p className="font-size-lg mb-4 text-black-50">Checkout Our Live Demo to get the*/}
                        {/*    look &amp; feel</p>*/}
                        <div
                            className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                        <div>

                            <a
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained py-3 px-5 mx-2 MuiButton-containedSecondary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                                tabIndex="0" role="button" aria-disabled="false" rel="noopener" target="_blank"
                                title="Read about what Carolina React Admin Dashboard with Material-UI PRO has to offer!"
                                href="/registration#FirstStep"><span className="MuiButton-label"><span className="btn-wrapper--icon"><svg
                                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt"
                                className="svg-inline--fa fa-external-link-alt fa-w-16 " role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor"
                                                                                               d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></span><span
                                className="btn-wrapper--label">Register</span></span><span
                                className="MuiTouchRipple-root"></span></a></div>
                    </div>
                    <div
                        className="divider border-2 border-dark my-4 mx-auto border-light opacity-2 rounded-circle w-25"></div>
                </div>

                <div className="bg-first py-5">
                    <div>
                        <div className="MuiContainer-root text-center MuiContainer-maxWidthMd"><h1
                            className="display-3 mb-4 text-white font-weight-bold">Stay up to date</h1></div>
                        <div
                            className="divider border-2 d-sm-none d-md-block rounded-circle border-white bg-white opacity-1 mx-auto mb-4 mt-5 w-50"></div>
                        <div
                            className="divider border-2 d-sm-none d-md-block rounded-circle border-white bg-white opacity-1 mx-auto my-4 w-50"></div>
                        <div className="MuiContainer-root text-center MuiContainer-maxWidthMd">
                            <div
                                className="MuiPaper-root MuiCard-root mt-5 card-box MuiPaper-elevation1 MuiPaper-rounded">
                                <div className="MuiCardContent-root p-3">
                                    <div className="text-black"><h1
                                        className="display-4 mb-3 font-weight-bold">Newsletter updates</h1><p
                                        className="font-size-md mb-4 text-black-50">Subscribe to our newsletter to be
                                        the first to find out when we offer promotions or discounts for our
                                        products.</p>
                                        <div id={"mc_embed_signup_thankyou"} ref={thankyouNote} className={"nodisplay"} > <p className="font-size-xl text-black-50">Thank You For Registering To Our News!<br/></p></div>
                                        <div id="mc_embed_signup" ref={newSubmitForm}>
                                            <form
                                                className="validate"
                                                id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                noValidate>
                                                <Grid container spacing={4}>
                                                    <Grid xs={8} item>
                                                        <div id="mc_embed_signup_scroll">
                                                            <div className="mc-field-group">
                                                                <TextField
                                                                    fullWidth
                                                                    className="mt-0"
                                                                    margin="dense"
                                                                    id="mce-EMAIL"
                                                                    onChange={onFormNewInputChange}
                                                                    name="email"
                                                                    type="email"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <MailOutlineTwoToneIcon />
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="clear" id="mce-responses">
                                                                <div
                                                                    className="response"
                                                                    id="mce-Errorresponse"
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <div
                                                                    className="response"
                                                                    id="mce-success-response"
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </div>
                                                            <div
                                                                aria-hidden="true"
                                                                style={{ position: 'absolute', left: '-5000px' }}>
                                                                <input
                                                                    name="b_3ebc33a3d37f2278158d74721_a122e673aa"
                                                                    tabIndex="-1"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid xs={4} item>
                                                        <Button
                                                            className="w-100"
                                                            color="primary"
                                                            variant="contained"
                                                            id="mc-embedded-subscribe"
                                                            name="subscribe"
                                                            onClick={subscribeToNews}
                                                            disabled={submitEmailDisabled}
                                                        >
                                                            <span className="btn-wrapper--label">Signup</span>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>

                                        </div>
                                        <div className={"capthca_center"} ref={capthcaWrapper}>
                                            <ReCaptchaV2 sitekey="6Lc_jRodAAAAAG1tM3J2gluAoCk9Xq6jrev_1QOL" onChange={onCaptchaChange}/>
                                        </div>
                                        <small className="text-black-50 d-block pt-3">We promise not to spam your inbox.
                                            We also hate spam!</small></div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5"><span className="text-center d-block text-white-50">Copyright © 2021 - <a
                            className="text-white" href="/" title="UiFort">B-Tech</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductsPage;

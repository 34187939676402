import React, {useRef, Fragment} from 'react';
import {
    MenuItem,useMenuState, ControlledMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import down_arrow from '../../theme/assets/down-arrow-white.svg'

export default function ProductsMenu() {
    const ref = useRef(null);
    const {toggleMenu, ...menuProps} = useMenuState({transition: true});

    return (
        <Fragment>
                <div className={'menu_item'}>
                    <div ref={ref} onMouseEnter={() => toggleMenu(true)} className={'menu_item_text'}>
                        Products<img src={down_arrow} className={'menu_down_arrow'} alt={'open sub menu'}/>
                    </div>

                    <ControlledMenu {...menuProps} anchorRef={ref}
                                    onMouseLeave={() => toggleMenu(false)}
                                    onClose={() => toggleMenu(false)}>
                        {/*<a href={'/products#campaigns'} className={'menu_link'}><MenuItem>Services</MenuItem></a>*/}
                        <a href={'/products#campaigns'} className={'menu_link'}><MenuItem>B-Tech Campaigns</MenuItem></a>
                        <a href={'/products#tracker'} className={'menu_link'}><MenuItem>B-Tech Tracker</MenuItem></a>
                        <a href={'/products#monitor'} className={'menu_link'}><MenuItem>B-Tech Monitor</MenuItem></a>
                    </ControlledMenu>
                </div>
        </Fragment>

    );
}
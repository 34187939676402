import React, {Fragment} from "react";
import './policy.css';
export default function PrivacyPolicy(){
    return (
      <Fragment>
          <div className="container">
              <div className="content educate_content">
                  <section className="section section__article">
                      <div className="breadcrumb" dir="ltr">
                          <div className="link__arrow o__ltr">
                              <a href="/policy-all">Policies & Contracts</a>
                          </div>

                          <div className="link__arrow o__ltr">Privacy Policy
                          </div>
                      </div>

                      <div className="paper paper__large">
                          <div className="content content__narrow__mid">
                              <div className="article intercom-force-break">
                                  <div className="article__meta" dir="ltr">
                                      <h1 className="t__h1">Privacy Policy</h1>
                                      <div className="article__desc">

                                      </div>
                                      <div className="avatar">
                                          <div className="avatar__photo o__ltr">
                                              <span className="avatar__image avatar__fallback"> A </span>

                                          </div>
                                          <div className="avatar__info">
                                              <div>
                                                  Written by <span className='c__darker'> Admin</span>
                                                  <br /> Updated over a week ago
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <article dir="ltr">
                              <header className="entry-header">
                              </header>
                                      <div className="entry-content">
                                          <div id="terms">
                                              <p>&nbsp;</p>
                                              <p>B-Tech(referred to as “B-Tech” or
                                                  “we” or “us” or “our”) is committed to protecting and respecting your
                                                  privacy and handling your personal data in an open and transparent
                                                  manner. The personal data that we collect and process may vary
                                                  depending on the service we provide to you.</p>

                                              <p>This privacy policy:</p>

                                              <ul>
                                                  <li>provides an overview of how B-Tech collects and processes
                                                      your personal data, and informs you about your rights under the EU
                                                      General Data Protection Regulation (“GDPR”) and any national law
                                                      supplementing or implementing the GDPR,
                                                  </li>
                                                  <li>Contains important information about, among others, the collection
                                                      and use of personal data; the legal grounds for the processing of
                                                      that data; disclosures of that personal data to third parties and
                                                      the use of cookies on the Website.
                                                  </li>
                                              </ul>

                                              <p>For the purposes of this privacy policy:</p>

                                              <ul>
                                                  <li>when we refer to “personal data” we mean data which identifies or
                                                      may identify you and which may include, for example, your name,
                                                      address, identification number, telephone number, date of birth,
                                                      occupation, family status and IP address
                                                  </li>
                                                  <li>when we refer to “processing” we mean the handling of your
                                                      personal data by us, including collecting, protecting and storing
                                                      your personal data, and
                                                  </li>
                                                  <li>when we refer to our “Website” or our “Platform” we mean
                                                      www.bandoftech.com or www.bandofdomain.com
                                                  </li>
                                              </ul>

                                              <h3>Who we are</h3>

                                              <p>B-Tech is a company registered in Israel,
                                                  under registration number 306469206.</p>

                                              <p>This Privacy Policy will apply to B-Tech or any other location that may be updated in feuture.</p>

                                              <h3>Our Data Protection Principles</h3>

                                              <p>We adhere to the principles relating to processing of personal data set
                                                  out in the GDPR which require personal data to be:</p>

                                              <ol className={'terms_and_conditions_ol'}>
                                                  <li>Processed lawfully, fairly and in a transparent manner
                                                      (Lawfulness, Fairness and Transparency).
                                                  </li>
                                                  <li>Collected only for specified, explicit and legitimate purposes
                                                      (Purpose Limitation).
                                                  </li>
                                                  <li>Adequate, relevant and limited to what is necessary in relation to
                                                      the purposes for which it is Processed (Data Minimisation).
                                                  </li>
                                                  <li>Accurate and where necessary kept up to date (Accuracy).</li>
                                                  <li>Not kept in a form which permits identification of data subjects
                                                      for longer than is necessary for the purposes for which the data
                                                      is Processed (Storage Limitation).
                                                  </li>
                                                  <li>Processed in a manner that ensures its security using appropriate
                                                      technical and organisational measures to protect against
                                                      unauthorised or unlawful processing and against accidental loss,
                                                      destruction or damage (Security, Integrity and Confidentiality).
                                                  </li>
                                                  <li>Not transferred to another country without appropriate safeguards
                                                      being in place (Transfer Limitation).
                                                  </li>
                                                  <li>Made available to data subjects and data subjects allowed to
                                                      exercise certain rights in relation to their Personal Data (Data
                                                      Subject's Rights and Requests).
                                                  </li>
                                                  <li>We are responsible for and must be able to demonstrate compliance
                                                      with the data protection principles listed above (Accountability).
                                                  </li>
                                              </ol>

                                              <h2><strong>Privacy Policy</strong></h2>

                                              <h3>1. Data Controller</h3>

                                              <p>1.1 This privacy policy applies where we are acting as a data
                                                  controller with respect to your personal data and therefore determine
                                                  the purposes and means of the processing of that personal data.</p>

                                              <p>1.2 We will ask you to consent to our use of cookies in accordance with
                                                  the terms of this policy when you first visit our Website.</p>

                                              <p>1.3 Our Website incorporates privacy controls you can use to specify
                                                  whether you would like to receive marketing, limit the publication or
                                                  any other use of your information. You can access such privacy
                                                  controls via <a
                                                      href="mailto:dpo@bandoftech.com">dpo@bandoftech.com</a>.</p>

                                              <h3>2. How we collect your personal data</h3>

                                              <p>2.1 We obtain your personal data mainly through any information you
                                                  provide directly to us or through information provided by third
                                                  parties. Below is a list of ways in which we collect your personal
                                                  data.</p>

                                              <p>Personal data collected directly from you, including:</p>

                                              <ul>
                                                  <li>when you complete our Publisher or Advertiser registration forms,
                                                      which are available on our Website,
                                                  </li>
                                                  <li>when you enter and use our Website, and/or performing contractual
                                                      obligations
                                                  </li>
                                                  <li>when you contact us for any enquiries, complaints or for any other
                                                      reason.
                                                  </li>
                                              </ul>

                                              <p>Personal data collected from other sources, including:</p>

                                              <ul>
                                                  <li>via third parties including our business partners, contractors
                                                      and/or vendors in a manner that is permitted
                                                  </li>
                                                  <li>through tags in websites you use and/or manage or control.</li>
                                              </ul>

                                              <h3>3. Personal Data We Use, Purposes and Legal Grounds</h3>

                                              <p>We may collect the following personal data from you depending on the
                                                  service we provide to you. We will only process your personal data
                                                  when the law allows us to. Most commonly, we will use your personal
                                                  data in the following circumstances:</p>

                                              <table width="100%">
                                                  <colgroup>
                                                      <col width="227"/>
                                                      <col width="241"/>
                                                      <col width="228"/>
                                                  </colgroup>
                                                  <tbody>
                                                  <tr valign="top">
                                                      <td width="227" height="8"><span lang="en-GB"><b>Type of information</b></span>
                                                      </td>
                                                      <td width="241"><span lang="en-GB"><b>Purpose</b></span></td>
                                                      <td width="228"><span
                                                          lang="en-GB"><b>Legal basis of processing</b></span></td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227">
                                                          <p><strong>Data about your use of our Website.</strong></p>
                                                          <p><span lang="en-GB">IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views, website navigation paths, timing, frequency, pattern of your service use</span>.
                                                          </p>
                                                      </td>
                                                      <td width="241">
                                                          <p>To analyse the operation of our Website and services.</p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Legitimate interests in monitoring and improving our Website and services</span>.
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="59">
                                                          <p><strong>Profile data</strong></p>
                                                          <p>Your name, address, telephone number, email address,
                                                              gender, date of birth.</p>
                                                      </td>
                                                      <td width="241">
                                                          <p>Operating our Website, providing our services,
                                                              communicating with you. Fulfilment of KYC / AML
                                                              procedures (if required ONLY).</p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Legitimate interests in the proper administration of our Website.</span>
                                                          </p>
                                                          <p><span lang="en-GB">Performing the contract we have with you. </span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="111">
                                                          <p><strong>Publication data</strong></p>
                                                          <p>Information that you post for publication on our Website or
                                                              through our services.</p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">The publication data may be processed for the purposes of enabling such publication and administering our Website and services</span>.
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Legitimate interests for the proper administration of our Website and business. </span>
                                                          </p>
                                                          <p><span lang="en-GB">Performing the contract we have with you. </span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="133">
                                                          <p><strong>Inquiry data</strong></p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">The inquiry data may be processed for the purposes of fulfilment of our KYC and AML obligations if required. The proper performance of </span><span
                                                              lang="en-GB">our payment obligations to you.</span></p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Performing the contract we have with you. </span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="107">
                                                          <p><strong>Transaction data</strong></p>
                                                          <p><span lang="en-GB">The transaction data your contact details, your card details and the transaction details</span>.
                                                          </p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">T</span><span lang="en-GB">he transaction data may be processed for the purpose of payments and keeping proper records of those transactions.</span>
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Performing the contract we have with you. </span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="98">
                                                          <p><strong>Notification data</strong></p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters.</span>
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Consent. Performing the contract we have with you.</span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="110">
                                                          <p><strong>Correspondence data</strong></p>
                                                          <p><span lang="en-GB">The communication content and metadata associated with the communication.</span>
                                                          </p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">The proper performance of </span><span
                                                              lang="en-GB">our contractual obligation before you.</span>
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Consent. Performing the contract we have with you.</span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="110">
                                                          <p><strong>Any of your personal data identified in this
                                                              policy</strong></p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">Exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure, the protection and assertion of our legal rights, your legal rights and the legal rights of others.</span>
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Our legitimate interests. </span></p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="227" height="74">
                                                          <p><strong>Any of your personal data identified in this
                                                              policy</strong></p>
                                                      </td>
                                                      <td width="241">
                                                          <p><span lang="en-GB">The obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice.</span>
                                                          </p>
                                                      </td>
                                                      <td width="228">
                                                          <p><span lang="en-GB">Our legitimate interests.</span></p>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>

                                              <h3>4. Information We May Provide to Third Parties</h3>

                                              <p>We may disclose your personal data to the third parties listed below.
                                                  When we do so, we require those third parties to have appropriate
                                                  technical and organisational measures in place to protect your
                                                  personal data. We will not share any of your personal data for any
                                                  purpose other than the purposes described in this privacy policy, nor
                                                  will we sell your personal data to anyone.</p>

                                              <p>4.1 Any member of our group of companies (this means our subsidiaries,
                                                  our ultimate holding company and all its subsidiaries) insofar as
                                                  reasonably necessary for the purposes, and on the legal bases, set out
                                                  in this privacy policy.</p>

                                              <p>4.2 To our insurers and/or professional advisers insofar as reasonably
                                                  necessary for the purposes of managing risks, obtaining professional
                                                  advice, or the establishment, exercise or defence of legal claims,
                                                  whether in court proceedings or in an administrative or out-of-court
                                                  procedure.</p>

                                              <p>4.3 Payment services providers we have chosen to support us with
                                                  financial transactions relating to our Website. We will share
                                                  transaction data with our payment services providers only to the
                                                  extent necessary for the purposes of processing your payments,
                                                  refunding such payments and dealing with complaints and queries
                                                  relating to such payments and refunds.</p>

                                              <p>4.4 Governmental and regulatory bodies, including law enforcement
                                                  authorities, in connection with enquiries, proceedings or
                                                  investigations by such parties or in order to enable B-Tech to
                                                  comply with its legal and regulatory requirements.</p>

                                              <p>4.5 Partners, contractors and/or vendors in order to perform contract
                                                  we have with you.</p>

                                              <h3>5. Your data protection rights</h3>

                                              <p>You have the following rights in terms of the personal data we hold
                                                  about you:</p>

                                              <table className="privacy-table" width="745" cellSpacing="0"
                                                     cellPadding="8">
                                                  <colgroup>
                                                      <col width="217"/>
                                                      <col width="495"/>
                                                  </colgroup>
                                                  <tbody>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to be informed</strong></p>
                                                      </td>
                                                      <td width="495">B-Tech is publishing this privacy policy to
                                                          keep you informed as to what we do with your personal data.
                                                          You can ask us for information regarding any data of yours
                                                          that we keep at any time. This information concerns, among
                                                          other things, the data categories we process, for what
                                                          purposes we process them, the origin of the data if we did not
                                                          acquire them directly from you and, if applicable, the
                                                          recipients to whom we have sent your data.
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to access</strong></p>
                                                      </td>
                                                      <td width="495">This enables you to receive a copy of the personal
                                                          data we hold about you and to check that we are lawfully
                                                          processing it. You can obtain a copy of your data from us free
                                                          of charge. If you are interested in other copies, we reserve
                                                          the right to charge for the additional copies.
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to correction (rectification)</strong>
                                                          </p>
                                                      </td>
                                                      <td width="495">You can request that we correct your data. We will
                                                          initiate appropriate measures to keep the data of yours that
                                                          we continuously process correct, complete, and up to date,
                                                          based the latest information available to us.
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><span lang="en-GB"><strong>The right to erasure<br />(The right to be forgotten)</strong></span>
                                                          </p>
                                                      </td>
                                                      <td width="495"><p>You can request that we delete your data
                                                          provided the legal requirements have been met. In accordance
                                                          with Article 17 the GDPR, this can be the case if:</p>
                                                          <ul>
                                                              <li>the data are no longer required for the purposes they
                                                                  were acquired or otherwise processed;
                                                              </li>
                                                              <li>you revoke your consent, which is the basis of the
                                                                  data processing, and there is no other legal basis for
                                                                  the processing;
                                                              </li>
                                                              <li>you object to the processing of your data and there
                                                                  are no legitimate reasons for the processing or you
                                                                  object to data processing for the purposes of direct
                                                                  advertising;
                                                              </li>
                                                              <li>the data have been processed illegally.</li>
                                                          </ul>
                                                          <p>Where the processing is not necessary:</p>
                                                          <ul>
                                                              <li>To ensure adherence to a legal obligation that
                                                                  requires us to process your data
                                                              </li>
                                                              <li>In particular with regard to legal retention periods
                                                              </li>
                                                              <li>To assert, exercise or defend against legal claims
                                                              </li>
                                                          </ul>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to restrict processing</strong></p>
                                                      </td>
                                                      <td width="495">
                                                          <p><span lang="en-GB">You can request that we restrict the processing of your data if</span>
                                                          </p>

                                                          <ul>
                                                              <li>You dispute the correctness of the data - for the
                                                                  period of time we need to check the correctness of the
                                                                  data;
                                                              </li>
                                                              <li>The processing is illegal but you do not wish to have
                                                                  your data deleted and request a restriction of use
                                                                  instead;
                                                              </li>
                                                              <li>We no longer need your data, but you need them to
                                                                  assert, exercise or defend against legal claims;
                                                              </li>
                                                              <li>You have filed an objection to the processing, though
                                                                  it has not yet been decided whether our legitimate
                                                                  grounds outweigh yours.
                                                              </li>
                                                          </ul>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to data portability</strong></p>
                                                      </td>
                                                      <td width="495">
                                                          <p><span lang="en-GB">At your request, we will transfer your data– where technically possible – to another responsible entity.</span>
                                                          </p>
                                                          <p><span lang="en-GB">However, this right only applies if the data processing is based on your consent or is required to fulfill a contract. Instead of receiving a copy of your data, you can ask us to send the data directly to another responsible entity that you specify.</span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to object</strong></p>
                                                      </td>
                                                      <td width="495">
                                                          <p><span lang="en-GB">You can object to the processing of your data at any time for reasons that arise from your special situation provided the data processing is based on your consent or our legitimate interest or that of a third party. In this case, we will no longer process your data. The latter does not apply if we are able to prove there are compelling, defensible reasons for the processing that outweigh your interests or we require your data to assert, exercise or defend against legal claims.</span>
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217">
                                                          <p><strong>The right to withdraw consent</strong></p>
                                                      </td>
                                                      <td width="495">
                                                          <p>Withdraw the consent you gave us with regard to the
                                                              processing of your personal data for certain purposes,
                                                              such as to allow us to promote our products and services
                                                              to you.</p>
                                                      </td>
                                                  </tr>
                                                  <tr valign="top">
                                                      <td width="217" height="5">
                                                          <p><strong>The right to complain</strong></p>
                                                      </td>
                                                      <td width="495">
                                                          <p><span lang="en-GB">B-Tech takes your rights very seriously. However, if you are of the opinion that we have not dealt with your complaints adequately, you have the right to submit a complaint to the data privacy protection authorities responsible.</span>.
                                                          </p>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>

                                              <p>We endeavour to address all of your requests promptly.</p>

                                              <h3>6. International Transfers of Your Personal Data</h3>

                                              <p>6.1 We do not envisage that any of your personal data will be
                                                  transferred to countries outside the European Economic Area (EEA) and Israel as
                                                  our offices, facilities, subcontractors and other third parties with
                                                  which we work are all located within the EEA and Israel.
                                                  <br />
                                                  To Clarify the data it self is stored in cloud services in EEA and is not hard copied to Israel, for any purpose other then,
                                                  temporary access by B-Tech in order to perform our obligations to you or exercise our legitimate intrest for a specific task performed.
                                              </p>

                                              <p>6.2. In the event that this changes in the future and one or more
                                                  recipients of your personal data are located outside the EEA and Israel, where
                                                  data protection laws may be of a lower standard than in the EEA and Israel, we
                                                  will impose the same data protection safeguards that we deploy inside
                                                  the EEA to ensure that your personal data are always protected.</p>

                                              <h3> 7. Retaining and Deleting Personal Data</h3>

                                              <p>7.1 We will keep your personal data for as long as is necessary for the
                                                  purpose for which we initially collected them. Once such period has
                                                  ended, we will keep your personal data for the longest of the
                                                  following periods: a) any retention period set out in our retention
                                                  policy which is in line with regulatory requirements relating to
                                                  retention; or (b) the end of the period in which legal action or
                                                  investigations might arise in respect of the services provided.</p>

                                              <p>7.2 Once the retention period of your personal data lapses, we will
                                                  ensure that your personal data are either irreversibly anonymised or
                                                  securely destroyed.</p>

                                              <h3>8. Confidentiality and Security</h3>

                                              <p>8.1 We deploy various security measures such as encryption and
                                                  authentication tools in line with the current state of the art to
                                                  protect and maintain the security, integrity and availability of your
                                                  personal data.</p>

                                              <p>8.2 100% protection against unauthorised access in the case of data
                                                  transfers across the internet or a website cannot be guaranteed, but
                                                  we and our service providers and business partners do our utmost to
                                                  protect your personal data in line with the prevailing data protection
                                                  legislation by means of physical and electronic physical precautions.
                                                  Among other things, we use the following measures:</p>

                                              <ul>
                                                  <li>
                                                      <p>Strict criteria for authorisation to access your personal data
                                                          on a “need-to-know” basis only and exclusively for the
                                                          specified purpose;</p>
                                                  </li>
                                                  <li>
                                                      <p>Transfer of acquired data in secured form;</p>
                                                  </li>
                                                  <li>
                                                      <p>Storage of confidential data in our secure servers in encrypted state;</p>
                                                  </li>
                                                  <li>
                                                      <p>Firewall safeguarding of IT systems to provide protection
                                                          against unauthorised access;</p>
                                                  </li>
                                                  <li>
                                                      <p>Continuous monitoring of access to IT systems to detect and
                                                          prevent the misuse of personal data.</p>
                                                  </li>
                                              </ul>

                                              <p>8.3 If you have received a password from us in order to be able to
                                                  access certain parts of our Website, you are responsible for keeping
                                                  this password confidential and for compliance with all other security
                                                  procedures which we make you aware from time to time. We ask you not
                                                  to share your password with anyone. <br />Any action in our system with your identification (username / password / certificate)
                                                  Will be considered as performed by you and you are responsible for any such action, unless you are able to prove that the said action
                                                  was performed by a third party without your knowledge and not due to lack of measures you have taken in order to ensure your access credentials
                                                  are not transferred / leak / obtained in any other way by others.
                                              </p>

                                              <h3>9. Cookies</h3>

                                              <p>9.1 Our Website may use cookies to distinguish you from other users of the
                                                  Website and provide contractual obligations in a high quality level.
                                                  This helps us to ensure its smooth operation in order to improve your
                                                  experience. For detailed information on the cookies we use and the
                                                  purposes for which we use them, please see our Cookies Policy at <a
                                                      href="https://bandoftech.com/cookies/">https://bandoftech.com/cookies/</a>
                                              </p>

                                              <h3>10. Changes to this privacy policy</h3>

                                              <p>10.1 We may update this privacy policy from time to time by publishing
                                                  a new version on our Website.</p>

                                              <p>10.2 We will notify you appropriately when we make changes to the
                                                  privacy policy and we will amend its revision date so that you know
                                                  when we last amended it. We do however encourage you to review this
                                                  statement periodically so as to always be informed about how we are
                                                  processing and protecting your personal information.</p>

                                              <p>10.3 We may notify you of changes to this policy by email or through
                                                  the private messaging system on our Website.</p>

                                              <h3>11 Contacts</h3>

                                              <p>11.1 If you have any questions about our privacy policy or wish to
                                                  obtain more details in relation to the personal data we process about
                                                  you or wish to exercise any of your rights set out in Section 5,
                                                  please contact us via email at: <a
                                                      href="mailto:dpo@bandoftech.com">dpo@bandoftech.com</a></p>

                                              <p><em>Last update: Aug 20, 2021</em></p>
                                              <p>&nbsp;</p>
                                          </div>
                                      </div>
                       </article>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
      </Fragment>
    );
}
import React, {Fragment} from 'react';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


export default function HomeMenu() {

    return (
        <Fragment>
            <div className={'menu_item_first'}>
                < a href={'/'} className={'menu_link_flat'}>Home</a>
            </div>
        </Fragment>

    );
}
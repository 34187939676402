import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

export default function ComplienceAll() {
    return (
        <Fragment>
            <div className="container">
                <div className="content educate_content">
                    <section className="section section__article">
                        <div className="breadcrumb" dir="ltr" style={{zIndex: 1}}>
                            <div className="link__arrow o__ltr">
                                <a href="/complience-all">Compliance & Campaign Quality Guidelines</a>
                            </div>

                            <div className="link__arrow o__ltr">map</div>
                        </div>

                        <div className="paper paper__large">
                            <div className="content content__narrow">
                                <div className="article intercom-force-break">
                                    <div className="article__meta" dir="ltr">
                                        <h1 className="t__h1">Compliance & Campaign Quality Guidelines Overview</h1>
                                        <div className="article__desc">
                                        </div>
                                        <div className="avatar">
                                            <div className="avatar__photo o__ltr">
                                                <span className="avatar__image avatar__fallback"> A </span>

                                            </div>
                                            <div className="avatar__info">
                                                <div>

                                                    Written by
                                                    <span className='c__darker'> Admin</span>
                                                    <br />
                                                    Updated over a week ago

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <article dir="ltr">
                                        <p className="no-margin">Welcome to the Advertising Policies Center. Here you'll find the requirements for advertising on the B-Tech Network.</p>
                                        <p className="no-margin"></p>

                                        <h2 id="specific-rules-for-ad-formats" data-post-processed="true">Compliance & Campaign Quality Guidelines list</h2>
                                        <ul>
                                            <li>
                                                <p className="no-margin">
                                                    <Link to={'/rules-for-push-notifications-campaign'} >Rules for Push Notifications campaigns</Link>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="no-margin">
                                                    <Link to={'/rules-for-native-notifications-campaign'} >Rules for Native Ads Campaigns</Link>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="no-margin">
                                                    <Link to={'/rules-for-onclick-notifications-campaign'} >Rules for OnClick Campaigns</Link>
                                                </p>
                                            </li>
                                        </ul>
                                        <p className="no-margin">
                                            <i>To make sure you comply with our rules read carefully our campaign Compliance and Quality Guidelines mentioned above as well as our&nbsp;<Link to={'/policy-all'}> Policies & Contracts</Link>&nbsp;<br /></i>
                                            <br />
                                            <i>Please note: Campaign approval or rejection remains at B-Tech’ sole discretion, active campaigns are constantly reviewed by our Compliance team and may be stopped at anytime without prior notice.</i>
                                        </p>
                                    </article>
                                </div>
                            </div>
                            <div className="intercom-reaction-picker" dir="ltr">
                                <div className="intercom-reaction-prompt">&nbsp;</div>

                                <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0" aria-label="Disappointed Reaction">
                                    <span data-emoji="disappointed" title="Disappointed"></span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0" aria-label="Neutral face Reaction">
                                    <span data-emoji="neutral_face" title="Neutral face"></span>
                                </button>
                                <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0" aria-label="Smiley Reaction">
                                    <span data-emoji="smiley" title="Smiley"></span>
                                </button>
                            </div>

                        </div>

                    </section>
                </div>
            </div>
        </Fragment>
    );
}
import {Route, Switch} from "react-router-dom";
import FAQ from "./pages/faq";
import AdvertiserPolicy from "./pages/policies/advertiser_policy";
import React from "react";
import ProhibitedContentPage from "./pages/policies/prohibited_content";
import TermsAndConditions from "./pages/policies/terms_and_conditions";
import PrivacyPolicy from "./pages/policies/privacy_policy";
import AdvertiserPolicyAll from "./pages/policies/policies_all";
import RulesForPush from "./pages/complience-and-quality/rules-for-push-campaigns";
import RulesForOnclick from "./pages/complience-and-quality/rules-for-onclick-campaigns";
import RulesForNative from "./pages/complience-and-quality/rules-for-native-campaigns";
import ComplienceAll from "./pages/complience-and-quality/compliance-all";
import LandingPage from "./pages/home";
import CPCTOCPMPolicyPage from "./pages/policies/cpc_to_cpm";
import RegistrationPage from "./pages/registration";
import PagesRegister from "./pages/register";
import ProductsPage from "./pages/products";

export default function BtechRouter(){
    return (
        <Switch>
            <Route path="/home" component={LandingPage}>
                <LandingPage />
            </Route>
            <Route path="/products" component={ProductsPage}>
                <ProductsPage />
            </Route>
            <Route path="/faq" component={FAQ}>
                <FAQ />
            </Route>
            <Route path="/advertiser-policy" component={AdvertiserPolicy} >
                <AdvertiserPolicy />
            </Route>
            <Route path="/advertisers" component={AdvertiserPolicy}>
                <AdvertiserPolicy />
            </Route>
            <Route path={'/prohibited-content'} component={ProhibitedContentPage}>
                <ProhibitedContentPage />
            </Route>
            <Route path={'/terms-and-conditions'} component={TermsAndConditions}>
                <TermsAndConditions />
            </Route>
            <Route path={'/privacy-policy'} component={PrivacyPolicy}>
                <PrivacyPolicy />
            </Route>
            <Route path={'/policy-all'} component={AdvertiserPolicyAll}>
                <AdvertiserPolicyAll />
            </Route>
            <Route path={'/rules-for-push-notifications-campaign'} component={RulesForPush}>
                <RulesForPush />
            </Route>
            <Route path={'/rules-for-onclick-notifications-campaign'} component={RulesForOnclick}>
                <RulesForOnclick />
            </Route>
            <Route path={'/rules-for-native-notifications-campaign'} component={RulesForNative}>
                <RulesForNative />
            </Route>
            <Route path={'/compliance-all'} component={ComplienceAll}>
                <ComplienceAll />
            </Route>
            <Route path={'/cpc-to-cpa'} component={CPCTOCPMPolicyPage}>
                <CPCTOCPMPolicyPage />
            </Route>
            <Route path={'/registration'} component={PagesRegister}>
                <PagesRegister />
            </Route>
            <Route path="/" component={LandingPage}>
                <LandingPage />
            </Route>
        </Switch>
    );
}
import React, {useRef, Fragment} from 'react';
import {
    MenuItem,useMenuState, ControlledMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import down_arrow from "../../theme/assets/down-arrow-white.svg";


export default function PolicyMenu() {
    const ref = useRef(null);
    const {toggleMenu, ...menuProps} = useMenuState({transition: true});
    return (
        <Fragment>
                <div className={'menu_item'}>
                    <div ref={ref} onMouseEnter={() => toggleMenu(true)} className={'menu_item_text'}>
                        Policies and Contracts<img src={down_arrow} className={'menu_down_arrow'} alt={'open sub menu'}/>
                    </div>

                    <ControlledMenu {...menuProps} anchorRef={ref}
                                    onMouseLeave={() => toggleMenu(false)}
                                    onClose={() => toggleMenu(false)}>
                        < a href={'/terms-and-conditions'} className={'menu_link'}><MenuItem>Terms & Conditions</MenuItem> </a>
                        < a href={'/privacy-policy'} className={'menu_link'}><MenuItem>Privacy Policy</MenuItem> </a>
                        < a href={'/prohibited-content'} className={'menu_link'}><MenuItem>Prohibited Content, Products and Services</MenuItem> </a>
                        < a href={'/cpc-to-cpa'} className={'menu_link'}><MenuItem>CPA Model Policy and Conditions</MenuItem> </a>
                    </ControlledMenu>
                </div>
        </Fragment>
    );
}
import React, {useRef, Fragment} from 'react';
import {
    MenuItem,useMenuState, ControlledMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import down_arrow from "../../theme/assets/down-arrow-white.svg";


export default function GuideLinesMenu() {
    const ref = useRef(null);
    const {toggleMenu, ...menuProps} = useMenuState({transition: true});
    return (
        <Fragment>
                <div className={'menu_item'} >
                    <div ref={ref} onMouseEnter={() => toggleMenu(true)} className={'menu_item_text'}>
                        Compliance & Quality Rules<img src={down_arrow} className={'menu_down_arrow'} alt={'open sub menu'}/>
                    </div>

                    <ControlledMenu {...menuProps} anchorRef={ref}
                                    onMouseLeave={() => toggleMenu(false)}
                                    onClose={() => toggleMenu(false)}>
                        < a href={'/rules-for-push-notifications-campaign'} className={'menu_link'}><MenuItem>Push Notification Campaign Rules</MenuItem> </a>
                        < a href={'/rules-for-onclick-notifications-campaign'} className={'menu_link'}><MenuItem>OnClick Campaigns Rules</MenuItem> </a>
                        < a href={'/rules-for-native-notifications-campaign'} className={'menu_link'}><MenuItem>Native Ads Campaign Rules</MenuItem> </a>
                    </ControlledMenu>
                </div>
        </Fragment>
    );
}
import React, {useEffect} from "react";

import {
    BrowserRouter as Router, useLocation
} from "react-router-dom";
import MainMenu from "./layout-components/menu";
import BtechRouter from "./router";

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default function App() {
    const panel = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_ADMIN_PATH;
    return (
        <Router>
            <header className="header">
                <div className="container header__container o__ltr" dir="ltr">
                    <div className="content">
                        <div className="mo o__centered o__reversed header__meta_wrapper">
                            <div className="mo__body header__site_name">
                                <div className="header__logo">
                                    <a href="/en/">
                                    </a>
                                </div>
                            </div>
                            <div className="mo__aside">
                                <div className="header__links">
                                    <a target="_blank" rel='noreferrer' href={panel}
                                       className="header__home__url menu_link_flat">
                                        <svg width="14" height="14" viewBox="0 0 14 14"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="#FFF" fill="none" fillRule="evenodd" strokeLinecap="round"
                                               strokeLinejoin="round">
                                                <path d="M11.5 6.73v6.77H.5v-11h7.615M4.5 9.5l7-7M13.5 5.5v-5h-5"/>
                                            </g>
                                        </svg>
                                        <span>Go To Panel</span>
                                    </a>

                                </div>
                            </div>
                        </div>
                        {/*<div>*/}
                        <MainMenu />
                        {/*    <nav>*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <Link to="/">Home</Link>*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                <Link to="/about">About</Link>*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                <Link to="/advertisers" >Users</Link>*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </nav>*/}
                    </div>
                </div>
            </header>
            <div>


                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <ScrollToTop />
                <BtechRouter />
            </div>
        </Router>
    );
}


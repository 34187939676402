import React, {Fragment} from 'react';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


export default function FAQMenu() {

    return (
        <Fragment>
                <div className={'menu_item'}>
                    < a href={'/faq'} className={'menu_link_flat'}>FAQ</a>
                </div>
        </Fragment>

    );
}
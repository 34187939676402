import React, { Fragment } from 'react';
// import push_anatomy from '../../theme/assets/images/Push_Anatomy_1280x800.png'
import image_1_land_no from "../../theme/assets/images/Image_1_Land_NO.png"
import image_1_2_land_yes from "../../theme/assets/images/Image_1_2_Land_Yes.png"
import brand_no_9 from "../../theme/assets/images/3_Brand_NO_9.png"
import brand_no_5 from "../../theme/assets/images/3_Brand_NO_5.png"
import brand_no_11 from "../../theme/assets/images/3_Brand_NO_11.png"
import brand_yes from "../../theme/assets/images/Brand_YES.png"
import google_2_no from "../../theme/assets/images/Google_2_NO.png"
import partial_nudity_no from "../../theme/assets/images/Partial_nudity_NO.png"
import pn_sextoy_no_1 from "../../theme/assets/images/4_PN_SexToy_NO_1.png"
import num1 from "../../theme/assets/images/15544139-8BAB-485E-BE54-4C183FA20E48.png"
import num2 from "../../theme/assets/images/0EAC5E91-2CFD-4058-9B61-6EBA6EFFC059.png"
import scary_1_yes from "../../theme/assets/images/Scary_1_YES.png"
import scary_2_yes from "../../theme/assets/images/Scary_2_YES.png"
import brand_no_2 from "../../theme/assets/images/3_Brand_NO_2.png"
import super_scary_1_yes from "../../theme/assets/images/Super_Scary_1_YES.png"
import super_scary_3_yes from "../../theme/assets/images/Super_Scary_3_YES.png"
import scary_4_no from "../../theme/assets/images/Scary_4_NO.png"
import sex_wellness_1_yes from "../../theme/assets/images/Sex_Wellness_1_YES.png"
import pn_sysmess_no_1 from "../../theme/assets/images/4_PN_SysMess_NO_1.png"
import pn_scary_yes_1 from "../../theme/assets/images/4_PN_Scary_YES_1.png"
import pn_scary_no_1 from "../../theme/assets/images/3_PN_Scary_NO_1.png"
import r3 from "../../theme/assets/images/3R_2.png"
import r4 from "../../theme/assets/images/4A_2.png"
import wk_sites_1_no from "../../theme/assets/images/WK_sites_1_NO.png"
import wk_sites_2_no from "../../theme/assets/images/WK_sites_2_NO.png"
import wk_sites_2_yes from "../../theme/assets/images/WK_sites_2_YES.png"
import wk_sites_1_yes from "../../theme/assets/images/WK_sites_1_YES.png"
import image_4_no from "../../theme/assets/images/CDD-853_Image_4_NO.png"
import celebrities_yes from "../../theme/assets/images/Celebrities_YES.png"
import {Link} from "react-router-dom";
// import { PageTitle } from '../../layout-components';

export default function RulesForOnclick() {
    return (
        <Fragment>

            {/*<PageTitle titleHeading="FAQ" titleDescription="Campaign Rules" />*/}
            <div className="content educate_content">
                <section className="section section__article">
                    <div className="breadcrumb" dir="ltr">
                        <div className="link__arrow o__ltr">
                            <a href="/compliance-all">Compliance & Campaign Quality Guidelines </a>
                        </div>
                        <div className="link__arrow o__ltr">Rules for OnClick Campaigns</div>
                    </div>

                    <div className="paper paper__large">
                        <div className="content content__narrow">
                            <div className="article intercom-force-break">
                                <div className="article__meta" dir="ltr">
                                    <h1 className="t__h1">Rules for OnClick Campaigns</h1>
                                    <div className="article__desc">
                                    </div>
                                    <div className="avatar">
                                        <div className="avatar__photo o__ltr">
                                            <span className="avatar__image avatar__fallback"> A </span>

                                        </div>
                                        <div className="avatar__info">
                                            <div>

                                                Written by
                                                <span className='c__darker'> Admin</span>
                                                <br />
                                                    Updated over a week ago
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <article dir="ltr">
                                    <p className="no-margin">All campaigns and ads must comply with the following
                                        policies:</p>
                                    <p className="no-margin"></p>
                                    <ul>
                                        <li>
                                            <p className="no-margin">
                                                <b>
                                                    <Link to={'/prohibited-content'}> Prohibited
                                                        content</Link>
                                                </b>
                                            </p>
                                        </li>
                                    </ul>
                                    <h3 id="policy-rules" data-post-processed="true">Policy rules:</h3>
                                    <ol>
                                        <li>
                                            <p className="no-margin">
                                                <b>
                                                    <i>Landing pages must not be of poor quality (contain typos,
                                                        misleading images, empty pages, etc.).</i>
                                                </b>
                                            </p>
                                        </li>
                                    </ol>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={image_1_land_no}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={image_1_2_land_yes}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>2. Brand logos / brand references </i>
                                        </b>
                                        (including well-known messengers, applications, postal services, newspapers or
                                        their web-based editions, government of Russia and other countries, public
                                        authorities)
                                        <br />
                                    </p>
                                    <ul>
                                        <li>
                                            <p className="no-margin">It is forbidden to use brand logos on the icons
                                                apart from the products that can be produced by these brands (including
                                                buttons, arrows etc.)</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">It is not accepted to use icons of mobile / desktop
                                                applications </p>
                                        </li>
                                        <li>
                                            <p className="no-margin">It is prohibited to create the campaigns where
                                                there is a statement that promoted offers are provided on behalf of the
                                                brand.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">The use of logos of famous brands on the landing
                                                pages is not allowed</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Landing pages with brand names spelled wrong or
                                                with hidden letters are prohibited</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">It is allowed to use the marketplace logo if the
                                                copyright holder guarantees this right</p>
                                        </li>
                                    </ul>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={brand_no_9}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={brand_no_5}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={brand_no_11}/>
                                    </div>
                                    <p className="no-margin">
                                        <br />
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={brand_yes}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>3. The Google logo</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">Use of Google logo is prohibited.</p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={google_2_no}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>4. Sexually provocative or suggestive texts/images/icons are
                                                prohibited. </i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <ul>
                                        <li>
                                            <p className="no-margin">Creatives with depictions of sexual acts, sexual
                                                organs, masturbation, etc. will be rejected.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Sexual activities (including the squeezing of the
                                                female breast), erected body parts, images of partially visible
                                                intercourse are not allowed.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Images that imply sexual contact (even through
                                                clothing) will also be rejected.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Images of women and men that initially show
                                                genitals or sexual intercourse but are "covered up" by banners, packs
                                                and cans of pills/gels, fruit/vegetables, etc. are prohibited.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Creatives with depictions of full and partial
                                                nudity, people in explicit or suggestive positions, sex toys, etc. are
                                                not accepted.</p>
                                        </li>
                                    </ul>
                                    <p className="no-margin">
                                        Landing pages with an anatomical images of the genitals
                                        <i>are not prohibited</i>
                                        (including pictures of medical checkups).
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>Unacceptable for push texts</b>
                                        : “Milfs want to have orgasm with you”.
                                        <br />
                                            <b>Acceptable for push texts</b>
                                            : “Girls want to chat ALL NIGHT”, “Say hi to your future girlfriend in app”.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={partial_nudity_no}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={pn_sextoy_no_1}/>
                                    </div>
                                    <p className="no-margin">The creatives with people wearing swimsuits or
                                        undergarments are accepted if there are no sexually suggestive texts and
                                        images.</p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={num1}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={num2}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>5. Pornography, adult or mature content is strictly forbidden to
                                            advertise.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <ul>
                                        <li>
                                            <p className="no-margin">Child Porn - it is strictly prohibited to promote
                                                any materials that contain child erotic or pornography or sexual
                                                exploitation of underaged persons.</p>
                                        </li>
                                        <li>
                                            <p className="no-margin">Zoo porn - It is strictly prohibited to promote any
                                                materials that contain pornography with human and non-human animals.</p>
                                        </li>
                                    </ul>
                                    <p className="no-margin">
                                        <i>Сampaigns containing this content will be rejected.</i>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>6. Images of various human secretions (feces, urine, spit, snot,
                                            menstruation, or vomit) are not allowed.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>7. Violence/ Terrorism</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">It is strictly prohibited to promote any materials related
                                        to terrorism and weapons, violent and brutal videos and images. </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>8. Scary offers</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        It is acceptable to use any information in creatives, texts and landing pages
                                        about
                                        <b>possible</b>
                                        viruses,
                                        <b>potential</b>
                                        threats, file damage etc., which can frighten users.
                                        <br />
                                            Scary offers with the texts like “A virus can/may damage”, “You may have
                                            viruses/problems/..”, "Scan completed", "Click here to fix" are allowed.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        However, please pay attention, that
                                        <b>it is forbidden to use logos of brands </b>
                                        on scary LPs/icons/push texts, such as Google, Google Chrome, Yandex, Apple,
                                        Safari, Adobe, Flash, Windows, Microsoft.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={scary_1_yes}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={scary_2_yes}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={brand_no_2}/>
                                    </div>
                                    <p className="no-margin">
                                        <br />
                                            <b>
                                                <i>9. SuperScary offers</i>
                                            </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        Any statements about viruses, junk files, device problems etc. are not allowed.
                                        <br />
                                            <b>
                                                <i>Any number of viruses indicated is considered to be as super scary
                                                    statement and must be rejected. </i>
                                            </b>
                                            <br />
                                                <br />
                                                    <b>Unacceptable:</b>
                                                    “Click here to solve this problem”, "System warning: Remove junk
                                                    files (
                                                    <b>~ 3.4 Gb</b>
                                                    )", " You may have
                                                    <b>3 viruses</b>
                                                    ", “Update required”, "Run a scan system to fix all the problems
                                                    that slow down this PC", "Clear your Android memory to solve this
                                                    problem and increase your phone’s speed", "Browser failed to load /
                                                    open the video/page" etc.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={super_scary_1_yes}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={super_scary_3_yes}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={scary_4_no}/>
                                    </div>
                                    <p className="no-margin">
                                        <br />
                                            <b>
                                                <i>10. Phishing offers.</i>
                                            </b>
                                            <br />
                                                <br />
                                                    Phishing
                                                    <b>
                                                        <i></i>
                                                    </b>
                                                    messages with the statements about winning / receiving large and
                                                    substantial gifts are prohibited.
                                                    <br />
                                                        Phishing messages about crediting or transferring funds are also
                                                        prohibited.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        Campaigns, where in order to pick up your winning a user needs to add a sum of
                                        money, are not allowed.
                                        <br />
                                            <br />
                                                <b>Unacceptable:</b>
                                                "You won iPhone / car", "1,000$ was credited to your account". The
                                                statements about the potential winning or small prizes like coupons are
                                                allowed.
                                                <br />
                                                    <b>Acceptable:</b>
                                                    "You may win iPhone / money", "You won a gift card / coupon /
                                                    certificate".
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        Phishing
                                        <b>
                                            <i></i>
                                        </b>
                                        messages with the statements about the prize payment are not allowed as well.
                                        <b>Unacceptable:</b>
                                        “Your phone has been paid. Claim it now”
                                        <br />
                                            <br />
                                                <b>
                                                    <i>11. Offers of sexual wellness products (e. g. genital enlargement
                                                        products, prostate treatment drugs, male potency supplements
                                                        etc.)</i>
                                                </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        Push notifications with harmless statements are allowed (e. g. ‘Useful tips for
                                        men on how to return the male power’).
                                        <br />
                                            Push texts with the words "prostate", "viagra", "potency" etc. are
                                            prohibited. Landing pages containing mentioned words are accepted.
                                            <br />
                                                <br />
                                                    <b>Please also note that there should be an indication that the
                                                        promoted product is a dietary supplement. </b>
                                                    If it is medicinal drugs, the landing page will be rejected.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={sex_wellness_1_yes}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>12. Imitation of system updates / messages is not allowed. </i>
                                        </b>
                                        <br />
                                            It is forbidden to imitate system messages of operating systems on LPs and
                                            creatives.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={pn_sysmess_no_1}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={pn_scary_yes_1}/>
                                    </div>
                                    <p className="no-margin">
                                        <br />
                                            <b>
                                                <i>13. Imitation of browser system messages is unacceptable.</i>
                                            </b>
                                            <br />
                                                It is forbidden to imitate icons and interfaces of well-known browsers.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={pn_scary_no_1}/>
                                    </div>
                                    <p className="no-margin"></p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>14. "Empty messages" </i>
                                        </b>
                                        <br />
                                            <br />
                                                "Empty messages" are combinations of texts and icons that imitate a
                                                message and don’t give any explanations of the offer.
                                                <br />
                                                    <b>
                                                        <i>"Empty messages" are allowed if there are no brand logos on
                                                            the icons / landing pages.</i>
                                                    </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={r3}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={r4}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>15. Imitation of interfaces of well-known sites</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        It is forbidden to imitate interfaces of well-known and popular sites and
                                        companies, including using the logos of well-known websites in order to make the
                                        landing page look like the official website or increase the credibility of the
                                        landing page.
                                        <br />
                                            Imitation of the interface without logo of the brand is allowed (but it's
                                            easy to guess what interface you mean).
                                            <br />
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={wk_sites_1_no}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={wk_sites_2_no}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={wk_sites_2_yes}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={wk_sites_1_yes}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>16. Vulnerabilities</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin"> Landing pages that exploit the vulnerabilities in a
                                        browser (for example, to install a file) are prohibited.</p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>17. Malware</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">The ads must not contain malicious software or “malware”
                                        that may infect computers and mobile devices.</p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>18. Automatic downloading is prohibited (without a click)</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>19. Technical support offers are not allowed.</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>20. Push notification campaigns with prelanders and landing pages focused
                                                on collecting subscribers are not accepted.</i>
                                        </b>
                                        <br />
                                            <br />
                                                This condition will not affect campaigns with landing pages, where
                                                subscription form is used for additional monetization. Push notification
                                                on such landings should not block the screen.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>21. Notification request cheat</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">Landing pages collecting subscriptions by a notification
                                        request cheat on a landing page (misleading users into subscribing to offers by
                                        reloading or blocking the offer page) are rejected.</p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>22. Use of celebrities' names and pictures</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">The use of celebrities or politicians' names for “fake
                                        news” promotion or negative representation of the individuals featured is not
                                        allowed. It is forbidden to promote the product / diet on behalf of a
                                        celebrity.</p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>Unacceptable: </b>
                                        “Breaking: [Name Surname] is dead!”.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>Acceptable:</b>
                                        “ANYONE can profit from Bitcoin. Bill Gates explains why...[video]”.
                                    </p>
                                    <p className="no-margin"></p>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={image_4_no}/>
                                    </div>
                                    <div className="intercom-container">
                                        <img alt="example of content"
                                            src={celebrities_yes}/>
                                    </div>
                                    <p className="no-margin">
                                        <b>
                                            <i>23. Political advertising is prohibited.</i>
                                        </b>
                                        <br />
                                            <br />
                                                It is forbidden to promote any political parties or persons, calls for a
                                                violent overthrow of the state regime along with using these persons in
                                                advertising purposes.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>24. </i>
                                            Offers with automatic dialing of phone numbers, as well as with a request to
                                            send sms messages are prohibited
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>25. Promotion of pharmaceuticals</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">We only allow promotion of dietary supplements that are not
                                        regulated like medicine.</p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>
                                            <i>26. Messages from postal services</i>
                                        </b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        Any messages on behalf of the post office are prohibited.
                                        <br />
                                            Campaigns with the statements about payment / awaiting / delivery (including
                                            the delivery number) of any prize, package, etc. are not allowed and will be
                                            rejected.
                                            <br />
                                                <b>Unacceptable: </b>
                                                “Order ES98685149AU delivered. You have 1 Package Pending”, “Your phone
                                                has been paid. Claim it now”, “(1) Message from Serbian Post”.
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>27. Fraud, unofficial, untrue, false, misleading, invented, re-produced
                                            information (i.e. facts, news, offers, solutions, guidelines), including
                                            false and defamatory information about COVID, false information about
                                            natural disasters, acts of God etc.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>28. We do not allow promotion of brands which have been referred as
                                            misleading, suspicious or fraudulent by respective authorities of the
                                            state.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>29. Advertising of any citizenship/passport schemes are prohibited</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>30. Imitation emails on behalf of well-known banks are prohibited.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>31. Calendar subscriptions are forbidden both for iOS and Android.</b>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin">
                                        <b>32. Reselling our traffic through our direct link campaigns is
                                            prohibited. </b>
                                    </p>
                                    <p className="no-margin">
                                        <br />
                                            <i>Please note: your landings pages, texts, images, and creatives must
                                                comply with the policies above. These guidelines are not exhaustive, and
                                                we will reject or remove any campaign's items that we believe to be
                                                harmful to our publishers or users, even if the campaign items otherwise
                                                comply with all of our guidelines.</i>
                                    </p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin"></p>
                                    <p className="no-margin"></p>
                                </article>
                            </div>
                        </div>
                        <div className="intercom-reaction-picker" dir="ltr">
                            <div className="intercom-reaction-prompt">&nbsp;</div>

                            <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0"
                                    aria-label="Disappointed Reaction">
                                <span data-emoji="disappointed" title="Disappointed"></span>
                            </button>
                            <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0"
                                    aria-label="Neutral face Reaction">
                                <span data-emoji="neutral_face" title="Neutral face"></span>
                            </button>
                            <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0"
                                    aria-label="Smiley Reaction">
                                <span data-emoji="smiley" title="Smiley"></span>
                            </button>
                        </div>

                    </div>

                </section>
            </div>
        </Fragment>
    );
}

import React, {Fragment, useState, useEffect, useRef} from 'react';
import StepWizard from "react-step-wizard";

import Nav from './nav';

import styles from './wizard.css';
import transitions from './transitions.css';
import {
    Container,

    Button,
    TextField, Select, MenuItem, Checkbox,
} from '@material-ui/core';
import {Link} from "react-router-dom";
import geolist from "../dropdowns/geolist";
import sendJson from "../../services/post";
// import remoteRoutes from "../../remoteRoutes";
import ReCaptchaV2 from 'react-google-recaptcha'
import getRemoteJson from "../../services/remote_get";
import validateEmail from "../../services/validators/email";
import ValidateNumber from "../../services/validators/numbers";
import MinLength from "../../services/validators/minlength";
import NotEmpty from "../../services/validators/notEmpty";
/* eslint react/prop-types: 0 */

/**
 * A basic demonstration of how to use the step wizard
 */

const geos = geolist;


const RegWizard = (props) => {
    // console.log(props.update_bredcrumbs);
    const [defaultGeo, updateDefaultGeo] = useState(null);
    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        },
        // demo: true, // uncomment to see more
    });

    const getFormData = () => {
        const {form} = state;
        const response = {}
        for (let attr of Object.keys(form)) {
            response[attr] = form[attr] !== undefined && form[attr] !== '' ? form[attr] : null;
        }

        return response
    }

    const updateForm = (key, value) => {
        const {form} = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        // console.log(stats);
    };

    // const onSubmitForm = () => {
    //     const {form} = state;
    //     // console.log(form)
    // }

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const {SW, demo} = state;



    useEffect(() => {
        const handleSubmitSucess = (response) => {
            if(!defaultGeo && response.hasOwnProperty('g')){
                updateDefaultGeo(response.g);
            }
        }
        const handleSubmitError = (response) => {
            console.log(response);
        }
        getRemoteJson(process.env.REACT_APP_GEO_SERVICE_PATH,
            handleSubmitSucess,handleSubmitError, {});
    },[]);


    return (
        <div className='container'>
            <div className={'jumbotron'}>
                <div className='row'>
                    <div className={`col-12 col-sm-6 offset-sm-3 ${styles['rsw-wrapper']}`}>
                        <StepWizard
                            onStepChange={onStepChange}
                            isHashEnabled
                            transitions={state.transitions} // comment out for default transitions
                            nav={<Nav/>}
                            instance={setInstance}
                        >
                            <First hashKey={'FirstStep'} update={updateForm} form={state.form} geo={defaultGeo} />
                            <Second hashKey={'SecondStep'} update={updateForm} form={state.form}/>
                            <Third hashKey={'Third'} update={updateForm} form={state.form}/>
                            <Progress stepName='progress' update={updateForm} form={state.form}
                                      getFromData={getFormData}/>
                            {null /* will be ignored */}
                            <Last hashKey={'TheEnd!'}/>
                        </StepWizard>
                    </div>
                </div>
            </div>
            {/*{ (demo && SW) && <InstanceDemo SW={SW} UP={props.update_bredcrumbs} /> }*/}
            {(demo && SW) && <InstanceDemo SW={SW} UP={props.update_bredcrumbs}/>}
        </div>
    );
};

export default RegWizard;

/** Demo of using instance */
const InstanceDemo = ({SW, UP}) => {
    return (
        <Fragment>
            {/*<h4>Control from outside component</h4>*/}
            {/*<button className={'btn btn-secondary'} onClick={SW.previousStep }>Previous Step</button>*/}
            {/*&nbsp;*/}
            {/*<button className={'btn btn-secondary'} onClick={SW.nextStep}>Next Step</button>*/}
            {/*&nbsp;*/}
            {/*<button className={'btn btn-secondary'} onClick={() => SW.goToNamedStep('progress')}>Submit</button>*/}
        </Fragment>
    );
}

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
                   currentStep,
                   firstStep,
                   goToStep,
                   lastStep,
                   nextStep,
                   previousStep,
                   totalSteps,
                   step,
               }) => {

    return (
        <div>
            <Container maxWidth="sm">
                <hr/>
                {step > 1 && step <= 3 &&
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={previousStep}
                    className="mb-5 marge-left-10">
                    Back
                </Button>
                    // <button className='btn btn-default btn-block' onClick={previousStep}>Go Back</button>
                }
                {step < 3 &&
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={nextStep}
                    className="mb-5 marge-left-10">
                    Next
                </Button>
                    // <button className='btn btn-primary btn-block' onClick={nextStep}>Continue</button>
                }
                {step === 3 &&
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={nextStep}
                    className="mb-5 marge-left-10">
                    Submit
                </Button>
                    // <button className='btn btn-primary btn-block' onClick={nextStep}>Continue</button>
                }
                {step > 3 &&
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={event => window.location.href = '/'}
                    fullWidth
                    className="mb-5 marge-left-10">
                    Home
                </Button>
                    // <button className='btn btn-primary btn-block' onClick={nextStep}>Continue</button>
                }

                {/*<hr />*/}
                {/*<div style={{ fontSize: '21px', fontWeight: '200' }}>*/}
                {/*    <h4>Other Functions</h4>*/}
                {/*    <div>Current Step: {currentStep}</div>*/}
                {/*    <div>Total Steps: {totalSteps}</div>*/}
                {/*    <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button>*/}
                {/*    <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button>*/}
                {/*    <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button>*/}
                {/*</div>*/}
            </Container>
        </div>
    );
}

/** Steps */

const First = props => {
    const [country_value, setCountryValue] = useState('none')
    const [area_code_value, setAreaCodeValue] = useState('none')
    const [countryOverrideDone, setCountryOverrideDone] = useState(false);

    const fields_in_step = {
        'company_name': useRef(),
        'company_email': useRef(),
        'industry': useRef(),
        'company_country': useRef(),
        'company_area_code': useRef(),
        'company_phone_number': useRef(),
        'address': useRef(),
    };

    const field_validators = {
        'company_name': [{'error': 'Company Name must be at least 2 char long', 'validator':MinLength(2)},{'error': 'please fill company name', 'validator':NotEmpty}],
        'company_email': [{'error': 'please enter a valid email', 'validator':validateEmail}, {'error': 'please enter a valid email', 'validator':NotEmpty}],
        'industry': [{'error': 'please select industry', 'validator':NotEmpty}],
        'company_country': [{'error': 'please select country', 'validator':NotEmpty}],
        'company_phone_number': [{'error': 'please enter a valid phone number', 'validator':ValidateNumber},{'error': 'please enter a valid phone number', 'validator':NotEmpty}],
        'company_area_code': [{'error': 'please select area code', 'validator':ValidateNumber},{'error': 'please select area code', 'validator':NotEmpty}],
        'address': [{'error': 'please fill real address', 'validator':MinLength(2)},{'error': 'please fill address', 'validator':NotEmpty}]
    }

    const { current } = fields_in_step['company_country'];

    const update_error_state = (elem) => {
        if (elem.childNodes.length > 2) {
            elem.childNodes[0].classList.remove('error_field');
            elem.childNodes[0].classList.add('error_field');
        } else if (elem.childNodes.length === 2) {
            elem.childNodes[1].classList.remove('error_field');
            elem.childNodes[1].classList.add('error_field');
        }
    }

    const validateNext = (e) => {
        const errors = []
        const val_errors = {};
        // Object.entries(fields_in_step).map(([key, value], i) => {
        //     if (props.form[key] === undefined || props.form[key] === '' || props.form[key] === null) {
        //         update_error_state(fields_in_step[key].current);
        //         console.log(key);
        //         errors.push(key);
        //     }
        //     return key;
        // });

        Object.entries(field_validators).map(([key, value], i) => {
            console.log(key);
            console.log(value);
            value.forEach((validator) => {
                if(!validator.validator(props.form[key]) && !(key in val_errors)){
                    val_errors[key] = validator.error;
                    errors.push(validator.error);
                    update_error_state(fields_in_step[key].current);
                }
            })
            return key;
        });
        console.log(val_errors);
        if (errors.length > 0 && confirm('Please correct the following errors: \n' + errors.join('\n'))) { // eslint-disable-line
            props.update('first_step_done', false);
            return false;
        } else {
            props.update('first_step_done', true);
            props.nextStep();
        }
    }

    const update = (e) => {
        if (fields_in_step[e.target.name].current.childNodes.length > 2) {
            fields_in_step[e.target.name].current.childNodes[0].classList.remove('error_field');
        } else if (fields_in_step[e.target.name].current.childNodes.length === 2) {
            fields_in_step[e.target.name].current.childNodes[1].classList.remove('error_field');
        }
        props.update(e.target.name, e.target.value);
    };

    const set_geo_values = (geo) => {
        setCountryValue(geo);
        setAreaCodeValue(geos[geo].phone_prefix);
        fields_in_step['company_area_code'].current.childNodes[0].classList.remove('error_field');
        // props.update('company_area_code', geos[geo].phone_prefix);
        update({'target': {'name': 'company_area_code', 'value': geos[geo].phone_prefix}})
        update({'target': {'name': 'company_country', 'value': geo}})
    }

    const updateCountrySelect = (e) => {
        console.log('folte');
        setCountryOverrideDone(true);
        set_geo_values(e.target.value);
    }

    useEffect(()=> {
        if(current && !countryOverrideDone && props.geo){
            console.log('rende');
            console.log(props.geo);
            set_geo_values(props.geo);
        }
    }, [props.geo, current]);



    let div = <>
        <div>
            <Container maxWidth="sm">
                <h3 className="display-4 mb-2 font-weight-bold">
                    Company Details
                </h3>
                <p className="font-size-lg mb-5 text-black-50">
                    Please Fill all fields accurately!
                </p>
                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'company_name'}
                        onChange={update}
                        label="Company Name"
                        ref={fields_in_step.company_name}
                        fullWidth
                        placeholder="Enter Company Name"
                        type="text"
                    />
                </div>
                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'company_email'}
                        onChange={update}
                        label="Email address"
                        ref={fields_in_step.company_email}
                        fullWidth
                        placeholder="Enter Official Company Email"
                        type="email"
                    />
                </div>
                <div className="mb-3">
                    <Select
                        variant="outlined"
                        label="Company Industry"
                        value={props.form.industry ? props.form.industry : 'none'}
                        defaultValue={'none'}
                        ref={fields_in_step.industry}
                        name={'industry'}
                        onChange={update}
                        fullWidth
                        placeholder="Company Industry"
                    >
                        <MenuItem value={'none'} style={{'display': "none"}}>Select Company Industry</MenuItem>
                        <MenuItem value={'insurance'}>Insurance</MenuItem>
                        <MenuItem value={'finance'}>Finance</MenuItem>
                        <MenuItem value={'realestate'}>Realestate</MenuItem>
                        <MenuItem value={'ecommerce'}>Ecommerce</MenuItem>
                        <MenuItem value={'entertainment'}>Entertainment</MenuItem>
                        <MenuItem value={'dating'}>Dating</MenuItem>
                        <MenuItem value={'gaming'}>Gaming</MenuItem>
                        <MenuItem value={'technology'}>Technology</MenuItem>
                        <MenuItem value={'webservices'}>Webservices</MenuItem>
                        <MenuItem value={'other'}>Other</MenuItem>
                    </Select>
                </div>
                <div className="mb-3">
                    <Select
                        variant="outlined"
                        label="Country Of Registration"
                        // defaultValue={'none'}
                        name={'company_country'}
                        ref={fields_in_step.company_country}
                        value={country_value}
                        onChange={updateCountrySelect}
                        fullWidth
                        placeholder="Select Registration Conutry"
                    >
                        <MenuItem value={'none'} key={'none_0'} style={{'display': "none"}}>Select Country</MenuItem>
                        {Object.entries(geos).map(([key, value], i) => <MenuItem key={'country_code_' + i.toString()}
                                                                                 value={key}>{value.country_name}</MenuItem>)}

                        {/*{ Object.entries(geolist).map((t,k) => <option key={'area_code_key_' + k.toString()} value={t[0]}>{t[1]}</option>) }*/}
                    </Select>
                </div>
                <div className="mb-3">
                    <Select
                        variant="outlined"
                        label="Area Code"
                        value={area_code_value}
                        defaultValue={'CA'}
                        name={'company_area_code'}
                        ref={fields_in_step.company_area_code}
                        onChange={update}
                        placeholder="Area Code"
                        style={{'width': '25%'}}
                    >
                        {Object.entries(geos).map(([key, value], i) => <MenuItem key={'area_code_' + i.toString()}
                                                                                 value={value.phone_prefix}>{value.phone_prefix}</MenuItem>)}

                    </Select>
                    <TextField
                        variant="outlined"
                        name={'company_phone_number'}
                        label="Phone Number"
                        onChange={update}
                        ref={fields_in_step.company_phone_number}
                        placeholder="Enter Phone Number"
                        type="text"
                        style={{'width': '71%', 'marginLeft': '4%'}}
                    />
                </div>
                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'address'}
                        onChange={update}
                        label="Company Address"
                        ref={fields_in_step.address}
                        fullWidth
                        placeholder="Enter Company Address"
                        type="text"
                    />
                </div>


            </Container>
            <Stats step={1} {...props} nextStep={validateNext}/>


        </div>
    </>;
    return div;
};

const Second = props => {
    const validate = () => {
        if (confirm('Are you sure you want to go back? data from this step will be lost!')) { // eslint-disable-line
            props.previousStep();
        }
    };
    const fields_in_step = {
        'vat_id': useRef(),
        'company_size': useRef(),
        'payment_method': useRef(),
        'name_on_invoice': useRef(),
        'address_on_invoice': useRef(),
    }

    const field_validators = {
        'vat_id': [{'error': 'Please enter valid VAT no.', 'validator':MinLength(2)},{'error': 'please fill VAT no.', 'validator':NotEmpty}],
        'company_size': [{'error': 'please Select company size.', 'validator':NotEmpty}],
        'payment_method': [{'error': 'please select preferred payment method.', 'validator':NotEmpty}],
        'name_on_invoice': [{'error': 'please fill legal entity name.', 'validator':NotEmpty}],
        'address_on_invoice': [{'error': 'please fill legal entity address', 'validator':NotEmpty}],
    };

    const update_error_state = (elem) => {
        if (elem.childNodes.length > 2) {
            elem.childNodes[0].classList.remove('error_field');
            elem.childNodes[0].classList.add('error_field');
        } else if (elem.childNodes.length === 2) {
            elem.childNodes[1].classList.remove('error_field');
            elem.childNodes[1].classList.add('error_field');
        }
    }

    const validateNext = (e) => {
        const errors = [];
        const val_errors = {};
        // Object.entries(fields_in_step).map(([key, value], i) => {
        //     if (props.form[key] === undefined || props.form[key] === '' || props.form[key] === null) {
        //         if (fields_in_step[key].current.childNodes.length > 2) {
        //             fields_in_step[key].current.childNodes[0].classList.remove('error_field');
        //             fields_in_step[key].current.childNodes[0].classList.add('error_field');
        //         } else if (fields_in_step[key].current.childNodes.length === 2) {
        //             fields_in_step[key].current.childNodes[1].classList.remove('error_field');
        //             fields_in_step[key].current.childNodes[1].classList.add('error_field');
        //         }
        //         // console.log(fields_in_step[key]);
        //         errors.push(key);
        //     }
        //     return key;
        // });

        Object.entries(field_validators).map(([key, value], i) => {
            console.log(key);
            console.log(value);
            value.forEach((validator) => {
                if(!validator.validator(props.form[key]) && !(key in val_errors)){
                    val_errors[key] = validator.error;
                    errors.push(validator.error);
                    update_error_state(fields_in_step[key].current);
                }
            })
            return key;
        });

        if (errors.length > 0 && confirm('Please correct the following errors: \n' + errors.join('\n'))) { // eslint-disable-line
            props.update('second_step_done', false);
            return false;
        } else {
            props.update('second_step_done', true);
            props.nextStep();
        }
    }

    const update = (e) => {
        if (fields_in_step[e.target.name].current.childNodes.length > 2) {
            fields_in_step[e.target.name].current.childNodes[0].classList.remove('error_field');
        } else if (fields_in_step[e.target.name].current.childNodes.length === 2) {
            fields_in_step[e.target.name].current.childNodes[1].classList.remove('error_field');
        }
        props.update(e.target.name, e.target.value);
    };

    useEffect(() => {
        // Update the document title using the browser API
        if ((props.form.first_step_done === undefined || !props.form.first_step_done) && window.location.hash === '#SecondStep') {
            window.location.href = '/registration#FirstStep';
        }
    });


    return (
        <div>
            <Container maxWidth="sm">
                <h3 className="display-4 mb-2 font-weight-bold">
                    Finance Details
                </h3>
                <p className="font-size-lg mb-5 text-black-50">
                    Please Fill all fields accurately!
                </p>
                <div className="mb-3">
                    <div className="mb-3">
                        <TextField
                            variant="outlined"
                            name={'vat_id'}
                            label="Company VAT ID"
                            onChange={update}
                            fullWidth
                            ref={fields_in_step.vat_id}
                            placeholder="Enter Company VAT ID"
                            type="text"
                        />
                    </div>
                    <Select
                        variant="outlined"
                        label="Company Size"
                        value={props.form.company_size ? props.form.company_size : 'none'}
                        defaultValue={'none'}
                        name={'company_size'}
                        onChange={update}
                        ref={fields_in_step.company_size}
                        fullWidth
                        placeholder="Company Size"
                    >
                        <MenuItem value={'none'} style={{'display': "none"}}>Select Company Size</MenuItem>
                        <MenuItem value={'private'}>Private ( 1 - 10 employees)</MenuItem>
                        <MenuItem value={'small'}>Small ( 10 - 100 employees)</MenuItem>
                        <MenuItem value={'medium'}>Medium ( 100 - 500 employees)</MenuItem>
                        <MenuItem value={'large'}>Large ( 500 - 1000 employees)</MenuItem>
                        <MenuItem value={'corporate'}>Small ( 1000+ employees)</MenuItem>
                    </Select>
                </div>
                <div className="mb-3">
                    <Select
                        variant="outlined"
                        label="Payment Method"
                        value={props.form.payment_method ? props.form.payment_method : 'none'}
                        defaultValue={'bitcoin'}
                        name={'payment_method'}
                        ref={fields_in_step.payment_method}
                        onChange={update}
                        fullWidth
                        placeholder="Payment Method"
                    >
                        <MenuItem value={'none'} style={{'display': "none"}}>Select Payment Method</MenuItem>
                        <MenuItem value={'bitcoin'}>Bitcoin</MenuItem>
                        <MenuItem value={'wire'}>Wire</MenuItem>
                        <MenuItem value={'paypal'} disabled={true}>PayPal (comming soon)</MenuItem>
                        <MenuItem value={'cc'} disabled={true}>Credit Card (coming soon)</MenuItem>
                        <MenuItem value={'skrill'} disabled={true}>Skrill (coming soon</MenuItem>
                    </Select>
                </div>

                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'name_on_invoice'}
                        label="Company Name On Invoice"
                        ref={fields_in_step.name_on_invoice}
                        onChange={update}
                        fullWidth
                        placeholder="Enter Name On Invoice"
                        type="text"
                    />
                </div>

                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'address_on_invoice'}
                        label="Company Address On Invoice"
                        ref={fields_in_step.address_on_invoice}
                        onChange={update}
                        fullWidth
                        placeholder="Enter Address On Invoice"
                        type="text"
                    />
                </div>
                {/*<div className="form-group pt-2 mb-4">*/}
                {/*    By clicking the <strong>Create account</strong>{' '}*/}
                {/*    button below you agree to our terms of service and*/}
                {/*    privacy statement.*/}
                {/*</div>*/}

                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    size="large"*/}
                {/*    variant="contained"*/}
                {/*    className="mb-5">*/}
                {/*    Create Account*/}
                {/*</Button>*/}
            </Container>
            <Stats step={2} {...props} previousStep={validate} nextStep={validateNext}/>


        </div>
    );
};

const Third = props => {
    const validate = () => {
        if (confirm('Are you sure you want to go back? data from this step will be lost!')) { // eslint-disable-line
            props.previousStep();
        }
    };

    // const area_code = React.createRef();
    const [value, setValue] = React.useState('972');
    // const [phone_value, updatePhoneState] = useState('');
    props.form.area_code = value;
    const [preferred_communication_method_value, setPreferedCommunication] = React.useState('none')
    const communiction_string = useRef();
    const communication_phone = useRef();
    // const phone_ref = useRef();
    const fields_in_step = {
        'first_name': useRef(),
        'last_name': useRef(),
        'preferred_communication_method': useRef(),
        'communication_method_target': useRef(),
        'job_title': useRef(),
    };
    const communication_methods = {
        'none': {'title': 'none', 'description': 'None', 'element': false},
        'chat': {
            'title': 'Chat Nickname',
            'description': 'Enter Chat Nickname',
            'element': communiction_string,
            'type': 'string'
        },
        'email': {
            'title': 'Email Address',
            'description': 'Enter Email Address',
            'element': communiction_string,
            'type': 'string'
        },
        'telegram': {
            'title': 'Telegram User Name',
            'description': 'Enter Telegram Name',
            'element': communiction_string,
            'type': 'string'
        },
        'whatsapp': {
            'title': 'Phone Number',
            'description': 'e.g: +44555555555',
            'element': communication_phone,
            'type': 'string'
        },
        'skype': {
            'title': 'Skype Nickname',
            'description': 'Enter Skype Nickname',
            'element': communiction_string,
            'type': 'string'
        }
    }


    const terms_and_conditions_ref = useRef();

    const handleCommunicationMethodChange = (e) => {
        // props.update('communication_method_target','');
        props.update(e.target.name, e.target.value);
        // props.update('phone_number','');
        // updatePhoneState('');
        setPreferedCommunication(e.target.value);
        // if (communication_methods[e.target.value] !== undefined && communication_methods[e.target.value]['element'] !== false ){
        //     if(communication_methods[e.target.value]['type'] === 'string') {
        //         communication_phone.current.style.display = 'none';
        //         communiction_string.current.style.display = 'block';
        //     } else {
        //         communiction_string.current.style.display = 'none';
        //         communication_phone.current.style.display = 'block';
        //     }
        // }
    }

    // const handleChange = (event) => {
    //     props.update(event.target.name, event.target.value.split('_')[0]);
    //     setValue(event.target.value.split('_')[0]);
    //     props.update('communication_method_target', event.target.value.split('_')[0])
    //     phone_ref.current.value = '';
    // };

    // const updatePhoneValue = (event) => {
    //     props.update(event.target.name, event.target.value);
    //     props.update('communication_method_target', props.form.area_code + event.target.value);
    //     updatePhoneState(event.target.value);
    //     console.log(props.form);
    // }

    const validateNext = (e) => {
        const errors = []
        Object.entries(fields_in_step).map(([key, value], i) => {
            if (props.form[key] === undefined || props.form[key] === '' || props.form[key] === null) {
                if (fields_in_step[key].current.childNodes.length > 2) {
                    fields_in_step[key].current.childNodes[0].classList.remove('error_field');
                    fields_in_step[key].current.childNodes[0].classList.add('error_field');
                } else if (fields_in_step[key].current.childNodes.length === 2) {
                    fields_in_step[key].current.childNodes[1].classList.remove('error_field');
                    fields_in_step[key].current.childNodes[1].classList.add('error_field');
                }
                // console.log(fields_in_step[key]);
                errors.push(key);
            }
            return key;
        });

        if (errors.length > 0 && confirm('Please fill all the fields!')) { // eslint-disable-line
            props.update('third_step_done', false);
            return false;
        } else {
            if (!props.form.agree_terms_and_conditions && confirm('In order to register you must agree to "Terms & Conditions"!')) { // eslint-disable-line
                return false;
            } else {
                props.update('third_step_done', true);
                props.nextStep();
            }
        }
    }

    const changTermsAndConditions = (e) => {
        props.update('agree_terms_and_conditions', e.target.checked);
    }

    const update = (e) => {
        if (fields_in_step[e.target.name].current.childNodes.length > 2) {
            fields_in_step[e.target.name].current.childNodes[0].classList.remove('error_field');
        } else if (fields_in_step[e.target.name].current.childNodes.length === 2) {
            fields_in_step[e.target.name].current.childNodes[1].classList.remove('error_field');
        }
        props.update(e.target.name, e.target.value);
    };


    // const phone_prefix_update = (e) => {
    //     e.preventDefault();
    //     // console.log(e.target.value);
    //     handleChange(e, '44');
    //     // props.update(e.target.name, e.target.value);
    //     // area_code.current.children[0].text = 'abc';
    // }

    useEffect(() => {
        // Update the document title using the browser API
        if ((props.form.first_step_done === undefined || !props.form.first_step_done) && window.location.hash === '#Third') {
            window.location.href = '/registration#FirstStep';
        }
        if ((props.form.second_step_done === undefined || !props.form.second_step_done) && window.location.hash === '#Third') {
            window.location.href = '/registration#SecondStep';
        }
    });

    return (
        <div>
            <Container maxWidth="sm">
                <h3 className="display-4 mb-2 font-weight-bold">
                    Personal Details
                </h3>
                <p className="font-size-lg mb-5 text-black-50">
                    Please Fill all fields accurately!
                </p>
                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'first_name'}
                        onChange={update}
                        label="First Name"
                        placeholder="Enter First Name"
                        ref={fields_in_step.first_name}
                        type="text"
                        style={{'marginRight': '4%', 'width': '48%'}}
                    />
                    <TextField
                        variant="outlined"
                        name={'last_name'}
                        onChange={update}
                        label="Last Name"
                        ref={fields_in_step.last_name}
                        placeholder="Enter Last Name"
                        type="text"
                        style={{'width': '48%'}}
                    />
                </div>

                <div className="mb-3">
                    <Select
                        variant="outlined"
                        label="Preferred Communication Method"
                        value={preferred_communication_method_value}
                        defaultValue={'none'}
                        name={'preferred_communication_method'}
                        onChange={handleCommunicationMethodChange}
                        placeholder="Preferred Communication Method"
                        ref={fields_in_step.preferred_communication_method}
                        fullWidth
                    >
                        <MenuItem value={'none'} style={{'display': "none"}}>Preferred Communication Method</MenuItem>
                        <MenuItem value={'chat'}>Chat</MenuItem>
                        <MenuItem value={'email'}>Email</MenuItem>
                        <MenuItem value={'telegram'}>Telegram</MenuItem>
                        <MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
                        <MenuItem value={'skype'}>Skype</MenuItem>
                    </Select>

                </div>
                <div className="mb-3" ref={communiction_string}>
                    <TextField
                        variant="outlined"
                        name={'communication_method_target'}
                        ref={fields_in_step.communication_method_target}
                        label={communication_methods[preferred_communication_method_value]['title']}
                        onChange={update}
                        fullWidth
                        placeholder={communication_methods[preferred_communication_method_value]['description']}
                        type="text"
                    />
                </div>
                <div className="mb-3">
                    <TextField
                        variant="outlined"
                        name={'job_title'}
                        ref={fields_in_step.job_title}
                        label="Job Title"
                        onChange={update}
                        fullWidth
                        placeholder="Enter Job Title"
                        type="text"
                    />
                </div>

                <div className="mb-3">
                    <Checkbox
                        variant="outlined"
                        name={'terms_and_conditions'}
                        label="Phone Number"
                        onChange={changTermsAndConditions}
                        placeholder="Terms & Conditions"
                        ref={terms_and_conditions_ref}
                        type="checkbox"
                    /> By checking this
                    box you agree to our <Link to={'/terms-and-conditions'} target={'new'} className={'reg_link'}> Terms
                    & Conditions</Link> and <Link to={'/policy-all'} className={'reg_link'} target={'new'}>privacy
                    statement</Link>.
                    Please read carefully the policies before submitting a registration!
                </div>


            </Container>
            <Stats step={3} {...props} previousStep={validate} nextStep={validateNext}/>


        </div>
    );
};

const Progress = (props) => {
    const [captchaState, setCaptchaState] = useState(null);
    const [state, updateState] = useState({
        isActiveClass: '',
        timeout: null,
    });


    const onCaptchaChange = (value) => {
        props.update('capthca_token', value);
        setCaptchaState(value)
    }

    const handleSubmitSucess = (response) => {
        console.log(response);
        props.nextStep();
    }

    const handleSubmitError = (response) => {
        console.log(response)
        window.location.href = '/registration#Third';
    }
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // const urls = remoteRoutes;

    useEffect(() => {
        console.log(props.getFromData());
        if (props.form.third_step_done && props.form.second_step_done && props.form.first_step_done && captchaState !== null) {
            console.log(props.getFromData());

            sendJson(process.env.REACT_APP_REGISTRATION,
                handleSubmitSucess,handleSubmitError, props.getFromData(), headers);

        } else {
            if (props.form.third_step_done && props.form.second_step_done && props.form.first_step_done){
                console.log('wateing for capthca')
            } else {
                window.location.href = '/registration#Third';
            }
            // window.location.href = '/registration#Third';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.third_step_done, props.form.second_step_done, props.form.first_step_done, captchaState]);

    return (
        <Fragment>
            <div className={styles['progress-wrapper']}>
                <p className='text-center'>Submitting Registration...</p>
                <div className={`${styles.progress} ${state.isActiveClass}`}>
                    <div className={`${styles['progress-bar']} progress-bar-striped`}/>
                </div>
            </div>
            <ReCaptchaV2 sitekey="6Lc_jRodAAAAAG1tM3J2gluAoCk9Xq6jrev_1QOL" onChange={onCaptchaChange}/>
        </Fragment>
    );
};

const Last = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
        <div>
            <div className={'text-center'}>
                <h3>Registration Request Submited!</h3>
                <hr/>
                {/*<Plugs />*/}
                <p>
                    Thank you for your registration.<br/>
                    It may take up to 3 business days to validate your account.<br/>
                    You will receive an email with instructions and login credentials after we will validate your
                    registration.

                </p>
            </div>
            <Stats step={4} {...props} nextStep={submit}/>
        </div>
    );
};

import axios from "axios";

const getRemoteJson = (url, success_callback, error_callback, headers={}, ) => {
    // POST request using axios with set headers
    axios.get(url, { headers })
        .then(response => {
                success_callback(response.data);
            }
        )
        .catch(error => {
            error_callback(error);
        });
}
export default getRemoteJson;

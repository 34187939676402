import React, { Fragment } from 'react';
import Collapsible from "react-collapsible";
import "./style.css"
import {Link} from "react-router-dom";
// import { PageTitle } from '../../layout-components';

export default function FAQ() {
    return (
        <Fragment>

            {/*<PageTitle titleHeading="FAQ" titleDescription="Campaign Rules" />*/}
            <div className="content educate_content">
                <section className="section section__article">
                    <div className="breadcrumb" dir="ltr">
                        <div className="link__arrow o__ltr">
                            <a href="/faq">F.A.Q</a>
                        </div>

                        <div className="link__arrow o__ltr">FAQ</div>
                    </div>

                    <div className="paper paper__large">
                        <Collapsible trigger="Is B-tech an advertisement platform and a content publisher?" triggerTagName={'div'}  >
                            <div>
                            <p>
                                No. B-Tech Services is a web based tool to perform technical tasks on traffic and campaign management.<br />
                                All our services are of technical tool offering (campaign management, monitor, alerts etc).<br /><br />
                                We do not publish or advertise any content, but we are providing technical and financial tools (CPC/CPM to CPA) for publishers that enable them to
                                publish content through multiple advertisement networks, but eventually any engagement regarding content or any obligations regarding the content are between
                                the publisher and the advertiser who distribute the content.
                            </p>
                            </div>
                        </Collapsible>
                        <Collapsible trigger="How does CPC/CPM to CPA works?" triggerTagName={'div'}>
                            <p>
                                "CPC/CPM to CPA" model means that as a publisher you can advertise your content through a veriaty of CPC/CPM networks which usually focus on High volume and Low price traffic, rather
                                then expensive micro targeted traffic, but pay eventually ONLY for the leads that converted to purchase. </p><p>
                                This is enabled by the fact that B-Tech tools pay the advertisers on your behalf for the CPC/CPM traffic while you pay B-Tech Services based on CPA model (only for converted leads),
                                according to the agreed rates based on lead geographical origin.
                            <br /><br />
                                To check if you are eligible to use the "CPC/CPM to CPA" model see: <Link to={'/cpc-to-cpa'} className={'reg_link'}>CPA Campaigns Policy & Conditions</Link>
                            </p>
                        </Collapsible>
                        <Collapsible trigger="What payment methods are available?" triggerTagName={'div'}>
                            <p>
                                <strong>Currently the following payment methods are available:</strong><br />
                                &bull; Wire Transfer.<br />
                                &bull; BTC.
                            </p>
                            <p>
                                <strong>Soon coming up:</strong><br />
                                &bull; Paypal.<br />
                                &bull; WebMoney.<br />
                                &bull; Credit Cards.
                            </p>
                            <p>
                                Pre-Payments are currently not possible in the system but can be requested from our support team.<br />
                                Payments are made only Weekly/Monthly bases.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Why do i need  to comply with content policy if B-Tech is not the advertiser?" triggerTagName={'div'}>
                            <p>
                                B-Tech is not an advertiser, and we do not hold responsibility for your content.< br />
                                However, we define our own policy due to the fact that we do not allow our tool to be used for any kind of activity the is deemed wrong by B-Tech.
                                please make sure to read upon: <Link to={'/compliance-all'} className={'reg_link'}>"Compliance & Campaign Quality Guidelines Overview"</Link>
                                before purchase.
                        </p>
                        </Collapsible>
                        <Collapsible trigger="How can i claim a refund?" triggerTagName={'div'}>
                            <p>
                                To claim a refund please contact our support on <Link to={"mailto:support@bandoftech.com"} className={'reg_link'}>support@bandoftech.com</Link>.<br />
                                Refunds may be issues based on legitimate claim with respect to the provided service and tools.
                            </p>
                            <p>
                                IMPORTANT: <br />
                                We do not offer refunds for traffic in CPA models.<br />
                                On CPC/CPM models refund on traffic will be nagotiated with the advertising networks and issued based on its outcome.<br />
                                To clarify B-Tech will not offer ANY refunds on traffic as we are not its seller / buyer but just a brokering service.
                            </p>
                            <p>
                                In event of any refund issued by the advertising networks, we will clean 10% of the full refund sum as processing fee.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="My account was suspended, how can i request a review?" triggerTagName={'div'}>
                            <p>
                                You can request a suspension / ban review by sending your request to<Link to={"mailto:support@bandoftech.com"} className={'reg_link'}>support@bandoftech.com</Link>.<br />

                            </p>
                            <p>
                                upon submitting a review requst please state in the email the following information:<br />
                                <strong>&bull;</strong> Company Name.<br />
                                <strong>&bull;</strong> First and Last names<br />
                                <strong>&bull;</strong> The ticket ID from the email notifying you of the suspension / ban.<br />
                            </p>
                            <p>
                                Please notice that it may take up to 5 business days for our support team to review the issue and get back to you with response regarding next steps.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="What are my CPA rates?" triggerTagName={'div'}>
                            <p>
                                Your CPA rates will be negotiated with B-Tech team, and are derived from the avg CPA cost per lead geographical origin.
                                The rates will be updated and displayed in your control panel after your account approval.
                            </p>
                            <p>
                                Any update to the rates will happen only after your written acknowledgment and update in your control panel.<br />
                                Important notice: CPA rates may change based on performance, if B-Tech request a CPA rate change and you will not respond within 2 business days, your
                                CPA campaigns will be paused till such time as the new rates are acknowledged or the campaign is moved to CPC/CPM type.
                            </p>
                            <p>
                                If you would like to re negotiate CPA rates, you can send your request to: <Link to={"mailto:dao@bandoftech.com"} className={'reg_link'}>dao@bandoftech.com</Link>. <br />
                                Please notice that it may take us up to 5 business days to review the rates change request and come back with an answer.
                            </p>
                        </Collapsible>
                        <Collapsible trigger="Did not find what you were looking for?" triggerTagName={'div'}>
                            <p>
                                If you did not find the information you are looking for, please contact us at: <Link to={"mailto:sales@bandoftech.com"} className={'reg_link'}>sales@bandoftech.com</Link>, and
                                one of our representatives will be in touch with you shortly.
                            </p>
                            <p>
                                To get more information please submit the following information in your email:<br/>
                                <strong>&bull;</strong>First and Last name.<br />
                                <strong>&bull;</strong>Company name.<br />
                                <strong>&bull;</strong>The nature of your inquiry.<br/>
                            </p>
                        </Collapsible>
                        <div className="intercom-reaction-picker" dir="ltr">
                            <div className="intercom-reaction-prompt"></div>

                            <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0"
                                    aria-label="Disappointed Reaction">
                                <span data-emoji="disappointed" title="Disappointed"></span>
                            </button>
                            <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0"
                                    aria-label="Neutral face Reaction">
                                <span data-emoji="neutral_face" title="Neutral face"></span>
                            </button>
                            <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0"
                                    aria-label="Smiley Reaction">
                                <span data-emoji="smiley" title="Smiley"></span>
                            </button>
                        </div>

                    </div>

                </section>
            </div>
        </Fragment>
    );
}

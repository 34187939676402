import React, {Fragment, useState} from 'react';
import RegWizard from "../../../layout-components/regwizard/wizard";
import {Link} from "react-router-dom";


const LivePreviewExample = () => {
  const [value, state] = useState(() => ['Registration', 'Company Details']);

  const update_breadcrumbs = (new_val) => {
    state(new_val);
  }
  return (
      <Fragment>
        <div className="container">
          <div className="content educate_content">
            <section className="section section__article">
              <div className="breadcrumb" dir="ltr" style={{zIndex: 1}}>
                <div className="link__arrow o__ltr">
                  <Link to="/">Home</Link>
                </div>
                {value.map((cell, i) => {
                    if (i < value.length - 1) {
                      return <div className="link__arrow o__ltr" key={'linkd_'+ i}><Link to="/registration" key={'linkv_'+ i}>{cell}</Link></div>;
                    }
                  return <div className="link__arrow o__ltr" key={'linkd_'+ i}>{cell}</div>;
                })}

                {/*{value}*/}
              </div>

              <div className="paper paper__large">
                <div className="content content__narrow">
                  <div className="article intercom-force-break">

                    <RegWizard update_bredcrumbs={update_breadcrumbs} />
                  </div>
                </div>
                <div className="intercom-reaction-picker" dir="ltr">
                  <div className="intercom-reaction-prompt">&nbsp;</div>

                  <button className="intercom-reaction" data-reaction-text="disappointed" tabIndex="0" aria-label="Disappointed Reaction">
                    <span data-emoji="disappointed" title="Disappointed"></span>
                  </button>
                  <button className="intercom-reaction" data-reaction-text="neutral_face" tabIndex="0" aria-label="Neutral face Reaction">
                    <span data-emoji="neutral_face" title="Neutral face"></span>
                  </button>
                  <button className="intercom-reaction" data-reaction-text="smiley" tabIndex="0" aria-label="Smiley Reaction">
                    <span data-emoji="smiley" title="Smiley"></span>
                  </button>
                </div>

              </div>

            </section>
          </div>
        </div>
      </Fragment>
  );
};

export default LivePreviewExample;

import React, {Fragment} from 'react';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './menu.css'
import HomeMenu from "./home";
import ProductsMenu from "./products";
import FAQMenu from "./faq";
import GuideLinesMenu from "./guidelines";
import PolicyMenu from "./policies";
import logo from '../../theme/assets/B-Tech-logos_white.png'
import {Link} from "react-router-dom";

export default function MainMenu() {

    return (
        <Fragment>
            <Link to={'/'}>
                <img src={logo} className={'logo_wrapper'} alt={'logo'} />
            </Link>
            <div className={'menu_wrapper'}>
                <HomeMenu />
                <FAQMenu />
                <ProductsMenu />
                <GuideLinesMenu />
                <PolicyMenu />

            </div>
        </Fragment>

    );
}
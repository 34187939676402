import React, {Fragment} from "react";
import {Link} from "react-router-dom";

export default function TermsAndConditions(){
    return (
      <Fragment>
          <div className="container">
              <div className="content educate_content">
                  <section className="section section__article">
                      <div className="breadcrumb" dir="ltr">
                          <div className="link__arrow o__ltr">
                              <a href="/policy-all">Policies & Contracts</a>
                          </div>

                          <div className="link__arrow o__ltr">Terms & Conditions
                          </div>
                      </div>

                      <div className="paper paper__large">
                          <div className="content content__narrow">
                              <div className="article intercom-force-break">
                                  <div className="article__meta" dir="ltr">
                                      <h1 className="t__h1">Terms and Conditions</h1>
                                      <div className="article__desc">

                                      </div>
                                      <div className="avatar">
                                          <div className="avatar__photo o__ltr">
                                              <span className="avatar__image avatar__fallback"> A </span>

                                          </div>
                                          <div className="avatar__info">
                                              <div>
                                                  Written by <span className='c__darker'> Admin</span>
                                                  <br /> Updated over a week ago
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <article dir="ltr">
                              <header className="entry-header">
                              </header>
                              <div className="entry-content">
                                  <div id="terms">
                                      <p>&nbsp;</p>
                                      <h3>Legal Notice</h3>
                                      <p>Trade name “<strong>B-Tech</strong>” relates to one or any number of Brands represented by several domain names, including its
                                          subsidiaries
                                          and affiliates, within the group of B-Tech
                                          registered under the laws of state of Israel.</p>
                                      <p>B-Tech is a general
                                          purpose advertising network that works with various types of
                                          advertisers and publishers around the world, and offers an ability to launch
                                          ads campaigns on third party networks
                                          by automated means. Advertising campaign management, lead score, and traffic optimization service is carried out through a
                                          personal user
                                          account, and B-Tech only offers a tool for such campaign management.
                                          Therefore,
                                          B-Tech is not an advertising agency, but an online service connecting
                                          advertisers and
                                          websites with ads spaces available (publishers).</p>
                                          <p>Your use of this website is subject to our terms and conditions (including
                                              the Advertiser and
                                              Publisher Agreement) set out herein, and by using this website you are
                                              agreeing, and you are
                                              signifying your agreement, to be bound by these terms and conditions. You
                                              are also
                                              responsible for ensuring that all persons who access our website through
                                              your internet
                                              connection are aware of these terms of use and other applicable terms and
                                              conditions, and
                                              that they comply with them.</p>
                                          <p>The information contained in this website is provided on “as is” basis and
                                              for general
                                              information purposes only. The information is provided by B-Tech
                                              and while we
                                              endeavour to keep the information up to date and correct, we make no
                                              representations or
                                              warranties of any kind, express or implied, about the completeness,
                                              accuracy, reliability,
                                              suitability or availability with respect to the website or the
                                              information, products,
                                              services, or related graphics contained on the website for any purpose.
                                              Any reliance you
                                              place on such information is therefore strictly at your own risk.</p>
                                          <p>Through this website you are able to link to other websites which are not
                                              under the control
                                              of B-Tech. We have no control over the nature, content and
                                              availability of those
                                              sites. The inclusion of any links does not necessarily imply a
                                              recommendation or endorse the
                                              views expressed within them.</p>
                                          <p>Every effort is made to keep the website up and running smoothly. However,
                                              B-Tech
                                              takes no responsibility for, and will not be liable for, the website being
                                              temporarily
                                              unavailable due to technical issues beyond our control.</p>
                                          <p>Before making any decision or taking any action that may affect your
                                              financial position or
                                              status, consult with a qualified professional. None of the entities within
                                              the B-Tech
                                              network is responsible for any loss sustained by any person using this
                                              website or
                                              service.</p>
                                          <p>This website and services provided herein managed by:</p>
                                          <p><em>Company responsible for serving Managed service clients and other
                                              campaign management:</em></p>
                                          <p>B-Tech<br/>
                                              VAT 306469206<br/>
                                          </p>
                                          <p>
                                              <em>Company responsible for Self-service Platform maintenance:</em></p>
                                          <p>B-Tech<br/>
                                              VAT 306469206<br/>
                                          </p>


                                          <h1>Terms and Conditions</h1>
                                          <h4>By using our services, you are agreeing to our Terms and Conditions, so
                                              please read them
                                              carefully.<br/>
                                              <a href="#aterms">Advertiser Agreement</a> | <a href="#pterms">Publisher
                                                  Agreement</a></h4>
                                          <p><a name="aterms"></a></p>
                                          <h3>Advertiser Agreement</h3>
                                          <p><strong><a href="#adv-1">1. Definitions</a><br/>
                                              <a href="#adv-2">2. The Service</a><br/>
                                              <a href="#adv-3">3. Costs incurred, Contents and Positioning</a><br/>
                                              <a href="#adv-4">4. Fees, Payment and Advertiser Account</a><br/>
                                              <a href="#adv-5">5. B-Tech&#8217;s Limited Warranty </a><br/>
                                              <a href="#adv-6">6. Advertiser&#8217;s Representations and
                                                  Warranties</a><br/>
                                              <a href="#adv-7">7. Fraudulent Activity</a><br/>
                                              <a href="#adv-8">8. Indemnification</a><br/>
                                              <a href="#adv-9">9. Rejection of Campaign Content</a><br/>
                                              <a href="#adv-10">10. Non-Solicitation</a><br/>
                                              <a href="#adv-11">11. Confidentiality</a><br/>
                                              <a href="#adv-12">12. Cancellation</a><br/>
                                              <a href="#adv-13">13. Intellectual property</a><br/>
                                              <a href="#adv-14">14. Entire Agreement and Variation</a><br/>
                                              <a href="#adv-15">15. Assignment, Governing Law and Jurisdiction</a><br/>
                                              <a href="#adv-16">16. Limitation of Liability; Disclaimer of
                                                  Warranty</a><br/>
                                              <a href="#adv-17">17. Refund policy</a><br/>
                                              <a href="#adv-18">18. Force Majeure</a><br/>
                                              <a href="#adv-19">19. Miscellaneous</a><br/>
                                              <a href="#adv-20">20. Recurring Transaction</a><br/>
                                          </strong></p>
                                          <p>B-Tech (the, <strong>“B-Tech”,
                                                  “We”</strong>, etc.) being an advertising network that provide
                                              services for products
                                              monetization and promotion, connecting publishers and advertisers through
                                              such service
                                              globally (the, “Service”), and<br/>
                                              You (the, <strong>“Advertiser”, “You”, “Yours”</strong>, etc.), seeking
                                              for an online
                                              service for managing advertising campaigns,
                                              media market analysis,
                                              ads campaign planning, media time buying, lead score and optimization and tools for tracking and monitoring your webpages</p>
                                          <p>WHEREAS,<br/>
                                              B-Tech has offered its services to the Advertiser through
                                              “www.bandoftech.com“ or "www.bandofdomain.com“ 
                                              website (the, “<strong>Program</strong>”), and You decided to utilise the
                                              Service,</p>
                                          <p><br/>
                                              B-Tech and Advertiser hereby agree as follows:</p>
                                          <p>BY CHECKING THE BOX AND CLICKING "I ACCEPT" BUTTON, AS APPLICABLE, OR BY
                                              CONTINUING TO PARTICIPATE IN THE SERVICE FOLLOWING OUR PUBLICATION OF THE
                                              REVISED VERSION OF THIS AGREEMENT ON OUR WEBSITE, YOU (A) CONFIRM THAT YOU
                                              ARE AWARE AND COMPLY WITH B-Tech AGREEMENT AND AGREE TO BE BOUND BY THIS
                                              ADVERTISER AGREEMENT; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE
                                              INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THE PROGRAM
                                              AND ARE NOT RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER
                                              THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT; (C) HEREBY REPRESENT AND
                                              WARRANT THAT YOU ARE AUTHORIZED AND LAWFULLY ABLE TO ENTER INTO THIS
                                              AGREEMENT AND THAT YOU ARE AND WILL REMAIN IN COMPLIANCE WITH THIS
                                              AGREEMENT; AND (D) AGREE TO RECEIVE DIRECTLY TO YOUR E-MAIL NEWSLETTERS,
                                              TRAFFIC MONETIZATION INSIGHTS, LATEST PROMOTIONS, CASE-STUDIES, OPERATIONAL ALERTS, AND
                                              TUTORIALS FROM US. IF YOU SUBSCRIBE TO OUR MONITORING & TRACKING SERVICES YOU AGREE TO RECEIVE
                                              DIRECTLY TO YOUR PHONE SMS / PUSH NOTIFICATIONS FROM B-TECH CONTAINING OPERATIONAL MESSAGES AND ALERTS.
                                          </p>

                                          <p><a name="adv-1"></a></p>
                                          <h3>1. Definitions</h3>

                                          <p><a name="adv-1.1"></a>1.1. “Ad(s) or Advertisement(s)” – means graphical,
                                              interactive, rich media and video, or other online advertisements,
                                              including, without limitation, banners, buttons, towers, skyscrapers,
                                              pop-ups, pop-unders and video advertisements or similar generated by
                                              Advertiser’s web-servers in response to a query from B-Tech.</p>

                                          <p><a name="adv-1.2"></a>1.2. “Advertiser” – means a party that has decided to
                                              enter into this Agreement and to assign B-Tech to provide online
                                              services in accordance with the terms and conditions of this Agreement.
                                          </p>

                                          <p><a name="adv-1.3"></a>1.3. “Advertiser Account” / “Account” – means the
                                              Advertiser’s account at B-Tech web-site “www.bandoftech.com“ or "www.bandofdomain.com“  for
                                              deposit of money and managing of campaigns.</p>

                                          <p><a name="adv-1.4"></a>1.4. “Content” – means all ad content, related
                                              technology and tags provided by Advertiser that are subject to the
                                              Services under this Agreement.</p>

                                          <p><a name="adv-1.5"></a>1.5. “Effective Date” – means the date of adoption by
                                              Advertiser terms of this Agreement or in the absence of its signature, the
                                              date when the Advertiser set up an Advertiser Account with B-Tech.
                                          </p>

                                          <p><a name="adv-1.6"></a>1.6. “B-Tech Network” – means B-Tech’s
                                              digital advertising network available at “www.bandoftech.com“ or "www.bandofdomain.com“ , including
                                              advertisers and publishers.</p>

                                          <p><a name="adv-1.7"></a>1.7. “B-Tech Network Property” – means any
                                              website, application, content, property or any other media owned,
                                              operated, or provided by a company within the B-Tech Network upon
                                              which B-Tech places Ads.</p>

                                          <p><a name="adv-1.8"></a>1.8. “Confidential Information” – will include any
                                              information, whether provided in writing, orally, visually, electronically
                                              or by other means, related to the Services and/or business of a party and
                                              is treated as confidential or secret by the party, including but not
                                              limited to (i) all information marked as “Confidential,” “Proprietary,” or
                                              similar legend by the disclosing party (“Discloser”) when given to the
                                              receiving party (“Recipient”); and (ii) information and data provided by
                                              the Discloser, which under the circumstances surrounding the disclosure
                                              should be reasonably deemed confidential or proprietary.</p>

                                          <p><a name="adv-1.9"></a>1.9. “Campaign” – means certain actions set up by an
                                              Advertiser to increase traffic to the Advertiser or its affiliate website,
                                              sales and/or attracting new customers.</p>

                                          <p><a name="adv-2"></a></p>
                                          <h3>2. The Service</h3>

                                          <p><a name="adv-2.1"></a>2.1. B-Tech provides You an opportunity to
                                              participate in our Service and Program by placing its Ads on web pages of
                                              publishers registered in B-Tech Network. B-Tech will
                                              monitor, track and report its Services in a manner and on a schedule as
                                              determined by B-Tech.</p>

                                          <p><a name="adv-2.2"></a>2.2. In order to become an Advertiser you must first
                                              accurately submit an application for B-Tech account at our website
                                              and be in compliance with present Agreement (in case of using
                                              Self-service) or register yourself as an Advertiser by contacting
                                              B-Tech directly (in case of using Managed service) for acceptance,
                                              and not use any aliases or other means to mask your true identity or
                                              contact information. After we review your application, we will notify of
                                              your acceptance or rejection as B-Tech’ Advertiser. We may accept
                                              or reject your account registration at any time at our sole discretion for
                                              any reason. B-Tech reserves the right to add, edit, remove or
                                              reclaim any account details (including your submissions) with or without
                                              your consent if deemed appropriate at B-Tech sole discretion.</p>

                                          <p><a name="adv-2.3"></a>2.3. By filing your account application or
                                              registering as an Advertiser
                                              you confirm your understanding and unreserved acceptance of present
                                              Agreement and terms and
                                              conditions of B-Tech, including, but not limited to, the&nbsp;
                                              <Link to={'/privacy-policy'}> <strong>Privacy Policy</strong></Link>&nbsp;published at our website concerning the
                                              Services, and confirm
                                              You are a duly authorized signatory, has full legal capacity and all the
                                              necessary authority
                                              to bind the individual, company or other entity, and hereby submitting a
                                              legally binding
                                              electronic signature and entering into a legally binding contract.</p>

                                          <p><a name="adv-2.4"></a>2.4. B-Tech has the following Non Acceptable
                                              Business rules for
                                              Advertisers:</p>
                                          <ul>
                                              <li>Where there are known or perceived links to terrorist organisations,
                                                  military, arms
                                                  and/or ammunition manufacture or sales;
                                              </li>
                                              <li>Where there is knowledge or suspicion of money laundering or terrorist
                                                  financing;
                                              </li>
                                              <li>Where it is known or there are reasonable grounds for suspicion that a
                                                  criminal offence
                                                  has taken place;
                                              </li>
                                              <li>Where the client or any of the clients associated parties are subject
                                                  to any sanctions
                                                  measures;
                                              </li>
                                              <li>Where the client is undertaking an activity or trade within, from or
                                                  into a country
                                                  where that activity is subject to embargo and/or trade control
                                                  restrictions;
                                              </li>
                                              <li>Producers/publishers of racist/pornographic/pressure group material or
                                                  extremist organizations;
                                              </li>
                                              <li>Regulated entities that do not have the appropriate licensing;</li>
                                              <li>Political organisations.</li>
                                          </ul>

                                          <p><a name="adv-2.5"></a>2.5. There are the following methods of using the
                                              Service available –
                                              Self-Service or Managed service.</p>
                                          <p><strong>Self-Service</strong> assumes that all Services and ad campaigns
                                              shall be provided
                                              through Advertisers’ account in Program. B-Tech support team may
                                              provide assistance
                                              upon your request, however, all the actions or modifications made through
                                              your account shall
                                              be deemed made solely by You.</p>
                                          <p><strong>Managed service</strong> assumes that assistance of using the
                                              Services and Program
                                              shall be provided by B-Tech officers.</p>

                                          <p><a name="adv-2.6"></a>2.6. Advertiser understands and accepts that
                                              B-Tech does not allow and prohibits the multiple account opening
                                              for each Advertiser. Advertiser agrees not to fill in an account
                                              application and/or register as an Advertiser more than one time and/or
                                              hold more than one account with B-Tech for any reason and/or in
                                              order to benefit in any way from any marketing promotional program/project
                                              and/or offer available for the Advertiser by B-Tech.</p>

                                          <p>In any case where B-Tech identifies multiple account
                                              applications/registrations/openings/holdings of an Advertiser through the
                                              use of any technology or through other means available for and/or
                                              acceptable by B-Tech only, B-Tech may forbid access to
                                              and/or suspend and/or ban and/or close any such multi accounts and/or the
                                              main account of the Advertiser and/or manage all accounts in such way
                                              and/or take any other actions and measures deemed appropriate in the sole
                                              discretion of B-Tech, regardless of the reason/purpose that such
                                              multi account applications/registrations/openings/holdings were
                                              created.</p>

                                          <p>If the only and/or any account of the Advertiser is forbidden access to
                                              and/or is suspended and/or banned and/or closed for any reason, including
                                              but not limited for reasons related to prohibited/non accepted activity,
                                              the Advertiser understands and agrees that is not allowed and will not
                                              fill in another account application and/or re-register and/or create
                                              and/or hold any other new account for the same reason and/or for any other
                                              prohibited/non accepted activity.</p>

                                          <p><a name="adv-2.7"></a>2.7. B-Tech may allow multi account
                                              applications/registrations/openings/holdings for an Advertiser if this is
                                              specifically and clearly predefined as accepted/permitted in any specific
                                              marketing promotional program/project and/or offer and/or in exceptional
                                              cases, at any time and for any reason B-Tech considers acceptable
                                              and solely at its own discretion, if the Advertiser submits such request
                                              by sending an email to <a
                                                  href="mailto:contact.us@B-Tech.com">contact.us@B-Tech.com</a>.
                                          </p>

                                          <p><a name="adv-2.8"></a>2.8. You may not transfer your account to anyone
                                              without explicit written permission of B-Tech and you may not use
                                              anyone else’s account or password at any time without the express
                                              permission and consent of the holder of that account. B-Tech cannot
                                              and will not be liable for any loss or damage arising from your failure to
                                              comply with these obligations.</p>

                                          <p><a name="adv-2.9"></a>2.9. B-Tech will implement, monitor, track and
                                              report an agreed campaign. B-Tech will furthermore report if and
                                              when errors have occurred in a Campaign in order to rectify such errors so
                                              that such Campaign can be carried out as agreed on. B-Tech will
                                              also, at its sole discretion, provide support and advice on a Campaign
                                              during the term of this Agreement.</p>

                                          <p><a name="adv-2.10"></a>B-Tech does not guarantee: (i) the placement,
                                              positioning or the timing of delivery of any Ad, or (ii) the number (if
                                              any) of any impressions, publications, conversions or clicks on any Ad on
                                              any B-Tech Network Property.</p>

                                          <p><a name="adv-3"></a></p>
                                          <h3>3. Costs incurred, Contents and Positioning</h3>

                                          <p><a name="adv-3.1"></a>3.1. Advertiser shall submit Contents for all Ads
                                              types in accordance with such due date as may be set out in this Agreement
                                              or as otherwise is communicated by B-Tech.</p>

                                          <p><a name="adv-3.2"></a>3.2. Unless otherwise agreed in writing, the
                                              positioning of Ads on a B-Tech Network Property is at B-Tech
                                              sole discretion.</p>

                                          <p><a name="adv-3.3"></a>3.3. If Advertiser asks B-Tech to carry out
                                              the posting or modification of a campaign or any element of the campaign
                                              (including without limitation through an authorization for B-Tech
                                              to optimize campaigns generally), B-Tech will carry out such
                                              posting and/or modification within 48 hours.</p>

                                          <p>Any such posting or modification carried out by B-Tech shall be
                                              deemed approved by Advertiser from the earlier of: (i) confirmation from
                                              Advertiser, and (ii) the end of the 12th hour following the posting or
                                              modification carried out by B-Tech. If Advertiser does not approve
                                              of the posting or modification, it must notify B-Tech via e-mail
                                              within 12 hours of the posting or modification.</p>

                                          <p><a name="adv-3.4"></a>3.4. Advertiser is solely responsible for all: (i)
                                              Contents generated by or for Advertiser; (ii) properties to which a
                                              Content directs users (including without limitation content on the domain
                                              or landing page reached by clicking on the Content URLs; and/or (iv)
                                              Advertiser’s services.</p>

                                          <p><a name="adv-4"></a></p>
                                          <h3>4. Fees, Payment and Advertiser Account</h3>

                                          <p><a name="adv-4.1"></a>4.1. All statistics for the purposes of billing and
                                              general delivery reporting are based on B-Tech’s reporting system.
                                          </p>

                                          <p>Use of the Service shall be carried out on a monthly/weekly basis. For the purpose
                                              of present Agreement, a calendar month shall be deemed as a reporting
                                              period (the, <strong>“Reporting Period”</strong>).</p>

                                          <p>Rates for advertising campaigns are calculated on the basis of its pricing
                                              model (currently CPA Only), number of conversion / sales,
                                              GEOs and other campaign terms.</p>

                                          <p><a name="adv-4.2"></a>4.2. In the event that Advertiser believes that there
                                              is a discrepancy in B-Tech’s reporting system (stats) for Reporting
                                              Period, Advertiser must provide B-Tech with a reasoned report of
                                              such discrepancy within three (3) calendar days from receipt of B-Tech
                                              server reports in relevant Reporting Period. Otherwise, B-Tech
                                              shall not be liable for such discrepancy, services shall be deemed
                                              rendered, and will calculate earnings on basis of its reporting system. If
                                              the parties are unable to reach an agreement regarding the discrepancy,
                                              then B-Tech reporting system shall prevail.</p>

                                          <p><a name="adv-4.3"></a>4.3. B-Tech provides the ability to perform
                                              payments by using payment service providers. Advertiser shall have the
                                              right to select any payment service provider available. You agree that
                                              B-Tech is not responsible for any actions applied by the payment
                                              service provider including but not limited to any additional transaction
                                              fees, banking commissions or currency fees applied to your transaction.
                                              All payments to B-Tech include the above-mentioned fees and
                                              commission, if applicable.</p>

                                          <p>Advertiser is responsible for all applicable taxes associated with provided
                                              ad services, other than taxes based on B-Tech income. Advertiser
                                              shall indemnify B-Tech against all losses suffered or incurred by
                                              B-Tech arising out of or in connection with any payment made to
                                              B-Tech.</p>

                                          <p><a name="adv-4.4"></a>4.4. In case of using Self-Service The minimum
                                              amount of initial deposit is $100 US Dollars. By using Self-Service you
                                              agree that setting limitations on Ads campaigns budget and spending shall
                                              be entirely your responsibility. If the funds in your Advertiser Account
                                              are exhausted, all running campaigns may be suspended immediately, if
                                              spending limits are not applicable. Spending limitations are not legally
                                              binding and B-Tech bears no responsibility for any excess.</p>

                                          <p><a name="adv-4.5"></a>4.5. In case of using Managed services Advertiser
                                              shall set up all spending limitations and budget (fixed or unlimited) with
                                              B-Tech managers to run Ads campaign. So Advertiser has to control
                                              spending of advertising budget and undertakes to inform B-Tech in
                                              written about further actions to optimize such campaign. You shall pay for
                                              Services on the base of the invoices, issued by B-Tech, by one the
                                              following options: (i) prepayment, (ii) net, or (iii) net + 14 days.</p>

                                          <p><a name="adv-4.6"></a>4.6. Advertiser acknowledges and agrees that any
                                              credit card and related billing and payment information that Advertiser
                                              provides to B-Tech may be shared by B-Tech with companies
                                              who work on B-Tech’ behalf such as payment processors and/or credit
                                              agencies solely for the purpose of checking credit and/or effecting
                                              payment to B-Tech and serving customers account.</p>

                                          <p><a name="adv-4.7"></a>4.7. B-Tech shall not be liable for any use or
                                              disclosure of such information by such third Party.</p>

                                          <p><a name="adv-4.8"></a>4.8. Advertiser shall be responsible for any pricing,
                                              Bid, Ad Unit Values, Bidding Terms, Account configuration or category
                                              classifications errors or other errors (“Buyer Errors”) resulting in a
                                              completed transaction (Ad Unit served), and shall be liable for any
                                              payments due in connection with the completed transaction.</p>

                                          <p>Advertiser acknowledges that:</p>
                                          <ul>
                                              <li>all executed transactions are final;</li>
                                              <li>notification of Buyer Errors must be reported by the Advertiser within
                                                  24 hours;
                                              </li>
                                          </ul>

                                          <p><a name="adv-4.9"></a>4.9. B-Tech reserves the right to discontinue
                                              Service, withhold payment at any time and terminate present Agreement
                                              without liability to Advertiser in case of material breach of this
                                              Agreement by the Advertiser or its associates. Parties hereby agree that
                                              any form of fraudulent or illegal activity, or any violation of the
                                              applicable laws and regulations, or any activity specified in Section 9 of
                                              this Agreement shall be deemed a material breach of this Agreement.</p>

                                          <p><a name="adv-4.10"></a>4.10. B-Tech shall have the right to adjust
                                              your account balance in the case of (i) need of payment of bonuses, (ii)
                                              to deduct transaction fees, (iii) due to technical reasons, (iv) due to
                                              fraudulent activity, (v) upon additional agreement by the Parties.</p>

                                          <p><a name="adv-5"></a></p>
                                          <h3>5. B-Tech’s Limited Warranty</h3>

                                          <p><a name="adv-5.1"></a>5.1. Except for the express warranties set forth
                                              above and to the extent permitted by law B-Tech expressly disclaims
                                              all other warranties of any kind with respect to the Service, whether
                                              express or implied, including without limitation any warranties for
                                              merchantability, fitness for a particular purpose, that the Services will
                                              be uninterrupted, completely secure and/or free of software errors.</p>

                                          <p><a name="adv-5.2"></a>5.2. B-Tech shall inform the Advertiser about
                                              any planned maintenance or technical work on its server at least twenty
                                              four (24) hours in advance.</p>

                                          <p><a name="adv-5.3"></a>5.3. B-Tech furthermore expressly disclaims
                                              any responsibility in relation to (i) any claims made in relation to Ads,
                                              campaigns or any Contents or (ii) any claims made in relation to the
                                              publication of any such Ads, campaigns or Contents on any websites such
                                              as, including but not limited to, streaming sites, File Sharing Sites, and
                                              sites with adult content.</p>

                                          <p><a name="adv-6"></a></p>
                                          <h3>6. Advertiser’s Representations and Warranties</h3>

                                          <p><a name="adv-6.1"></a>6.1. Each party will make every effort to uphold the
                                              highest ethical and commercial standards. If B-Tech requests that
                                              Advertisements should be removed from or not placed in any context that
                                              harms the goodwill or reputation of B-Tech, Advertiser will
                                              promptly comply with such request.</p>

                                          <p>In case of violation of its obligations under present Agreement by
                                              Advertiser, B-Tech reserves the right to stop providing services
                                              and withhold Advertisers’ remuneration or account balance or fine.</p>

                                          <p><a name="adv-6.2"></a>6.2 Advertiser accepts and acknowledges the full
                                              responsibility in the event that the Contents in a Campaign would be
                                              deemed invalid or illegal in any applicable jurisdiction.</p>

                                          <p><a name="adv-6.3"></a>6.3 Each Party waives its rights against the other in
                                              respect of warranties and representations (whether written or oral) not
                                              expressly set out or referred to in this Agreement. Nothing in this clause
                                              limits or excludes either Party’s liability for fraud.</p>

                                          <p><a name="adv-6.4"></a>6.4 Hereby you represent and warrant that you have
                                              all necessary rights, permits and licenses to start and manage ad
                                              campaigns and for display Advertisement and operate Your websites and
                                              business activities in the selected jurisdictions. In case of breach of
                                              this obligation, B-Tech may terminate this Agreement at any time
                                              without prior notice, withhold any remuneration or account balance and
                                              claim for compensation of incurred losses and damages.</p>

                                          <p><a name="adv-6.5"></a>6.5 Advertiser undertakes to ensure that its servers
                                              support the traffic directed to ad campaign through our service. Anyway,
                                              B-Tech takes no responsibility for all the consequences in case
                                              your servers cannot support the traffic directed to your website.</p>

                                          <p><a name="adv-6.6"></a> 6.6. You hereby agree not to use B-Tech’
                                              system interface, available to You in connection with the execution of
                                              this Agreement, in any ways not provided for by this Agreement, including
                                              not to distribute or transfer it to any third party.</p>

                                          <p><a name="adv-6.7"></a> 6.7. Hereby You agree not to grant any third parties
                                              the opportunity to place Ads that violate the requirements of the
                                              legislation, as well as ethics and morality rules. You shall bear all the
                                              expenses and losses incurred from Your illegal use of copyrighted
                                              materials (including Ads, trademarks, etc).</p>

                                          <p><a name="adv-6.8"></a> 6.8. You warrant not to use automated tools,
                                              including robots, scripts, or spiders, for generation of the inquiries or
                                              gather information from the interface of the B-Tech Network.</p>

                                          <p><a name="adv-6.9"></a> 6.9. Hereby You warrant that You will not use the
                                              B-Tech Network system interface for any purposes that violate any
                                              applicable laws or rights of any third parties, including its intellectual
                                              property.</p>

                                          <p><a name="adv-6.10"></a> 6.10. You grant NOT to modify, adapt, translate,
                                              disassemble or otherwise attempt to derive the source code of any
                                              software, used in B-Tech Network, Services or Program.</p>

                                          <p><a name="adv-6.11"></a> 6.11. Hereby You represent and warrant to provide
                                              B-Tech with all the documentation or its equivalents, needed for
                                              identification of the parties, ascertainment of the legal fact and
                                              fulfilment of its obligations under this Agreement, within 15 business
                                              days from the date of request. In certain cases, we may withhold all
                                              payments until we will receive relevant documentation from you.</p>

                                          <p><a name="adv-6.12"></a> 6.12. Hereby You irrevocably authorize B-Tech
                                              to transfer a request received by B-Tech to provide information
                                              for the payment directly to Your financial institution available.</p>

                                          <p><a name="adv-7"></a></p>
                                          <h3>7. Fraudulent Activity</h3>

                                          <p>You are expressly prohibited from using any means, devices or arrangements
                                              to commit fraud, violate any applicable law, interfere with other
                                              affiliates or falsify information in connection with the Services or
                                              exceed your permitted access to B-Tech website or Program.</p>

                                          <p>You are prohibited from any practice of disguising (cloaking) an Ads with
                                              different content or landing page and you are forbidden from using any
                                              preference/method resulting to the re-direction of the user to your
                                              landing page when such user has at least once previously chosen through a
                                              certain action to leave your page.</p>

                                          <p>B-Tech shall have the right, in any event described under this
                                              clause 7, to ban Your Advertiser Account, to withhold account balance and
                                              to take all necessary legal actions to restore the damage caused by this
                                              violation. In any case B-Tech shall make all determinations about
                                              fraudulent activity in its sole discretion.</p>

                                          <p><a name="adv-8"></a></p>
                                          <h3>8. Indemnification</h3>

                                          <p>Advertiser agrees to indemnify and hold B-Tech, its affiliates,
                                              subsidiaries, successors and assigns harmless from any and all claims,
                                              actions, judgments or liabilities arising out of or in connection with
                                              Advertiser´s Campaign, any breach of this Agreement by Advertiser and/or
                                              of any representation, warranty or agreement in this Agreement.</p>

                                          <p><a name="adv-9"></a></p>
                                          <h3>9. Rejection of Campaign Content</h3>

                                          <p><a name="adv-9.1"></a>9.1. B-Tech has, in its sole discretion and
                                              without any liability, the right to deny any advertising material or
                                              Content that includes or based on any inappropriate or illegal content
                                              such as, including but not limited to, the following examples:</p>
                                          <ul>
                                              <li>pornography, adult or mature content;</li>
                                              <li>illegal activity (i.e. how to build a bomb, hacking, “phreaking”,
                                                  etc);
                                              </li>
                                              <li>racial, ethnic, political, hate-mongering or otherwise objectionable
                                                  content;
                                              </li>
                                              <li>violence, obscene or vulgar language and abusive content or content
                                                  which endorses or
                                                  threatens physical harm;
                                              </li>
                                              <li>illegal substance;</li>
                                              <li>drugs or any related paraphernalia;</li>
                                              <li>adware, malware, viruses, phishing offers;</li>
                                              <li>creatives should not contain the words like “your software is
                                                  outdated”, “your device is
                                                  infected”, “viruses found” etc. No misleading ads, providing false
                                                  info to the user;
                                              </li>
                                              <li>purchase of weapons/military equipment;</li>
                                              <li>false or deceptive investment advice, and others;</li>
                                              <li>fraud, unofficial, untrue, false, misleading, invented, re-produced
                                                  information, facts, news, offers, solutions, guidelines related to or
                                                  aiming to treat in any way and at any level vulnerabilities of all
                                                  kind, including but not limited to any physical, mental,
                                                  psychological, social, religious, economic, scientific
                                                  vulnerabilities;
                                              </li>
                                          </ul>

                                          <p>If Advertiser provides software for campaign, it shall be free from any
                                              spy- or malicious software and comply with the terms and conditions under
                                              present Agreement. In confirmation of this fact the Advertiser can provide
                                              duly executed SSL-, or Code sign certificate.</p>

                                          <p>Advertiser will defend, indemnify and hold B-Tech or its affiliates
                                              and representatives harmless from any damages, liabilities, costs, and
                                              expenses (incl. attorneys’ fees) resulting from any claim, judgment or
                                              proceeding brought by a third party.</p>

                                          <p>In case where advertisements are placed in such locations, B-Tech
                                              reserves the right to withhold payment for the entire campaign, withhold
                                              account balance and any other remuneration and/or submit an immediate
                                              legal action against Advertiser and/or set a financial penalty, based on
                                              the damages caused to B-Tech.</p>

                                          <p><a name="adv-9.2"></a>9.2. In order to be eligible to become an Advertiser
                                              of software or other application (API), Your software or application (API)
                                              must meet the following criteria:</p>
                                          <ul>
                                              <li>not to generate or facilitate unsolicited bulk commercial email;</li>
                                              <li>not to violate, or encourage the violation of, the legal rights of
                                                  others;
                                              </li>
                                              <li>not to be used in any unlawful, invasive, infringing, defamatory, or
                                                  fraudulent
                                                  purpose;
                                              </li>
                                              <li>not to distribute viruses, worms, Trojan horses, corrupted files,
                                                  hoaxes, or other items
                                                  of a destructive or deceptive nature (i.e. malware);
                                              </li>
                                              <li>it must not to alter, disable, interfere with or circumvent any aspect
                                                  of the software
                                                  of third parties or advertisement services particularly.
                                              </li>
                                          </ul>

                                          <p>Advertiser will make all reasonable efforts to prevent unauthorized use of
                                              its software or application and to terminate any unauthorized use.
                                              Advertiser will promptly notify B-Tech of any unauthorized use of,
                                              or access to, the software or application of which it becomes aware.</p>

                                          <p>Advertising software shall be installed only with the consent of the user,
                                              and shall provide ability of its removal without special additional
                                              programs.</p>

                                          <p><a name="adv-9.3"></a>9.3. Advertiser further acknowledges and accepts that
                                              B-Tech may stop a Campaign in case Advertiser’s website includes
                                              inappropriate content as described under sections 9.1, 9.2 above.</p>

                                          <p><a name="adv-9.4"></a>9.4. In order to ensure compliance with this section
                                              9, Advertiser must notify B-Tech in writing of any changes to the
                                              content on Advertiser’s website which could be deemed inappropriate
                                              content.</p>

                                          <p><a name="adv-10"></a></p>
                                          <h3>10. Non-Solicitation</h3>

                                          <p>Advertiser hereby agrees not to contact websites in the B-Tech
                                              Network in order to purchase advertisement space from them or engage in
                                              practice that would be deemed competitive to the efforts of B-Tech
                                              in its attempts to represent the website’s advertising spaces. Violation
                                              of this clause shall be deemed a material breach of this Contract.</p>

                                          <p><a name="adv-11"></a></p>
                                          <h3>11. Confidentiality</h3>

                                          <p><a name="adv-11.1"></a> 11.1. Each Party (a <strong>“Receiving
                                              Party”</strong>) understands
                                              that the other Party (a <strong>“Disclosing Party”</strong>) may disclose
                                              information of a
                                              confidential nature including, without limitation, product information,
                                              data, pricing,
                                              financial information, software, specifications, research and development
                                              and proprietary
                                              algorithms or other materials that is disclosed in a manner in which the
                                              Disclosing Party
                                              reasonably communicated, or the Receiving Party should reasonably have
                                              understood under the
                                              circumstances that the disclosure should be treated as confidential,
                                              whether or not the
                                              specific designation “confidential” or any similar designation is used
                                              (<strong>“Confidential
                                                  Information”</strong>).</p>

                                          <p><a name="adv-11.2"></a>11.2. The Receiving Party agrees, for itself and its
                                              agents and employees, that it will not publish, disclose or otherwise
                                              divulge or use for its own purposes any Confidential Information of the
                                              Disclosing Party furnished to it by such Disclosing Party without the
                                              prior written approval of the Disclosing Party in each instance. Neither
                                              party will make any public announcement regarding the existence or content
                                              of the Agreement without the other’s prior written approval.</p>

                                          <p><a name="adv-11.3"></a>11.3. The Parties agree that if disclosure is made
                                              to their professional advisors, auditors or bankers this shall be done
                                              subject to each Party procuring each such recipient’s agreement to keep
                                              such information confidential to the same extent as if such recipient were
                                              Party to this agreement.</p>

                                          <p><a name="adv-11.4"></a>11.4. The foregoing obligations under this section
                                              11 shall not extend to any information to the extent that the Receiving
                                              Party can demonstrate that such information (i) was at the time of
                                              disclosure or, to the extent that such information thereafter becomes
                                              through no fault of the Receiving Party, a part of the public domain by
                                              publication or otherwise; (ii) was already properly and lawfully in the
                                              Receiving Party’s possession at the time it was received by the Receiving
                                              Party free from any obligation of confidentiality, (iii) was or is
                                              lawfully received by the Receiving Party from a third Party who was under
                                              no obligation of confidentiality to the Disclosing Party with respect
                                              thereto, or (iv) is independently developed by the Receiving Party or its
                                              independent contractors who did not have access to the Disclosing Party’s
                                              Confidential Information or (v) express written consent has been given
                                              prior to disclosure.</p>

                                          <p><a name="adv-11.5"></a>11.5. In the event that the Receiving Party is
                                              required to disclose Confidential Information in accordance with judicial
                                              or regulatory or governmental order or requirement, or any tax authority
                                              to which that Party is subject or submits, wherever situated, whether or
                                              not the requirement for information has the force of law the Receiving
                                              Party shall promptly notify the Disclosing Party in order to allow such
                                              Party to contest the order or requirement or seek confidential treatment
                                              for such information.</p>

                                          <p><a name="adv-11.6"></a>11.6. Upon termination or expiration of this
                                              Agreement, upon the request of a Disclosing Party, the Receiving Party
                                              agrees to return to the other all of such other Party’s Confidential
                                              Information, or to certify to the Disclosing Party in writing that all
                                              such material has been destroyed, however, destruction is only permitted
                                              after Disclosing Party’s prior approval.</p>

                                          <p><a name="adv-12"></a></p>
                                          <h3>12. Cancellation</h3>

                                          <p><a name="adv-12.1"></a>12.1. Either party may cancel the ads campaign and
                                              terminate present Agreement with 48 hours’ written notice to the other
                                              party.</p>

                                          <p><a name="adv-12.2"></a>12.2. B-Tech shall be entitled, with
                                              immediate effect, to stop Advertiser’s Campaign or to prematurely
                                              terminate this Agreement in writing where: (a) Advertiser uses the Service
                                              or Program in a manner that entails the perpetration of a crime; (b)
                                              Advertiser uses the Service or Program in a manner that occasions losses
                                              or the risk of loss for B-Tech or any third Party; (c) it may be
                                              reasonably assumed that Campaign violates governing law; (d)
                                              notwithstanding reminders, Advertiser fails to pay agreed fees or any
                                              other remuneration to B-Tech within a stated time; (e) Advertiser
                                              otherwise fails to comply with this Agreement and such breach of contract
                                              is material; or (f) Advertiser is placed into insolvent liquidation or is
                                              otherwise insolvent.</p>

                                          <p>In this case, B-Tech shall have the right to block your account
                                              immediately and to withhold the remaining funds at your account as a
                                              fine.</p>

                                          <p><a name="adv-12.3"></a>12.3. This Agreement will be blocked when the
                                              Advertiser’s Account has not been in use for more than three (3) months.
                                          </p>

                                          <p>You will receive a notification informing you that your account is blocked
                                              due to “Inactive account status” at account login. After deactivation, you
                                              will have 30 calendar days to restore your account. To do so, you have to
                                              login to your account and follow the steps described there. If your
                                              account is not reactivated within 30 calendar days it will be deleted
                                              without option to restore it.</p>

                                          <p>If your account balance is 0 EUR/USD, the system will automatically block
                                              your account, if otherwise do not agreed by the parties. If your account
                                              balance is above 0 EUR/USD, the remaining funds will be fully deducted
                                              from your account.</p>

                                          <p><a name="adv-12.4"></a>12.4. You acknowledge and agree that in case of Your
                                              account been deleted at any reason it doesn’t mean that user data would be
                                              erased too.</p>

                                          <p><a name="adv-13"></a></p>
                                          <h3>13. Intellectual property</h3>

                                          <p>Hereby we grant you a non-exclusive, non-transferable, revocable right to
                                              use B-Tech Service and access our Program solely in accordance with
                                              the terms of this Agreement.</p>

                                          <p>You may not alter, modify, manipulate or create derivative works of
                                              B-Tech or any our graphics, creative, copy or other materials owned
                                              by, or licensed to B-Tech in any way. We may revoke your license
                                              anytime by giving you written notice. Except as expressly stated herein,
                                              nothing in this Agreement is intended to grant you any rights to any of
                                              B-Tech’ trademarks, service marks, copyrights, patents or trade
                                              secrets. You agree that we may use any suggestion, comment or
                                              recommendation you choose to provide to B-Tech without
                                              compensation. All rights not expressly granted in this Agreement are
                                              reserved by B-Tech.</p>

                                          <p><a name="adv-14"></a></p>
                                          <h3>14. Entire Agreement and Variation</h3>

                                          <p><a name="adv-14.1"></a>14.1. B-Tech reserves the right to amend the
                                              terms and conditions of this Agreement at any time unilaterally. The
                                              Advertiser shall be informed of such amendments by relevant notice in
                                              personal account or through the information being made available on
                                              B-Tech’s website. The Advertiser shall be deemed to have received
                                              such notice within two (2) weeks of the notice being sent by e-mail or
                                              made available in Advertiser’s personal account on B-Tech’s
                                              website. Where the Advertiser does not accept the amendment, the
                                              Advertiser shall be entitled, within thirty (30) calendar days from the
                                              date of dispatch of the e-mail or, where appropriate, thirty (30) calendar
                                              days from the amendment being published on the website, provided that the
                                              changes have an adverse effect, that could not be considered as minor, on
                                              the Advertiser, to terminate the Agreement with immediate effect. Where
                                              the Agreement is not terminated by the Advertiser within the
                                              aforementioned time, the Advertiser shall be deemed to have accepted the
                                              new terms and conditions.</p>

                                          <p><a name="adv-14.2"></a>14.2. Advertiser acknowledges and agrees that in
                                              entering into this Agreement it has not relied and is not relying on any
                                              representations, warranties or other statements whatsoever, whether
                                              written or oral other than those expressly set out in this Agreement,
                                              Privacy Policy, Agreement on the Storage of the Cardholder’s Credentials
                                              or other terms and conditions published at “www.bandoftech.com“ or "www.bandofdomain.com“  and that
                                              it will not have any right or remedy rising out of any representation,
                                              warranty or other statement not expressly set out in this Agreement.</p>

                                          <p><a name="adv-15"></a></p>
                                          <h3>15. Assignment, Governing Law and Jurisdiction</h3>

                                          <p><a name="adv-15.1"></a>15.1. B-Tech may assign this Agreement to a
                                              subsidiary or business successor. You may not assign this Agreement
                                              without the prior written consent of B-Tech, which shall not be
                                              unreasonably withheld.</p>

                                          <p><a name="adv-15.2"></a>15.2. This Agreement and any dispute or claim
                                              (including
                                              non-contractual disputes or claims) arising out of or in connection with
                                              it or its subject
                                              matter or formation shall be governed by and construed in accordance with
                                              the law
                                              of Israel.</p>

                                          <p><a name="adv-15.3"></a>15.3. Each party irrevocably agrees, for the sole
                                              benefit of B-Tech
                                              that, subject as provided below, the courts
                                              of Israel shall have exclusive jurisdiction over any dispute or claim
                                              (including non-contractual
                                              disputes or claims) arising out of or in connection with this agreement or
                                              its subject
                                              matter or formation. Nothing in this clause shall limit the right of
                                              B-Tech to take
                                              proceedings against Advertiser in any other court of competent
                                              jurisdiction, nor shall the
                                              taking of proceedings in any one or more jurisdictions preclude the taking
                                              of proceedings in
                                              any other jurisdictions, whether concurrently or not, to the extent
                                              permitted by the law of
                                              such other jurisdiction.</p>

                                          <p><a name="adv-16"></a></p>
                                          <h3>16. Limitation of Liability; Disclaimer of Warranty.</h3>

                                          <p>IN NO EVENT SHALL B-TECH BE LIABLE FOR ANY DAMAGES OF ANY KIND
                                              ARISING FROM YOUR USE OF THE SITE, OPERATION OF A PROGRAM, OR YOUR DISPLAY
                                              OF ANY PROGRAM CREATIVE ON YOUR MEDIA, INCLUDING BUT NOT LIMITED TO BROKEN
                                              IMAGES, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES,
                                              EVEN IF B-TECH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                              THE INFORMATION, CONTENT AND SERVICES AT THE PROGRAM OR IN SERVICE ARE
                                              PROVIDED ON AN “AS IS” BASIS WITH NO WARRANTY. YOU USE THE SERVICE AND RUN
                                              PROGRAM AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                                              B-TECH DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND,
                                              EXPRESS OR IMPLIED, WITH RESPECT TO THE OPERATION OF PROGRAM, THE
                                              INFORMATION, SERVICES, AND CONTENT INCLUDED AT THE PROGRAM OR IN SERVICE
                                              AND PROVIDED BY B-TECH, INCLUDING BUT NOT LIMITED TO IMPLIED
                                              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                                              B-TECH DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON ITS
                                              WEBSITE OR PROVIDED BY B-TECH IS ACCURATE, COMPLETE OR CURRENT.</p>

                                          <p><a name="adv-17"></a></p>
                                          <h3>17. Refund Policy</h3>

                                          <p><a name="adv-17.1"></a>17.1. Refund could be applied only upon written
                                              request containing
                                              reasons for your refund to <a
                                                  href="mailto:finance@bandoftech.com">finance@bandoftech.com</a>
                                              in case if Ad campaign cannot be launched due to reasons included but not
                                              limited to
                                              noncompliance of the advertising materials with the requirements of
                                              current legislation,
                                              unacceptable quality and/or content of the creative, other reasons deemed
                                              applicable by
                                              B-Tech’ officer.</p>

                                          <p><a name="adv-17.2"></a>17.2. Refund will be made in the amount of unused
                                              funds. Amount must be calculated based off B-Tech reporting
                                              system.</p>

                                          <p><a name="adv-17.3"></a>17.3. Refund shall be applied only to the actual
                                              payments made by the Advertiser to B-Tech. All funds credited to
                                              the account of the Advertiser within the frame of participation in bonus
                                              programs or similar actions of B-Tech are non-refundable in any
                                              case and subject to the terms and conditions of such programs.</p>

                                          <p><a name="adv-17.4"></a>17.4. A refund request will be considered legitimate
                                              ONLY if it has been sent from the email used for Advertiser’s Account
                                              registration.</p>

                                          <p><a name="adv-17.5"></a>17.5. Advertiser has six (6) months from the last
                                              payment date to ask for a refund of the balance remaining on the
                                              Advertiser Account if You have remained in compliance with this Agreement.
                                              After Advertiser makes a second deposit at Advertiser Account (itself or
                                              via manager), a refund will only be issued for a balance of more than $200
                                              US Dollars and a processing fee of 10% will be deducted from such refund.
                                          </p>

                                          <p><a name="adv-17.6"></a>17.6. The refund will be executed ONLY through a wire transfer regardless
                                              to the original payment method.</p>

                                          <p><a name="adv-17.7"></a>17.7. The refund request will be processed within 5
                                              business days from the date the request was received.</p>

                                          <p><a name="adv-17.8"></a>17.8. Refund is not acceptable in case the
                                              Advertiser breaches terms and conditions of present Agreement or other
                                              terms agreed by the parties.</p>

                                          <p><a name="adv-18"></a></p>
                                          <h3>18. Force Majeure</h3>

                                          <p><a name="adv-18.1"></a>18.1. The force majeure events are understood as
                                              events which occur after the Effective Date, regardless of the will of the
                                              Parties, and which could not be foreseen and prevented by any reasonable
                                              actions of the Parties. The influence of these events may postpone the
                                              performance of all or several parts of present Agreement or other terms
                                              and conditions agreed by the Parties.</p>

                                          <p><a name="adv-18.2"></a>18.2. The circumstances of force majeure include
                                              such events as war, mobilization, epidemic, fire, natural disasters,
                                              traffic accidents and changes in legislation, if such events meet the
                                              criteria of the paragraph 18.1 of this Agreement. The list above is not
                                              exhaustive.</p>

                                          <p><a name="adv-18.3"></a>18.3. If provision of Services been postponed due to
                                              the force majeure, the Party affected by force majeure shall notify the
                                              other Party in writing about the day of the force majeure commencement
                                              within 5 calendar days. With the cessation of force majeure and the
                                              restoration of normal conditions, the Party which was affected by force
                                              majeure shall notify the other Party in writing within 3 calendar days.
                                          </p>

                                          <p><a name="adv-18.4"></a>18.4. If a Party fails to comply with the
                                              requirements specified in the paragraph 18.3., i.e., it will not notify
                                              the other Party of the commencement and termination of the force majeure,
                                              it loses the right to rely on such force major action.</p>

                                          <p><a name="adv-19"></a></p>
                                          <h3>19. Miscellaneous</h3>

                                          <p><a name="adv-19.1"></a>19.1. Present Agreement is the principal document in
                                              legal relationship of the Parties, and shall be deemed an entire agreement
                                              of the Parties. In case of contradictions in using Service or Program,
                                              present Agreement shall prevail in any case.</p>

                                          <p><a name="adv-19.2"></a>19.2. Relationship between the Parties. The
                                              relationship between the Parties will be that of independent contractors
                                              and nothing in this Agreement is intended to nor will establish any
                                              relationship of partnership, joint venture, employment, franchise, agency
                                              or other form of legal association between the Parties. Neither Party will
                                              have, nor represent to any third party that it does have, any power or
                                              authority to bind the other Party or incur any obligations on the other
                                              Party’s behalf.</p>

                                          <p><a name="adv-19.3"></a>19.3. Waiver clause. The failure of a party hereto
                                              at any time or times to require performance of any provision hereof shall
                                              in no manner affect its right at a later time to enforce the same. No
                                              waiver by a party of any condition or of any breach of any term, covenant
                                              or representation contained in this Agreement shall be effective unless in
                                              writing, and no waiver in any one or more instances shall be deemed to be
                                              a further or continuing waiver of any such condition or breach in other
                                              instances or a waiver of any other condition or breach of any other term,
                                              covenant or representation.</p>

                                          <p><a name="adv-19.4"></a>19.4. Survival of Representations and Warranties.
                                              The representations and warranties of Advertiser set forth in this
                                              Agreement (in particular, Article 10-11) hereof shall survive closing for
                                              a period of one (1) year from the termination date (the <strong>“Survival
                                                  Period”</strong>).</p>

                                          <p>No claim for a breach of any representation or warranty by B-Tech
                                              shall be actionable or payable if the breach in question results from or
                                              is based on a condition, state of facts or other matter which was
                                              disclosed to Advertiser and/or actually known by Advertiser prior to
                                              termination.</p>

                                          <p><a name="adv-19.5"></a>19.5. All claims related to the use of the Service
                                              or Program shall be submitted by the Advertiser within 30 days from the
                                              end of the Reporting Period only. In the case of missing the specified
                                              term, B-Tech reserves the right not to process the complaint, and
                                              all the services shall be deemed rendered properly and subject to payment.
                                          </p>

                                          <p><a name="adv-19.6"></a>19.6. Headings. Headings to sections and subsections
                                              in this Agreement are for the convenience of the Parties only and are not
                                              intended to be a part of or affect the meaning or interpretation hereof.
                                          </p>

                                          <p><a name="adv-20"></a></p>
                                          <h3>20. Recurring Transaction</h3>

                                          <p><a name="adv-20.1"></a>20.1. By filling Recurring Transaction Form and
                                              clicking the “I Agree” button you express full consent with these terms
                                              and conditions of recurring transactions and authorize B-Tech and
                                              payment service provider to automatically charge your credit card for
                                              recurring delivery of Services in agreed variable amount and variable
                                              date, stipulated in Recurring Transaction Form in your personal account.
                                          </p>

                                          <p><a name="adv-20.2"></a>20.2. You acknowledge and agree that confirmation
                                              notification of the recurring transaction will be provided within 5
                                              business days via email specified in your personal account.</p>

                                          <p><a name="adv-20.3"></a>20.3. You certify that you are an authorized user of
                                              credit card, details provided in personal account of the Service, and that
                                              you will not dispute the scheduled payments with your credit card company
                                              provided the transactions correspond to the terms indicated in this
                                              Agreement.</p>

                                          <p><a name="adv-20.4"></a>20.4. You agree to pay for all services or other
                                              additional services you ordered through B-Tech Service, as well as
                                              for any additional expenses (if necessary), including, but not limited,
                                              all possible taxes, charges, etc.</p>

                                          <p><a name="adv-20.5"></a>20.5. You take full responsibility for timely
                                              payments for using the Service. Payment service provider only facilitates
                                              a payment for the amount indicated by B-Tech, and it is not
                                              responsible for paying by user of the Service the aforementioned
                                              additional funds/expenses.</p>

                                          <p><a name="adv-20.6"></a>20.6. After clicking the “Pay” button the
                                              transaction is irrevocably deemed to be processed and executed. After
                                              clicking the “Pay” button you agree that you will not be eligible to
                                              cancel the payment or request to cancel it. By placing the order on the
                                              Service, you confirm and state that you do not violate legislation of any
                                              country. Also, by accepting this Agreement, you, as cardholder, confirm
                                              that you are entitled to use Service offered via B-Tech websites.
                                          </p>

                                          <p><a name="adv-20.7"></a>20.7. By agreeing to use the Recurring Transaction
                                              Service, you understand and accept that processing of any of your payments
                                              are executed by the payment service provider, and there is no statutory
                                              right of revocation of already purchased services or any other
                                              opportunities to cancel the payment.</p>

                                          <p><a name="adv-20.8"></a>20.8. You acknowledge that this Recurring
                                              Transaction Service will remain in effect until you cancel it, and you
                                              agree to notify B-Tech of any changes in your personal account
                                              information or cease of this Recurring Transaction Service at least 7 days
                                              prior to the next billing date. If you wish to reject to use Recurring
                                              Transaction services for your next purchases of services or other
                                              facilities on the B-Tech Service, you can do that by using email
                                              notification provided in contact details.</p>

                                          <p><a name="adv-20.9"></a>20.9. When you pay for any of B-Tech
                                              services, you are primarily bound by this Agreement. Please note that only
                                              you, as the cardholder, are responsible for paying for all services you
                                              have ordered through B-Tech Service and for any additional
                                              expenses/fees that can be applied to this payment. Payment service
                                              provider acts only as the executor of the payment in the amount stated by
                                              B-Tech, and it is not responsible for pricing, total prices and/or
                                              total sums.</p>

                                          <p><a name="adv-20.10"></a>20.10. You acknowledge and agree that notification
                                              for following situations will be sent to you, using method of
                                              communication available, at least 7 business days prior: more than six
                                              month have elapsed since the last payment; or there are charges to the
                                              recurring transaction services including, but not limited to any change to
                                              the amount of the recurring transaction and/or any change to the date of
                                              the recurring transaction.</p>

                                          <p><a name="adv-20.11"></a>20.11. In case there is a situation when you do not
                                              agree with the aforementioned terms and conditions of Recurring
                                              Transactions and/or other reasons, we ask you not to proceed with the
                                              payment, and, if necessary, contact directly support of B-Tech
                                              at <a
                                                  href="mailto:support@bandoftech.com">support@bandoftech.com</a>
                                          </p>

                                          <div className="wpb_wrapper"><em>This agreement was last updated on
                                              23.07.2021</em></div>

                                          <p><a name="pterms"></a></p>
                                          <h3>Publisher Agreement</h3>
                                          <p><strong><a href="#pub-1">1. Definitions</a><br/>
                                              <a href="#pub-2">2. The Service</a><br/>
                                              <a href="#pub-3">3. Placement of Ads</a><br/>
                                              <a href="#pub-4">4. Online Reports</a><br/>
                                              <a href="#pub-5">5. Publisher Earnings</a><br/>
                                              <a href="#pub-6">6. Payments</a><br/>
                                              <a href="#pub-7">7. Representations, Warranties and Covenants</a><br/>
                                              <a href="#pub-8">8. Fraudulent Activity</a><br/>
                                              <a href="#pub-9">9. Limitation of Liability; Disclaimer of
                                                  Warranty.</a><br/>
                                              <a href="#pub-10">10. Indemnity</a><br/>
                                              <a href="#pub-11">11. Assignment, Governing Law and Jurisdiction</a><br/>
                                              <a href="#pub-12">12. Severability</a><br/>
                                              <a href="#pub-13">13. Intellectual Property Rights</a><br/>
                                              <a href="#pub-14">14. Termination</a><br/>
                                              <a href="#pub-15">15. Force Majeure</a><br/>
                                              <a href="#pub-16">16. Confidentiality</a><br/>
                                              <a href="#pub-17">17. Self-Billing</a><br/>
                                              <a href="#pub-18">18. Miscellaneous</a><br/>
                                          </strong><br/>
                                              Publisher Agreement (the <strong>“Agreement”</strong>)</p>

                                          <p> B-Tech “<strong>We</strong>”) being an advertising network that provide
                                              services for products
                                              monetization and promotion, connecting publishers and advertisers through
                                              such service
                                              globally (the, “<strong>Service</strong>”), and</p>

                                          <p>You
                                              (the, <strong>“Publisher”</strong>, <strong>“You”</strong>, <strong>“Yours”</strong>,
                                              etc.) being the owner of the website(s) or having sufficient authority to
                                              enter into present
                                              Agreement, that seeks a service to attract advertisers to such website(s)
                                              (the, <strong>“Publisher’s
                                                  web site”</strong>, <strong>“Your
                                                  website”</strong>, <strong>“Site”</strong>, etc.),</p>

                                          <p>WHEREAS,<br/>
                                              B-Tech has offered its service to the Publisher through
                                              “www.bandoftech.com“ or "www.bandofdomain.com“  website
                                              (the, <strong>“Program”</strong>, etc.) and Publisher’s personal account,
                                              and you decided to
                                              utilise the Service,</p>

                                          <p><br/>
                                              B-Tech and Publisher hereby agree as follows:</p>

                                      <p>BY CHECKING THE BOX AND CLICKING "I ACCEPT" BUTTON, AS APPLICABLE, OR BY
                                          CONTINUING TO PARTICIPATE IN THE SERVICE FOLLOWING OUR PUBLICATION OF THE
                                          REVISED VERSION OF THIS AGREEMENT ON OUR WEBSITE, YOU (A) CONFIRM THAT YOU
                                          ARE AWARE AND COMPLY WITH B-Tech AGREEMENT AND AGREE TO BE BOUND BY THIS
                                          ADVERTISER AGREEMENT; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE
                                          INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THE PROGRAM
                                          AND ARE NOT RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER
                                          THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT; (C) HEREBY REPRESENT AND
                                          WARRANT THAT YOU ARE AUTHORIZED AND LAWFULLY ABLE TO ENTER INTO THIS
                                          AGREEMENT AND THAT YOU ARE AND WILL REMAIN IN COMPLIANCE WITH THIS
                                          AGREEMENT; AND (D) AGREE TO RECEIVE DIRECTLY TO YOUR E-MAIL NEWSLETTERS,
                                          TRAFFIC MONETIZATION INSIGHTS, LATEST PROMOTIONS, CASE-STUDIES, OPERATIONAL ALERTS, AND
                                          TUTORIALS FROM US. IF YOU SUBSCRIBE TO OUR MONITORING & TRACKING SERVICES YOU AGREE TO RECEIVE
                                          DIRECTLY TO YOUR PHONE SMS / PUSH NOTIFICATIONS FROM B-TECH CONTAINING OPERATIONAL MESSAGES AND ALERTS.
                                      </p>

                                          <p><a name="pub-1"></a></p>
                                          <h3>1. Definitions</h3>

                                          <p>1.1. “Ad(s) or Advertisement(s)” – means graphical, interactive, rich media
                                              and video, or other online advertisements, including, without limitation,
                                              banners, buttons, towers, skyscrapers, pop-ups, pop-unders and video
                                              advertisements or similar generated by advertiser’s web-servers in
                                              response to a query from B-Tech.</p>

                                          <p>1.2. “Publisher” – means a party that has decided to enter into this
                                              Agreement and to assign B-Tech to provide online services in
                                              accordance with the terms and conditions of this Agreement.</p>

                                          <p>1.3. “Publisher Account” / “Account” – means the Publisher’s account at
                                              B-Tech web-site “www.bandoftech.com“ or "www.bandofdomain.com“ .</p>

                                          <p>1.4. “Content” – means textual, visual, or aural content that is
                                              encountered as part of the Publisher’s websites. It may include documents,
                                              data, applications, e-services, images, audio and video files, personal
                                              web pages, archived e-mail messages, and etc.</p>

                                          <p>1.5. “Effective Date” – means the date of adoption by Publisher terms of
                                              this Agreement or in the absence of its signature, the date when the
                                              Publisher set up a Publisher Account with B-Tech.</p>

                                          <p>1.6. “B-Tech Network” – means B-Tech’s digital advertising
                                              network available at “www.bandoftech.com“ or "www.bandofdomain.com“ , including advertisers and
                                              publishers.</p>

                                          <p>1.7. “B-Tech Network Property” – means any website, application,
                                              content, property or any other media owned, operated, or provided by a
                                              company within the B-Tech Network upon which B-Tech places
                                              Ads.</p>

                                          <p>1.8. “Confidential Information” – will include any information, whether
                                              provided in writing, orally, visually, electronically or by other means,
                                              related to the Services and/or business of a party and is treated as
                                              confidential or secret by the party, including but not limited to (i) all
                                              information marked as “Confidential,” “Proprietary,” or similar legend by
                                              the disclosing party (“Discloser”) when given to the receiving party
                                              (“Recipient”); and (ii) information and data provided by the Discloser,
                                              which under the circumstances surrounding the disclosure should be
                                              reasonably deemed confidential or proprietary.</p>

                                          <p><a name="pub-2"></a></p>
                                          <h3>2. The Service</h3>

                                          <p>2.1. In order to become a Publisher, you must first accurately submit an
                                              application for B-Tech account at our website and be in compliance
                                              with present Agreement (in case of using Self-service) or register as a
                                              Publisher by contacting B-Tech directly (in case you wish to use
                                              dedicated campaign Management service) for acceptance, and not use any
                                              aliases or other means to mask your true identity or contact information.
                                              After we review your application, we will notify of your acceptance or
                                              rejection as B-Tech’ Publisher. We may accept or reject your
                                              account registration at any time at our sole discretion for any reason.
                                              B-Tech reserves the right to add, edit, remove or reclaim any
                                              account details (including your submissions) with or without your consent
                                              if deemed appropriate at B-Tech sole discretion.</p>

                                          <p>2.2. By filing your account application or registering as a Publisher you
                                              confirm your understanding and unreserved acceptance of present Agreement
                                              and other terms and conditions of B-Tech, including, but not
                                              limited to the Privacy Policy, published at our website concerning the
                                              Services, and confirm you are a duly authorized signatory, has full legal
                                              capacity and all the necessary authority to bind the individual, company
                                              or other entity, and hereby submitting a legally binding electronic
                                              signature and entering into a legally binding contract.</p>

                                          <p>2.3. In order to be eligible to become a B-Tech’ Publisher, all
                                              websites must meet the
                                              following criteria:</p>
                                          <ul>
                                              <li>Be content-based, not simply a list of links or advertisements, nor
                                                  can the site be
                                                  centered around making money off of our advertisers;
                                              </li>
                                              <li>Be fully functional at all levels; no “under construction” sites or
                                                  sections; and
                                              </li>
                                              <li>Shall comply with the applicable legislation.</li>
                                          </ul>

                                          <p>2.4. The content of the Publisher’s website(s) or its affiliated website(s)
                                              can not include any material that infringes the rights of any third party
                                              or is in violation of any law, as bound by the law or determined by us in
                                              our sole discretion, including but not limited to the following:</p>

                                          <ul>
                                              <li>Intellectual property rights;</li>
                                              <li>Racial, ethnic, political, hate-mongering or otherwise objectionable
                                                  content;
                                              </li>
                                              <li>Investment, money-making opportunities or advice not permitted under
                                                  law;
                                              </li>
                                              <li>Gratuitous violence or profanity;</li>
                                              <li>Material that defames, abuses, or threatens physical harm to others;
                                              </li>
                                              <li>Promotion of illegal substances or activities (e.g. illegal online
                                                  gambling, “how to
                                                  build a bomb”, counterfeiting money, etc.);
                                              </li>
                                              <li>Software Pirating (e.g., Warez, P2P, Bit torrent, Hotline, etc.);</li>
                                              <li>Hacking or Phreaking;</li>
                                              <li>Fraud, unofficial, untrue, false, misleading, invented, re-produced
                                                  information, facts, news, offers, solutions, guidelines related to or
                                                  aiming to treat in any way and at any level vulnerabilities of all
                                                  kind, including but not limited to any physical, mental,
                                                  psychological, social, religious, economic, scientific
                                                  vulnerabilities;
                                              </li>
                                              <li>Any illegal activity whatsoever;</li>
                                              <li>Any spoofing, redirecting, or trafficking from adult-related websites
                                                  in an effort to
                                                  gain traffic; or
                                              </li>
                                              <li>Any other inappropriate activity as determined by us in our sole
                                                  discretion.
                                              </li>
                                          </ul>

                                          <p>2.5. B-Tech has the following Non Acceptable Business rules for
                                              Publishers:</p>

                                          <ul>
                                              <li>Where there are known or perceived links to terrorist organisations,
                                                  military, arms
                                                  and/or ammunition manufacture or sales;
                                              </li>
                                              <li>Where there is knowledge or suspicion of money laundering or terrorist
                                                  financing;
                                              </li>
                                              <li>Where it is known or there are reasonable grounds for suspicion that a
                                                  criminal offence
                                                  has taken place;
                                              </li>
                                              <li>Where the client or any of the clients associated parties are subject
                                                  to any sanctions
                                                  measures;
                                              </li>
                                              <li>Where the client is undertaking an activity or trade within, from or
                                                  into a country
                                                  where that activity is subject to embargo and/or trade control
                                                  restrictions;
                                              </li>
                                              <li>Producers/publishers of racist/pornographic/pressure group material or
                                                  extreme political
                                                  propaganda;
                                              </li>
                                              <li>Regulated entities that do not have the appropriate licensing;</li>
                                              <li>Extreme political and/or charitable organisations.</li>
                                          </ul>

                                          <p>2.6. Publisher understands and accepts that B-Tech does not allow
                                              and prohibits the multiple account opening for each Publisher. Publisher
                                              agrees not to fill in an account application and/or register as a
                                              Publisher more than one time and/or hold more than one account with
                                              B-Tech for any reason and/or in order to benefit in any way from
                                              any marketing promotional program/project and/or offer available for the
                                              Publisher by B-Tech.</p>

                                          <p>2.7. B-Tech may allow multi account
                                              applications/registrations/openings/holdings for a Publisher if this is
                                              specifically and clearly predefined as accepted/permitted in any specific
                                              marketing promotional program/project and/or offer and/or in exceptional
                                              cases, at any time and for any reason B-Tech considers acceptable
                                              and solely at its own discretion, if the Publisher submits such request by
                                              sending an email to <a
                                                  href="mailto:support@bandoftech.com">support@bandoftech.com</a>.
                                          </p>

                                          <p>2.8. There are the following methods of using the Service available –
                                              Self-Service or
                                              Management service.</p>

                                          <p>Self-Service assumes that access to the Service shall be provided through
                                              Publishers’
                                              personal account. B-Tech support team may provide assistance upon
                                              your request,
                                              however, all the actions or modifications made through your account shall
                                              be deemed made
                                              solely by you.</p>

                                          <p>Management service assumes that assistance of using the Services and
                                              Program shall be
                                              provided by B-Tech officers.</p>

                                          <p>2.9. You may not transfer your account to anyone without explicit written
                                              permission of B-Tech and you may not use anyone else’s account or
                                              password at any time without the express permission and consent of the
                                              holder of that account. B-Tech cannot and will not be liable for
                                              any loss or damage arising from your failure to comply with these
                                              obligations.</p>

                                          <p><a name="pub-3"></a></p>
                                          <h3>3. Placement of Ads</h3>

                                          <p>3.1. Publisher shall NOT place any advertisements of B-Tech network
                                              advertisers attracted through the Service on alternative publishers or
                                              websites without written consent and approval of B-Tech. Publisher
                                              will not place advertisement on pornographic/offensive, and/or warez,
                                              and/or illegal MP3 sites/directories, and/or P2P/Bit-Torrent sites, and/or
                                              Spyware or malicious code of any sort and/or alternatively questionable
                                              areas. In the case whereby advertisements are placed in such
                                              sites/directories, B-Tech reserves the right to withhold payment
                                              for the entire campaign and/or submit an immediate legal action against
                                              the Publisher and/or set a monetary fine in the amount based on the
                                              damages caused to B-Tech.</p>

                                          <p>3.2. B-Tech do not check or control the activities or contents at
                                              your website, but all the services may be rejected and we reserve the
                                              right to delete your account, withhold and freeze all fees and
                                              remunerations if you engage in fraudulent or illegal activity.</p>

                                          <p><a name="pub-4"></a></p>
                                          <h3>4. Online Reports</h3>

                                          <p>4.1. Use of the Service shall be carried out on a monthly/weekly basis. For the
                                              purpose of present Agreement, a calendar month shall be deemed as a
                                              reporting period (“Reporting Period”).</p>

                                          <p>4.2. During the month/week Publisher may track online reports within B-Tech
                                              reporting system in Publishers’ personal account, which are only
                                              estimated numbers subject to being adjusted within 5 days after the end
                                              of the Reporting Period. In all cases, we will use commercially reasonable
                                              methods and practices to direct and measure traffic. Campaigns may be
                                              adjusted at any time by B-Tech team to comply with advertiser´s ad
                                              serving stats. At the end of the Reporting Period the reports are frozen
                                              and within 5 days will include the definitive numbers of earnings. For
                                              avoidance of doubt, B-Tech reporting system (stats) will be
                                              prevailing in any case.</p>

                                          <p><a name="pub-5"></a></p>
                                          <h3>5. Publisher Earnings</h3>

                                          <p>5.1. Cost of using Service depends on the amount and scope of advertising
                                              campaigns carried out on Publishers websites during the reporting period
                                              based on ads placements generated by B-Tech reporting system
                                              (stats), available in your personal account. All reported statistics for
                                              the purposes of billing and general delivery reporting are based on
                                              B-Tech reporting system only.</p>

                                          <p>5.2. In the event that Publisher believes that there is a discrepancy in
                                              B-Tech’s reporting system, Publisher must provide B-Tech
                                              with a reasoned report of such discrepancy within three (3) calendar days
                                              from receipt of B-Tech’s reports. Otherwise, B-Tech shall
                                              not be liable for such discrepancy, and will calculate earnings on basis
                                              of its reporting system. If the parties are unable to reach an agreement
                                              regarding the discrepancy, then B-Tech stats and reports shall
                                              prevail.</p>

                                          <p>5.3. B-Tech is entitled to make adjustments in Publisher’s account
                                              in one of the
                                              following cases:</p>

                                          <ul>
                                              <li>To pay promotions and bonuses</li>
                                              <li>Due to technical reasons</li>
                                              <li>Due to Publisher’s fraudulent activity</li>
                                              <li>On the basis of additional agreements with You</li>
                                              <li>Due to Advertiser’s complaints or refunds</li>
                                          </ul>

                                          <p><a name="pub-6"></a></p>
                                          <h3>6. Payments</h3>

                                          <p>6.1. B-Tech offers its Publishers a wide range of payment methods in
                                              order to provide convenient conditions for mutually beneficial
                                              cooperation.<br />
                                                  B-Tech has the following payment terms:</p>
                                              <ul>
                                                  <li>Self-Service billing type:</li>
                                                  <ul>
                                                      <li>for newly registered Publishers the very first payout may be
                                                          proceeded not earlier 14 calendar days from the date the first
                                                          campaign starts;
                                                      </li>
                                                      <li>the second and the following payouts to be proceeded either on
                                                          a weekly basis (every Thursday) with a Hold 4 days (e.g. if
                                                          payment request has been filed through your personal account
                                                          on Thursday, payment day will be Monday next week), or at Net
                                                          35 days basis. Payment terms variations available in Your
                                                          personal account.
                                                      </li>
                                                      <li>the third option is prepayment funds. This option is available for CPC/CPM based models only.<br />
                                                          The Publisher agree to issue a refund upon a legitimate refund request with 5 business days, faileing to
                                                          comply with this clause without written agreement from B-Tech will may result in account immediate account
                                                          suspension/termination and will be considered a material breach of this agreement/contract.
                                                      </li>
                                                  </ul>
                                                  <li>Managed Service for publishers are currently not available. payment conditions for managed services will
                                                      be published by B-Tech once the service will becaome active
                                                  </li>
                                              </ul>

                                          <p>6.2. B-Tech acts as a third party for advertisers, therefore
                                              Publisher understands and agrees that payment for Publisher’s revenue is
                                              dependent upon payments from advertisers to B-Tech that it has
                                              received without any restrictions. You hereby release B-Tech from
                                              any claim for Publisher’s revenue if B-Tech did not receive funds
                                              from the advertiser. Publisher shall hold B-Tech harmless and
                                              indemnify it from any claims or liability related to such unpaid
                                              revenue.</p>

                                          <p>6.3. B-Tech provides the ability to perform payments by using
                                              payment service providers. Publisher shall have the right to select any
                                              payment service provider available. You agree that B-Tech is not
                                              responsible for any actions made by the payment service provider including
                                              but not limited to any additional transaction fees, banking commissions or
                                              currency fees applied to your transaction. All payments shall include the
                                              above-mentioned fees and commission, if applicable.</p>

                                          <p>6.4. Publisher is responsible for all applicable taxes associated with
                                              provided Services, other than taxes based on B-Tech income.
                                              Publisher shall indemnify B-Tech against all losses suffered or
                                              incurred by the B-Tech arising out of or in connection with any payment
                                              made to the Publisher.</p>

                                          <p>6.5. Publisher responsible to supply valid payment details in personal
                                              account of our Service, if details are wrong or if the Publisher change
                                              its payment details, it is the Publisher’s responsibility to notify by
                                              mail 14 days before payment due date (or minimal possible amount of time, or immediately
                                              in case of a prepayment model). Publisher will bear payments fees if
                                              required. In any event, all payments will be made at the payment details
                                              specified in your personal account in our Service.</p>

                                          <p>6.6. All payments are processed automatically. We may, in our sole
                                              discretion, refuse to process a payment (and may place a payment hold) on
                                              any part of your account for any reason, block your account and terminate
                                              this Agreement, including if we have a reasonable suspicion that you have
                                              breached any clause of this Agreement. We also reserve the right to
                                              set-off any amount you owe us, including for breaches of this Agreement.
                                              We assume no responsibility for paying any taxes on payments made to you,
                                              and you acknowledge and agree that it is your complete and sole
                                              responsibility to pay for all taxes as a consequence of your participation
                                              in the Program.</p>

                                          <p>6.7. Hereby you represent and warrant to provide B-Tech with all the
                                              documentation or its equivalents, needed for identification of the
                                              parties, ascertainment of the legal fact and fulfillment of its
                                              obligations under this Agreement, within 15 business days from the date of
                                              request. In certain cases, we may withhold all payments until we will
                                              receive relevant documentation from you.</p>

                                          <p>6.8. You on your own shall ensure the ability to receive payments from
                                              B-Tech to specified bank account or at relevant payment provider.
                                              If the receipt of remuneration or other payment is delayed or failed
                                              because of your non-compliance with this clause 6 (including if the
                                              failure or delay is caused by a third party payment service provider you
                                              are using), B-Tech shall not be responsible for violation of terms
                                              of payment.</p>

                                          <p>6.9. If you believe that any fault in transaction has taken place, you
                                              agree to notify us immediately, and We will make all possible efforts to
                                              eliminate delays or errors in payment processing. Unless your claim been
                                              submitted within 30 days after the charge, you will have waived, to the
                                              fullest extent permitted by law, all claims against B-Tech related
                                              to the transaction. If you experience a technical failure or interruption
                                              of services that causes your funding transaction to fail, you may request
                                              that your transaction be completed at a later time.</p>

                                          <p>6.10. By entering into this Agreement, you agree to receive Publisher’s
                                              revenue as from B-Tech, or from its affiliates, subsidiaries,
                                              agents, sub-contractors or distributors.</p>

                                          <p><a name="pub-7"></a></p>
                                          <h3>7. Representations, Warranties and Covenants</h3>
                                          <ul>
                                              <li>You represent, warrant and covenant that: your website is in
                                                  compliance with all applicable laws and terms and conditions of
                                                  present Agreement, and does not contain or promote, nor links to
                                                  another website that contains, libelous, defamatory, abusive, violent,
                                                  prejudicial, obscene, infringing, sexually explicit or illegal
                                                  content, including copyright ownership infringements and unlawful use
                                                  of intellectual property;
                                              </li>
                                              <li>You agree not to promote via website or link to websites containing
                                                  any pornographic, racial, ethnic, political, software pirating (e.g.
                                                  Warez) or hacking, hate-mongering, or otherwise objectionable content;
                                              </li>
                                              <li>You agree not to engage in any illegal activity, in accordance with
                                                  applicable law, whatsoever, is not allowed;
                                              </li>
                                              <li>You represent and warrant that you own or have legal rights to use and
                                                  distribute all content, copyrighted material, trademarked materials,
                                                  products, and services displayed on your website; you agree not to use
                                                  deceit when marketing advertiser’s offers or presenting these offers
                                                  to consumers; you have the right, power, and authority to enter into
                                                  this Agreement and grant the rights specified herein;
                                              </li>
                                              <li>You will not attempt in any way to alter, modify, eliminate, conceal,
                                                  or otherwise render inoperable or ineffective the website tags, source
                                                  codes, links, pixels, modules or other data provided by or obtained
                                                  from B-Tech that allows B-Tech to measure ad performance
                                                  and provide its service (“<strong>Site Data</strong>”);
                                              </li>
                                              <li>If instructed to do so by B-Tech and/or if this Agreement
                                                  terminates, you will immediately remove and discontinue the use of any
                                                  Site Data;
                                              </li>
                                              <li>You acknowledge that B-Tech does not represent, warrant, or
                                                  make any specific or implied promises as to the successful use of
                                                  Service;
                                              </li>
                                              <li>You agree to display the creative exactly as it appears on the Program
                                                  and will not alter any creative that has been submitted to the Site;
                                              </li>
                                              <li>You agree to display the creative exactly as it appears on the Service
                                                  and will not alter any creative that has been placed through the
                                                  Service;
                                              </li>
                                              <li>If you are notified that fraudulent activities may be occurring on
                                                  your website, and you do not take any actions to stop the fraudulent
                                                  activities, then you are responsible for all associated costs and
                                                  legal fees resulting in these fraudulent activities;
                                              </li>
                                              <li>You represent, warrant and covenant that you will not take any action
                                                  that imposes, or may impose, in our sole discretion, an unreasonable
                                                  or disproportionately large loan on our technology infrastructure or
                                                  otherwise make excessive demands on it;
                                              </li>
                                              <li>You may not disable, circumvent or otherwise interfere with security
                                                  related features of our Service or features that prevent or restrict
                                                  use or copying of any part of our Service, or which enforce
                                                  limitations on the use of our Service;
                                              </li>
                                              <li>Hereby You irrevocably authorize B-Tech to transfer a request
                                                  received by B-Tech to provide information for the payment
                                                  directly to your financial institution available;
                                              </li>
                                              <li>You represent, warrant and covenant that your website does not contain
                                                  any sexual or erotic material that depicts persons under the age of
                                                  eighteen (18) or in a manner that suggests that they are under the age
                                                  of eighteen (18);
                                              </li>
                                              <li>If any errors or undesirable results occur due to no fault of
                                                  B-Tech, B-Tech shall not be responsible for losses and
                                                  you may not be compensated;
                                              </li>
                                              <li>Publisher undertakes to ensure that its servers support the traffic
                                                  directed to ad campaign through our Service. B-Tech takes no
                                                  responsibility for all the consequences in the event your servers
                                                  cannot support the traffic directed to your website. You shall test
                                                  your website to insure its correct appearance in different web
                                                  browsers, devices or systems and optimize it if necessary.
                                              </li>
                                              <li>You acknowledge that every case of violation of the terms of this
                                                  Agreement will lead to material and business standing losses of
                                                  B-Tech in the amount of at least US $ 1,000. Therefore, we
                                                  reserve the right to recover damages caused in the specified amount,
                                                  or the amount of actually incurred losses, in the event of your breach
                                                  of contract. Such losses may be deducted from the balance of your
                                                  personal account in the Service.
                                              </li>
                                          </ul>

                                          <p><a name="pub-8"></a></p>
                                          <h3>8. Fraudulent Activity</h3>

                                          <p>8.1. YOU MAY NOT CHEAT, DEFRAUD OR MISLEAD US, OR ATTEMPT TO CHEAT, DEFRAUD
                                              OR MISLEAD US, IN ANY MANNER.</p>

                                          <p>You are expressly prohibited from using any means, devices or arrangements
                                              to commit fraud, violate any applicable law, interfere with other
                                              affiliates or falsify information in connection with the Services or
                                              generating of remuneration or exceed your permitted access to B-Tech
                                              Ads Service. You are forbidden from using any preference/method resulting
                                              to the re-direction of the user to your landing page when such user has at
                                              least once previously chosen through a certain action to leave your page.
                                              These prohibited activities include but not limited to: framing an
                                              ad-banner’s click-through destination, invisible iframe, auto-spawning of
                                              browsers, running “spiders”/”bots”, and automatic redirecting of users or
                                              any other technique of generating automatic or fraudulent click-through
                                              and/or impressions. Ads may not be placed on an automatically reloaded
                                              page. In any case B-Tech shall make all determinations about
                                              fraudulent activity in its sole discretion.</p>

                                          <p>8.2. If Publisher is suspected in any fraudulent activity B-Tech
                                              shall have the right to ban Your Publisher Account, to withhold account
                                              balance and to take all necessary legal actions to restore the damage
                                              caused by this violation. All advertising campaigns carried out on
                                              Publishers websites with fraudulent activities are not subject for
                                              payment.</p>

                                          <p><a name="pub-9"></a></p>
                                          <h3>9. Limitation of Liability; Disclaimer of Warranty</h3>

                                          <p>IN NO EVENT SHALL B-TECH BE LIABLE FOR ANY DAMAGES OF ANY KIND
                                              ARISING FROM YOUR USE OF THE SERVICE, OPERATION OF A PROGRAM, OR YOUR
                                              DISPLAY OF ANY PROGRAM CREATIVE ON YOUR WEBSITE, INCLUDING BUT NOT LIMITED
                                              TO BROKEN IMAGES, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE AND
                                              CONSEQUENTIAL DAMAGES, EVEN IF B-TECH HAS BEEN ADVISED OF THE
                                              POSSIBILITY OF SUCH DAMAGES. B-TECH IS ONLY THE TOOL FOR ADS
                                              CAMPAIGNS CONNECTING ADVERTISERS AND PUBLISHERS THROUGH ITS SERVICE. THE
                                              INFORMATION, CONTENT AND OTHER B-TECH SERVICES ARE PROVIDED ON AN
                                              “AS IS” BASIS WITH NO WARRANTY. YOU USE THE SERVICE AND RUN PROGRAMS AT
                                              YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, B-TECH
                                              DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS OR
                                              IMPLIED, WITH RESPECT TO THE OPERATION OF THE SERVICE, THE INFORMATION,
                                              AND CONTENT INCLUDED ON THE SERVICE AND PROVIDED BY B-TECH,
                                              INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND
                                              FITNESS FOR A PARTICULAR PURPOSE. B-TECH DOES NOT REPRESENT OR
                                              WARRANT THAT THE INFORMATION ON THIS SERVICE OR WEBSITE OR PROVIDED BY
                                              B-TECH IS ACCURATE, COMPLETE OR CURRENT.</p>

                                          <p><a name="pub-10"></a></p>
                                          <h3>10. Indemnity</h3>

                                          <p>You shall indemnify, defend and hold B-Tech harmless from and
                                              against any and all claims, allegations, liabilities, costs and expenses
                                              (including reasonable attorneys´ fees) which may be incurred by or to the
                                              third parties arising out of your: (a) improper use of the Service; (b)
                                              improper operation of a Program; or (c) breach or violation of any clause
                                              of this Agreement or other mutual agreement of its parties.</p>

                                          <p><a name="pub-11"></a></p>
                                          <h3>11. Assignment, Governing Law and Jurisdiction</h3>

                                          <p>11.1. B-Tech may assign this Agreement to a subsidiary or business
                                              successor. You may not assign this Agreement without the prior written
                                              consent oThis Agreement and any dispute or claim (including
                                              non-contractual disputes or claims) arising out of or in connection with
                                              it or its subject matter or formation shall be governed by and construed
                                              in accordance with the law of Israel.</p>
                                          <p>11.3. Each party irrevocably agrees, for the sole benefit of B-Tech
                                              that, subject as
                                              provided below, the courts of Israel shall have exclusive jurisdiction
                                              over any dispute or claim (including non-contractual
                                              disputes or claims) arising out of or in connection with this Agreement or
                                              its subject
                                              matter or formation. Nothing in this clause shall limit the right of
                                              B-Tech to take
                                              proceedings against Publisher in any other court of competent
                                              jurisdiction, nor shall the
                                              taking of proceedings in any one or more jurisdictions preclude the taking
                                              of proceedings in
                                              any other jurisdictions, whether concurrently or not, to the extent
                                              permitted by the law of
                                              such other jurisdiction.</p>

                                          <p><a name="pub-12"></a></p>
                                          <h3>12. Severability</h3>

                                          <p>If any provision of this Agreement is held to be invalid, illegal or
                                              unenforceable for any reason, such invalidity, illegality or
                                              unenforceability shall not affect any other provisions of this Agreement,
                                              and this Agreement shall be construed as if such invalid, illegal or
                                              unenforceable provision had not been contained herein.</p>

                                          <p><a name="pub-13"></a></p>
                                          <h3>13. Intellectual Property Rights</h3>

                                          <p>13.1. Hereby we grant you a non-exclusive, non-transferable, revocable
                                              right to use B-Tech Service and to access our website through our
                                              Service only in accordance with the terms and conditions of this
                                              Agreement.</p>

                                          <p>13.2. You may not alter, modify, manipulate or create derivative works of
                                              B-Tech or any our graphics, creative, copy or other materials owned
                                              by, or licensed to B-Tech in any way. We may revoke your license
                                              anytime by giving you written notice. Except as expressly stated herein,
                                              nothing in this Agreement is intended to grant you any rights to any of
                                              B-Tech’ trademarks, service marks, copyrights, patents or trade
                                              secrets. You agree that we may use any suggestion, comment or
                                              recommendation you choose to provide to B-Tech without
                                              compensation. All rights not expressly granted in this Agreement are
                                              reserved by B-Tech.</p>

                                          <p>13.3. Your use of the Service shall be governed by and subject to the laws
                                              and regulations regarding copyright ownership and terms of use of
                                              intellectual property. You represent, warrant and covenant that you do not
                                              upload, download, display, perform, transmit, or otherwise distribute any
                                              object in violation of any third party’s copyrights, trademarks, or other
                                              intellectual property rights. You represent, warrant and covenant that you
                                              abide by the laws regarding copyright ownership and use of intellectual
                                              property and you shall be solely responsible for any violations of any
                                              relevant laws and for any infringements of third party rights caused by
                                              you.</p>

                                          <p>13.4. ALL THE PARTIES HEREBY AGREE THAT B-Tech DOES NOT HAVE ANY
                                              AUTHORITY OR ABILITY TO CONTROL CONTENT AT PUBLISHER’S WEBSITE(S) AND FOR
                                              THIS REASON, WE CANNOT BEAR ANY RESPONSIBILITY REGARDING BREACHING OF ANY
                                              THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS.</p>

                                          <p><a name="pub-14"></a></p>
                                          <h3>14. Termination</h3>

                                          <p>14.1. This Agreement shall commence upon your acceptance and remain in
                                              effect until terminated. This Agreement may be terminated by either Party
                                              upon one (1) business days´ notice. This Agreement shall terminate
                                              immediately upon the dissolution or insolvency of either Party, or the
                                              breach of this Agreement by you. B-Tech reserves the right, in its
                                              sole and absolute discretion, to terminate a campaign and remove any
                                              advertisements at any time for any reason.</p>

                                          <p>14.2. This Agreement will be blocked when the Publisher’s Account has not
                                              been in use for
                                              more than three (2) months.</p>

                                          <p>You will receive a notification informing you that your account is blocked
                                              because of “Inactive account status” at account login. After deactivation,
                                              you will have 30 calendar days to restore your account. To do so, you have
                                              to login to your account and follow the steps described there. If your
                                              account is not reactivated within 30 calendar days it will be deleted
                                              without option to restore it.</p>

                                          <p>If your account balance is 0 EUR/USD, the system will automatically block
                                              your account, if otherwise do not agreed by the parties. If your account
                                              balance is above 0 EUR/USD, the remaining funds will be fully deducted
                                              from your account.</p>

                                          <p><a name="pub-15"></a></p>
                                          <h3>15. Force Majeure</h3>

                                          <p>Neither Party shall be liable to the other by reason of failure or delay in
                                              the performance of its obligations hereunder on account of Acts of God,
                                              fires, storms, war, governmental action, labor conditions, earthquakes,
                                              natural disasters, interruption in internet service or any other cause
                                              which is beyond the reasonable control of such Party. The party referring
                                              to such force majeure circumstances shall notify the other party on
                                              arising within 3 working days from the date of its occurrence with the
                                              relevant evidence.</p>

                                          <p><a name="pub-16"></a></p>
                                          <h3>16. Confidentiality</h3>
                                          <p>16.1. Each Party (a “<strong>Receiving Party</strong>“) understands that
                                              the other Party (a “<strong>Disclosing
                                                  Party</strong>“) may disclose information of a confidential nature
                                              including, without
                                              limitation, product information, data, pricing, financial information,
                                              software,
                                              specifications, research and development and proprietary algorithms, stats
                                              and reports,
                                              personal data or other materials that is disclosed in a manner in which
                                              the Disclosing Party
                                              reasonably communicated, or the Receiving Party should reasonably have
                                              understood under the
                                              circumstances that the disclosure should be treated as confidential,
                                              whether or not the
                                              specific designation “confidential” or any similar designation is used
                                              (“<strong>Confidential
                                                  Information</strong>“).</p>

                                          <p>16.2. The Receiving Party agrees, for itself and its agents and employees,
                                              that it will not publish, disclose or otherwise divulge or use for its own
                                              purposes any Confidential Information of the Disclosing Party furnished to
                                              it by such Disclosing Party without the prior written approval of the
                                              Disclosing Party in each instance. Neither party will make any public
                                              announcement regarding the existence or content of the Agreement without
                                              the other’s prior written approval.</p>

                                          <p>16.3. The Parties agree that if disclosure is made to their professional
                                              advisors, auditors or bankers this shall be done subject to each Party
                                              procuring each such recipient’s agreement to keep such information
                                              confidential to the same extent as if such recipient were Party to this
                                              agreement.</p>

                                          <p>16.4. The foregoing obligations under this section 15 shall not extend to
                                              any information to the extent that the Receiving Party can demonstrate
                                              that such information (i) was at the time of disclosure or, to the extent
                                              that such information thereafter becomes through no fault of the Receiving
                                              Party, a part of the public domain by publication or otherwise; (ii) was
                                              already properly and lawfully in the Receiving Party’s possession at the
                                              time it was received by the Receiving Party free from any obligation of
                                              confidentiality, (iii) was or is lawfully received by the Receiving Party
                                              from a third Party who was under no obligation of confidentiality to the
                                              Disclosing Party with respect thereto, or (iv) is independently developed
                                              by the Receiving Party or its independent contractors who did not have
                                              access to the Disclosing Party’s Confidential Information or (vi) express
                                              written consent has been given prior to disclosure.</p>

                                          <p>16.5. In the event that the Receiving Party is required to disclose
                                              Confidential Information in accordance with judicial or regulatory or
                                              governmental order or requirement, or any tax authority to which that
                                              Party is subject or submits, wherever situated, whether or not the
                                              requirement for information has the force of law the Receiving Party shall
                                              promptly notify the Disclosing Party in order to allow such Party to
                                              contest the order or requirement or seek confidential treatment for such
                                              information.</p>

                                          <p>16.6. Upon termination or expiration of this Agreement, upon the request of
                                              a Disclosing Party, the Receiving Party agrees to return to the other all
                                              of such other Party’s Confidential Information, or to certify to the
                                              Disclosing Party in writing that all such material has been destroyed,
                                              however, destruction is only permitted after Disclosing Party’s prior
                                              approval.</p>

                                          <p><a name="pub-17"></a></p>
                                          <h3>17. Self-Billing</h3>

                                          <p>17.1. Hereby the Publisher expressly orders B-Tech to generate and
                                              issue the Publisher’s invoices on behalf of the Publisher. Prior to making
                                              any payment to a Publisher, B-Tech will generate automatically
                                              through the Program the invoice on behalf of such Publisher. Furthermore,
                                              the Publisher expressly agree that the Program will generate the said
                                              invoices based on the stats provided by the B-Tech reporting system
                                              and agree that such stats is accurate, fully and legally compliant for the
                                              purposes of invoicing and taxation.</p>

                                          <p>17.2. Any Publisher residing in the European Union who has provided a VAT
                                              number expressly warrants that such VAT number is, in its own country,
                                              valid for the issuance of VAT-exempt invoices to B-Tech. The
                                              Publisher expressly accepts to be solely liable for any error, direct or
                                              indirect loss or damage arising from the inaccuracy or non-compliance of
                                              such data or the breach of any of the aforementioned warranties and,
                                              accordingly, the Publisher will hold B-Tech harmless from any of
                                              the direct or indirect loss or damages. Publisher hereby confirms that
                                              another VAT invoice won’t be issued.</p>

                                          <p>17.3. Parties hereby agree to notify each other if they:</p>
                                          <ul>
                                              <li>change their VAT registration number;</li>
                                              <li>cease to be VAT registered;</li>
                                              <li>sell their business, or part of their business;</li>
                                              <li>to notify each other about any changes in their payment details</li>
                                          </ul>

                                          <p>17.4. Notice given in accordance with the conditions of clause 17.3 is also
                                              to be considered as your confirmation to issue self-billing invoices in
                                              altered conditions.</p>

                                          <p>17.5. In case there is any claim, administrative proceeding from any
                                              authority, dispute or conflict, in any way due to the inaccuracy or
                                              non-compliance of such data provided by the Publisher, B-Tech is
                                              expressly authorized to retain any payments due to the Publisher until
                                              such incident has been resolved.</p>

                                          <p><a name="pub-18"></a></p>
                                          <h3>18. Miscellaneous</h3>

                                          <p>18.1. This Agreement contains the sole and entire agreement and
                                              understanding between the Parties relating to the subject matter herein,
                                              and merges all prior discussions, whether through officers, directors,
                                              salespersons, employees or consultants.</p>

                                          <p>18.2. Present Agreement is the principal document in legal relationship of
                                              the Parties, and shall be deemed an entire agreement of the Parties. In
                                              case of contradictions in using Service or Program, present Agreement
                                              shall prevail in any case.</p>

                                          <p>18.3. Each Party is an independent contractor and not a partner, joint
                                              venture or employee of the other. All notices shall be sent to the
                                              addresses submitted by you when signing up for the Service by certified
                                              mail, fax, email or courier.</p>

                                          <p>18.4. B-Tech reserves the right to change any terms and conditions
                                              of this Agreement at any time unilaterally. The Publisher shall be
                                              informed of such amendments by relevant notice in personal account or
                                              through the information being made available on B-Tech’s website.
                                              The Publisher shall be deemed to have received such notice within two (2)
                                              weeks of the notice being sent by e-mail or made available in Publisher’s
                                              personal account on B-Tech’s website. You may refer to contract
                                              revisions in our website – “www.bandoftech.com“ or "www.bandofdomain.com“ . The terms and conditions
                                              of present Publisher Agreement (as published on “www.bandoftech.com“ or "www.bandofdomain.com“  (the
                                              “Terms”) bind the parties from the date signed or the date service is
                                              provided and shall apply to each and any services provided by B-Tech
                                              Ads. This Agreement shall take precedence over any other terms and
                                              conditions issued or stated or referenced to apply relating to the
                                              services provided by B-Tech.</p>

                                          <p>18.5. Representations and warranties of Publisher set forth in this
                                              Agreement (in particular, Section 15) hereof shall survive closing for a
                                              period of one (1) year from the termination date.</p>

                                          <p>18.6. No claim for a breach of any representation or warranty by B-Tech
                                              Ads shall be actionable or payable if the breach in question results from
                                              or is based on a condition, state of facts or other matter which was
                                              disclosed to Publisher and/or actually known by Publisher prior to
                                              termination.</p>

                                          <p>18.7. Publisher acknowledges and agrees that entering into this Agreement
                                              it has not relied
                                              and is not relying on any representations, warranties or other statements
                                              whatsoever,
                                              whether written or oral other than those expressly set out in this
                                              Agreement, &nbsp;
                                              <Link to={'/privacy-policy'}><strong>Privacy Policy</strong></Link>&nbsp;
                                              or other terms and conditions published at “www.bandoftech.com“ or "www.bandofdomain.com“  and that
                                              it will not have
                                              any right or remedy rising out of any representation, warranty or other
                                              statement not
                                              expressly set out in this Agreement.</p>

                                          <p>18.8. All claims related to the use of the Service or Program shall be
                                              submitted by the Publisher within 30 days from the end of the Reporting
                                              Period only. In the case of missing the specified term, B-Tech
                                              reserves the right not to process the complaint, and all the services
                                              shall be deemed rendered properly.</p>

                                          <p>18.9. Headings to sections and subsections in this Agreement are for the
                                              convenience of the parties only and are not intended to be a part of or
                                              affect the meaning or interpretation hereof.</p>

                                          <p>18.10. You agree on using of any communication method (email message/SMS
                                              message/phone) with contact details provided in your personal account.</p>

                                          <div className="wpb_wrapper"><em>This agreement was last updated on
                                              20.7.2021</em></div>
                                          <p>&nbsp;</p>
                                  </div>
                              </div>
                       </article>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
      </Fragment>
    );
}
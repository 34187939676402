
const geolist = {
        'AF': {'phone_prefix': '93', 'country_name': 'Afghanistan'},
        'AL': {'phone_prefix': '355', 'country_name': 'Albania'},
        'DZ': {'phone_prefix': '213', 'country_name': 'Algeria'},
        'AS': {'phone_prefix': '1684', 'country_name': 'American Samoa'},
        'AD': {'phone_prefix': '376', 'country_name': 'Andorra'},
        'AO': {'phone_prefix': '244', 'country_name': 'Angola'},
        'AI': {'phone_prefix': '1264', 'country_name': 'Anguilla'},
        'AQ': {'phone_prefix': '672', 'country_name': 'Antarctica'},
        'AG': {'phone_prefix': '1268', 'country_name': 'Antigua and Barbuda'},
        'AR': {'phone_prefix': '54', 'country_name': 'Argentina'},
        'AM': {'phone_prefix': '374', 'country_name': 'Armenia'},
        'AW': {'phone_prefix': '297', 'country_name': 'Aruba'},
        'AU': {'phone_prefix': '61', 'country_name': 'Australia'},
        'AT': {'phone_prefix': '43', 'country_name': 'Austria'},
        'AZ': {'phone_prefix': '994', 'country_name': 'Azerbaijan'},
        'BS': {'phone_prefix': '1242', 'country_name': 'Bahamas'},
        'BH': {'phone_prefix': '973', 'country_name': 'Bahrain'},
        'BD': {'phone_prefix': '880', 'country_name': 'Bangladesh'},
        'BB': {'phone_prefix': '1246', 'country_name': 'Barbados'},
        'BY': {'phone_prefix': '375', 'country_name': 'Belarus'},
        'BE': {'phone_prefix': '32', 'country_name': 'Belgium'},
        'BZ': {'phone_prefix': '501', 'country_name': 'Belize'},
        'BJ': {'phone_prefix': '229', 'country_name': 'Benin'},
        'BM': {'phone_prefix': '1441', 'country_name': 'Bermuda'},
        'BT': {'phone_prefix': '975', 'country_name': 'Bhutan'},
        'BO': {'phone_prefix': '591', 'country_name': 'Bolivia'},
        'BA': {'phone_prefix': '387', 'country_name': 'Bosnia and Herzegovina'},
        'BW': {'phone_prefix': '267', 'country_name': 'Botswana'},
        'BR': {'phone_prefix': '55', 'country_name': 'Brazil'},
        'IO': {'phone_prefix': '246', 'country_name': 'British Indian Ocean Territory'},
        'VG': {'phone_prefix': '1284', 'country_name': 'British Virgin Islands'},
        'BN': {'phone_prefix': '673', 'country_name': 'Brunei'},
        'BG': {'phone_prefix': '359', 'country_name': 'Bulgaria'},
        'BF': {'phone_prefix': '226', 'country_name': 'Burkina Faso'},
        'BI': {'phone_prefix': '257', 'country_name': 'Burundi'},
        'KH': {'phone_prefix': '855', 'country_name': 'Cambodia'},
        'CM': {'phone_prefix': '237', 'country_name': 'Cameroon'},
        'CA': {'phone_prefix': '1', 'country_name': 'Canada'},
        'CV': {'phone_prefix': '238', 'country_name': 'Cape Verde'},
        'KY': {'phone_prefix': '1345', 'country_name': 'Cayman Islands'},
        'CF': {'phone_prefix': '236', 'country_name': 'Central African Republic'},
        'TD': {'phone_prefix': '235', 'country_name': 'Chad'},
        'CL': {'phone_prefix': '56', 'country_name': 'Chile'},
        'CN': {'phone_prefix': '86', 'country_name': 'China'},
        'CX': {'phone_prefix': '61', 'country_name': 'Christmas Island'},
        'CC': {'phone_prefix': '61', 'country_name': 'Cocos Islands'},
        'CO': {'phone_prefix': '57', 'country_name': 'Colombia'},
        'KM': {'phone_prefix': '269', 'country_name': 'Comoros'},
        'CK': {'phone_prefix': '682', 'country_name': 'Cook Islands'},
        'CR': {'phone_prefix': '506', 'country_name': 'Costa Rica'},
        'HR': {'phone_prefix': '385', 'country_name': 'Croatia'},
        'CU': {'phone_prefix': '53', 'country_name': 'Cuba'},
        'CW': {'phone_prefix': '599', 'country_name': 'Curacao'},
        'CY': {'phone_prefix': '357', 'country_name': 'Cyprus'},
        'CZ': {'phone_prefix': '420', 'country_name': 'Czech Republic'},
        'CD': {'phone_prefix': '243', 'country_name': 'Democratic Republic of the Congo'},
        'DK': {'phone_prefix': '45', 'country_name': 'Denmark'},
        'DJ': {'phone_prefix': '253', 'country_name': 'Djibouti'},
        'DM': {'phone_prefix': '1767', 'country_name': 'Dominica'},
        'TL': {'phone_prefix': '670', 'country_name': 'East Timor'},
        'EC': {'phone_prefix': '593', 'country_name': 'Ecuador'},
        'EG': {'phone_prefix': '20', 'country_name': 'Egypt'},
        'SV': {'phone_prefix': '503', 'country_name': 'El Salvador'},
        'GQ': {'phone_prefix': '240', 'country_name': 'Equatorial Guinea'},
        'ER': {'phone_prefix': '291', 'country_name': 'Eritrea'},
        'EE': {'phone_prefix': '372', 'country_name': 'Estonia'},
        'ET': {'phone_prefix': '251', 'country_name': 'Ethiopia'},
        'FK': {'phone_prefix': '500', 'country_name': 'Falkland Islands'},
        'FO': {'phone_prefix': '298', 'country_name': 'Faroe Islands'},
        'FJ': {'phone_prefix': '679', 'country_name': 'Fiji'},
        'FI': {'phone_prefix': '358', 'country_name': 'Finland'},
        'FR': {'phone_prefix': '33', 'country_name': 'France'},
        'PF': {'phone_prefix': '689', 'country_name': 'French Polynesia'},
        'GA': {'phone_prefix': '241', 'country_name': 'Gabon'},
        'GM': {'phone_prefix': '220', 'country_name': 'Gambia'},
        'GE': {'phone_prefix': '995', 'country_name': 'Georgia'},
        'DE': {'phone_prefix': '49', 'country_name': 'Germany'},
        'GH': {'phone_prefix': '233', 'country_name': 'Ghana'},
        'GI': {'phone_prefix': '350', 'country_name': 'Gibraltar'},
        'GR': {'phone_prefix': '30', 'country_name': 'Greece'},
        'GL': {'phone_prefix': '299', 'country_name': 'Greenland'},
        'GD': {'phone_prefix': '1473', 'country_name': 'Grenada'},
        'GU': {'phone_prefix': '1671', 'country_name': 'Guam'},
        'GT': {'phone_prefix': '502', 'country_name': 'Guatemala'},
        'GG': {'phone_prefix': '441481', 'country_name': 'Guernsey'},
        'GN': {'phone_prefix': '224', 'country_name': 'Guinea'},
        'GW': {'phone_prefix': '245', 'country_name': 'GuineaBissau'},
        'GY': {'phone_prefix': '592', 'country_name': 'Guyana'},
        'HT': {'phone_prefix': '509', 'country_name': 'Haiti'},
        'HN': {'phone_prefix': '504', 'country_name': 'Honduras'},
        'HK': {'phone_prefix': '852', 'country_name': 'Hong Kong'},
        'HU': {'phone_prefix': '36', 'country_name': 'Hungary'},
        'IS': {'phone_prefix': '354', 'country_name': 'Iceland'},
        'IN': {'phone_prefix': '91', 'country_name': 'India'},
        'ID': {'phone_prefix': '62', 'country_name': 'Indonesia'},
        'IR': {'phone_prefix': '98', 'country_name': 'Iran'},
        'IQ': {'phone_prefix': '964', 'country_name': 'Iraq'},
        'IE': {'phone_prefix': '353', 'country_name': 'Ireland'},
        'IM': {'phone_prefix': '441624', 'country_name': 'Isle of Man'},
        'IL': {'phone_prefix': '972', 'country_name': 'Israel'},
        'IT': {'phone_prefix': '39', 'country_name': 'Italy'},
        'CI': {'phone_prefix': '225', 'country_name': 'Ivory Coast'},
        'JM': {'phone_prefix': '1876', 'country_name': 'Jamaica'},
        'JP': {'phone_prefix': '81', 'country_name': 'Japan'},
        'JE': {'phone_prefix': '441534', 'country_name': 'Jersey'},
        'JO': {'phone_prefix': '962', 'country_name': 'Jordan'},
        'KZ': {'phone_prefix': '7', 'country_name': 'Kazakhstan'},
        'KE': {'phone_prefix': '254', 'country_name': 'Kenya'},
        'KI': {'phone_prefix': '686', 'country_name': 'Kiribati'},
        'XK': {'phone_prefix': '383', 'country_name': 'Kosovo'},
        'KW': {'phone_prefix': '965', 'country_name': 'Kuwait'},
        'KG': {'phone_prefix': '996', 'country_name': 'Kyrgyzstan'},
        'LA': {'phone_prefix': '856', 'country_name': 'Laos'},
        'LV': {'phone_prefix': '371', 'country_name': 'Latvia'},
        'LB': {'phone_prefix': '961', 'country_name': 'Lebanon'},
        'LS': {'phone_prefix': '266', 'country_name': 'Lesotho'},
        'LR': {'phone_prefix': '231', 'country_name': 'Liberia'},
        'LY': {'phone_prefix': '218', 'country_name': 'Libya'},
        'LI': {'phone_prefix': '423', 'country_name': 'Liechtenstein'},
        'LT': {'phone_prefix': '370', 'country_name': 'Lithuania'},
        'LU': {'phone_prefix': '352', 'country_name': 'Luxembourg'},
        'MO': {'phone_prefix': '853', 'country_name': 'Macau'},
        'MK': {'phone_prefix': '389', 'country_name': 'Macedonia'},
        'MG': {'phone_prefix': '261', 'country_name': 'Madagascar'},
        'MW': {'phone_prefix': '265', 'country_name': 'Malawi'},
        'MY': {'phone_prefix': '60', 'country_name': 'Malaysia'},
        'MV': {'phone_prefix': '960', 'country_name': 'Maldives'},
        'ML': {'phone_prefix': '223', 'country_name': 'Mali'},
        'MT': {'phone_prefix': '356', 'country_name': 'Malta'},
        'MH': {'phone_prefix': '692', 'country_name': 'Marshall Islands'},
        'MR': {'phone_prefix': '222', 'country_name': 'Mauritania'},
        'MU': {'phone_prefix': '230', 'country_name': 'Mauritius'},
        'YT': {'phone_prefix': '262', 'country_name': 'Mayotte'},
        'MX': {'phone_prefix': '52', 'country_name': 'Mexico'},
        'FM': {'phone_prefix': '691', 'country_name': 'Micronesia'},
        'MD': {'phone_prefix': '373', 'country_name': 'Moldova'},
        'MC': {'phone_prefix': '377', 'country_name': 'Monaco'},
        'MN': {'phone_prefix': '976', 'country_name': 'Mongolia'},
        'ME': {'phone_prefix': '382', 'country_name': 'Montenegro'},
        'MS': {'phone_prefix': '1664', 'country_name': 'Montserrat'},
        'MA': {'phone_prefix': '212', 'country_name': 'Morocco'},
        'MZ': {'phone_prefix': '258', 'country_name': 'Mozambique'},
        'MM': {'phone_prefix': '95', 'country_name': 'Myanmar'},
        'NA': {'phone_prefix': '264', 'country_name': 'Namibia'},
        'NR': {'phone_prefix': '674', 'country_name': 'Nauru'},
        'NP': {'phone_prefix': '977', 'country_name': 'Nepal'},
        'NL': {'phone_prefix': '31', 'country_name': 'Netherlands'},
        'AN': {'phone_prefix': '599', 'country_name': 'Netherlands Antilles'},
        'NC': {'phone_prefix': '687', 'country_name': 'New Caledonia'},
        'NZ': {'phone_prefix': '64', 'country_name': 'New Zealand'},
        'NI': {'phone_prefix': '505', 'country_name': 'Nicaragua'},
        'NE': {'phone_prefix': '227', 'country_name': 'Niger'},
        'NG': {'phone_prefix': '234', 'country_name': 'Nigeria'},
        'NU': {'phone_prefix': '683', 'country_name': 'Niue'},
        'KP': {'phone_prefix': '850', 'country_name': 'North Korea'},
        'MP': {'phone_prefix': '1670', 'country_name': 'Northern Mariana Islands'},
        'NO': {'phone_prefix': '47', 'country_name': 'Norway'},
        'OM': {'phone_prefix': '968', 'country_name': 'Oman'},
        'PK': {'phone_prefix': '92', 'country_name': 'Pakistan'},
        'PW': {'phone_prefix': '680', 'country_name': 'Palau'},
        'PS': {'phone_prefix': '970', 'country_name': 'Palestine'},
        'PA': {'phone_prefix': '507', 'country_name': 'Panama'},
        'PG': {'phone_prefix': '675', 'country_name': 'Papua New Guinea'},
        'PY': {'phone_prefix': '595', 'country_name': 'Paraguay'},
        'PE': {'phone_prefix': '51', 'country_name': 'Peru'},
        'PH': {'phone_prefix': '63', 'country_name': 'Philippines'},
        'PN': {'phone_prefix': '64', 'country_name': 'Pitcairn'},
        'PL': {'phone_prefix': '48', 'country_name': 'Poland'},
        'PT': {'phone_prefix': '351', 'country_name': 'Portugal'},
        'QA': {'phone_prefix': '974', 'country_name': 'Qatar'},
        'CG': {'phone_prefix': '242', 'country_name': 'Republic of the Congo'},
        'RE': {'phone_prefix': '262', 'country_name': 'Reunion'},
        'RO': {'phone_prefix': '40', 'country_name': 'Romania'},
        'RU': {'phone_prefix': '7', 'country_name': 'Russia'},
        'RW': {'phone_prefix': '250', 'country_name': 'Rwanda'},
        'BL': {'phone_prefix': '590', 'country_name': 'Saint Barthelemy'},
        'SH': {'phone_prefix': '290', 'country_name': 'Saint Helena'},
        'KN': {'phone_prefix': '1869', 'country_name': 'Saint Kitts and Nevis'},
        'LC': {'phone_prefix': '1758', 'country_name': 'Saint Lucia'},
        'MF': {'phone_prefix': '590', 'country_name': 'Saint Martin'},
        'PM': {'phone_prefix': '508', 'country_name': 'Saint Pierre and Miquelon'},
        'VC': {'phone_prefix': '1784', 'country_name': 'Saint Vincent and the Grenadines'},
        'WS': {'phone_prefix': '685', 'country_name': 'Samoa'},
        'SM': {'phone_prefix': '378', 'country_name': 'San Marino'},
        'ST': {'phone_prefix': '239', 'country_name': 'Sao Tome and Principe'},
        'SA': {'phone_prefix': '966', 'country_name': 'Saudi Arabia'},
        'SN': {'phone_prefix': '221', 'country_name': 'Senegal'},
        'RS': {'phone_prefix': '381', 'country_name': 'Serbia'},
        'SC': {'phone_prefix': '248', 'country_name': 'Seychelles'},
        'SL': {'phone_prefix': '232', 'country_name': 'Sierra Leone'},
        'SG': {'phone_prefix': '65', 'country_name': 'Singapore'},
        'SX': {'phone_prefix': '1721', 'country_name': 'Sint Maarten'},
        'SK': {'phone_prefix': '421', 'country_name': 'Slovakia'},
        'SI': {'phone_prefix': '386', 'country_name': 'Slovenia'},
        'SB': {'phone_prefix': '677', 'country_name': 'Solomon Islands'},
        'SO': {'phone_prefix': '252', 'country_name': 'Somalia'},
        'ZA': {'phone_prefix': '27', 'country_name': 'South Africa'},
        'KR': {'phone_prefix': '82', 'country_name': 'South Korea'},
        'SS': {'phone_prefix': '211', 'country_name': 'South Sudan'},
        'ES': {'phone_prefix': '34', 'country_name': 'Spain'},
        'LK': {'phone_prefix': '94', 'country_name': 'Sri Lanka'},
        'SD': {'phone_prefix': '249', 'country_name': 'Sudan'},
        'SR': {'phone_prefix': '597', 'country_name': 'Suriname'},
        'SJ': {'phone_prefix': '47', 'country_name': 'Svalbard and Jan Mayen'},
        'SZ': {'phone_prefix': '268', 'country_name': 'Swaziland'},
        'SE': {'phone_prefix': '46', 'country_name': 'Sweden'},
        'CH': {'phone_prefix': '41', 'country_name': 'Switzerland'},
        'SY': {'phone_prefix': '963', 'country_name': 'Syria'},
        'TW': {'phone_prefix': '886', 'country_name': 'Taiwan'},
        'TJ': {'phone_prefix': '992', 'country_name': 'Tajikistan'},
        'TZ': {'phone_prefix': '255', 'country_name': 'Tanzania'},
        'TH': {'phone_prefix': '66', 'country_name': 'Thailand'},
        'TG': {'phone_prefix': '228', 'country_name': 'Togo'},
        'TK': {'phone_prefix': '690', 'country_name': 'Tokelau'},
        'TO': {'phone_prefix': '676', 'country_name': 'Tonga'},
        'TT': {'phone_prefix': '1868', 'country_name': 'Trinidad and Tobago'},
        'TN': {'phone_prefix': '216', 'country_name': 'Tunisia'},
        'TR': {'phone_prefix': '90', 'country_name': 'Turkey'},
        'TM': {'phone_prefix': '993', 'country_name': 'Turkmenistan'},
        'TC': {'phone_prefix': '1649', 'country_name': 'Turks and Caicos Islands'},
        'TV': {'phone_prefix': '688', 'country_name': 'Tuvalu'},
        'VI': {'phone_prefix': '1340', 'country_name': 'U.S. Virgin Islands'},
        'UG': {'phone_prefix': '256', 'country_name': 'Uganda'},
        'UA': {'phone_prefix': '380', 'country_name': 'Ukraine'},
        'AE': {'phone_prefix': '971', 'country_name': 'United Arab Emirates'},
        'GB': {'phone_prefix': '44', 'country_name': 'United Kingdom'},
        'US': {'phone_prefix': '1', 'country_name': 'United States'},
        'UY': {'phone_prefix': '598', 'country_name': 'Uruguay'},
        'UZ': {'phone_prefix': '998', 'country_name': 'Uzbekistan'},
        'VU': {'phone_prefix': '678', 'country_name': 'Vanuatu'},
        'VA': {'phone_prefix': '379', 'country_name': 'Vatican'},
        'VE': {'phone_prefix': '58', 'country_name': 'Venezuela'},
        'VN': {'phone_prefix': '84', 'country_name': 'Vietnam'},
        'WF': {'phone_prefix': '681', 'country_name': 'Wallis and Futuna'},
        'EH': {'phone_prefix': '212', 'country_name': 'Western Sahara'},
        'YE': {'phone_prefix': '967', 'country_name': 'Yemen'},
        'ZM': {'phone_prefix': '260', 'country_name': 'Zambia'},
        'ZW': {'phone_prefix': '263', 'country_name': 'Zimbabwe'}
};

export default geolist